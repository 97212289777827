import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ORDER_STATUS} from "../../constants";


@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderStatusComponent implements OnInit {

  @Input() status: string;
  @Input() statusText: string;
  @Input() disruptionStatus: any;
  @Input() isNotificationsExists = false;
  @Input() isPdf = false;
  @Input() isOpened = false;
  @Output() emitOpenTab: EventEmitter<boolean> = new EventEmitter<boolean>();
  ORDER_STATUS = ORDER_STATUS;

  redStatuses = [
    ORDER_STATUS.CANCELED,
    ORDER_STATUS.EXPIRED,
    ORDER_STATUS.DELETED,
    ORDER_STATUS.BLOCKED,
    ORDER_STATUS.VOIDED,
    ORDER_STATUS.VOIDING,
    ORDER_STATUS.CANCELLING
  ];

  constructor() {
  }

  ngOnInit() {

  }

  openTab() {
    this.emitOpenTab.emit(true);
  }

}
