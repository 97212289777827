import {Pipe, PipeTransform} from "@angular/core";

@Pipe({ name: 'travelerTypePipe' })

export class TravelerTypePipe implements PipeTransform {
  transform(type: string): string {
    switch (type.toLowerCase()) {
      case 'vfr':
        return 'adt';
        break;
      case 'v14':
        return 'yad';
        break;
      case 'vnn':
        return 'chd';
        break;
      case 'vnf':
        return 'inf';
      default:
        return type;
    }
  }
}
