import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-air-icons-info',
  templateUrl: './air-icons-info.component.html',
  styleUrls: ['./air-icons-info.component.scss'],
})
export class AirIconsInfoComponent implements OnInit {
  @Input() icon;
  @Input() showPopup;
  @Input() segment;
  @Input() popupData: any[] = [];

  icons = [
    'MEALS',
    'BAGGAGE_CARRYON',
    'BAGGAGE_CHECKED',
    'SEATRES',
    'CHANGE',
    'REFUND',
    'LEATHER_SEAT',
    'CHECK_IN',
    'BAR_SERVICE',
    'WINDOW_AISLE',
    'MILES',
    'LOUNGE',
    'SERVICES',
  ];

  incognitoArr = [];

  fareRulesData = [];

  constructor() {}

  ngOnInit() {
    if (this.showPopup) {
      this.showDefault();
    }

    this.convertData();
  }

  convertData() {
    let typesArr = [];

    if (this.segment && this.segment.detail.classOfService.disclosures) {
      const segmentDisclosures = this.segment.detail.classOfService.disclosures;
      typesArr = Object.keys(segmentDisclosures);
    } else {
      return;
    }

    typesArr.forEach((key) => {
      if (!this.segment.detail.classOfService.disclosures[key].fareRules.penalties) {
        return;
      }

      this.segment.detail.classOfService.disclosures[key].fareRules.penalties.map((penalty) => {
        if (penalty.type && !this.isDuplicateFareRules(penalty.type)) {
          this.fareRulesData.push({
            amount: penalty?.amounts ? penalty.amounts[0]?.amount : '',
            currency: penalty?.amounts ? penalty.amounts[0]?.currency : '',
            type: penalty.type,
            description: penalty.description,
          });
        }
      });
    });
  }

  private showDefault() {
    this.incognitoArr = this.popupData.filter((item) => {
      return !!item && !!item.text && !item.origin_destination_reference
        ? !this.icons.includes(item.item)
        : false;
    });
  }

  private isDuplicateFareRules(type) {
    return (
      this.fareRulesData.findIndex((fareRules) => fareRules.type === type) !==
      -1
    );
  }
}
