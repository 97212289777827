import { Component, OnInit } from '@angular/core';
import {environment} from "../../environments/environment";
import {Router} from "@angular/router";

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (!environment.default.showMaintenancePage) {
      this.router.navigate(['/']);
    }
  }

}
