import {Pipe, PipeTransform} from "@angular/core";

@Pipe({ name: 'timeRangePipe' })
export class TimeRangePipe implements PipeTransform {
  transform(obj: any, way: string) {
    return {
      floor: obj[way].min,
      step: 30,
      ceil: obj[way].max,
      minRange: 2,
      enforceStep: false,
      enforceRange: false,
      pushRange: true,
      translate: (value: number): string => {
        let hours = Math.trunc(value / 60) >= 10 ? Math.trunc(value / 60) : '0' + Math.trunc(value / 60);
        let minutes = value % 60 >= 10 ? value % 60 : '0' + value % 60;

        return hours + ':' + minutes + 'h';
      }
    };
  }
}
