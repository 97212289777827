export const RESPONSE_OK = 'OK';
export const RESPONSE_ERROR = 'ERROR';

export class ErrorResponse {
  status: string;
  error: string | {};
}

export class MetaResponse {
  status: string;
  message: string;
  meta: string;
}

export class ListResponse {
  data: any;
  pages: number;
}

export enum ContentType {
  TextPlain = 'text/plain',
  TextXml = 'text/xml',
  ApplicationPdf = 'application/pdf',
}
