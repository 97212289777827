import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Disclocures, DisclosureDescription} from "../../interfaces/disclosures";
import {DisclosureService} from "../../services/disclosure.service";

@Component({
  selector: 'app-disclosures',
  templateUrl: './disclosures.component.html',
  styleUrls: ['./disclosures.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisclosuresComponent implements OnInit {

  @Input() disclosures: Disclocures[];
  @Input() flightKey: string;
  @Input() isCollapsed = false;
  groupedDisclosuresByCategory = new Map<string, DisclosureDescription[]>();

  constructor(public disclosureService: DisclosureService) { }

  ngOnInit(): void {
    if (this.disclosureService != null) {
      const filteredByFlightKey = this.disclosureService.filterDisclosuresByODR(this.disclosures, this.flightKey);
      this.groupedDisclosuresByCategory = this.disclosureService.groupDisclosuresByCategory(filteredByFlightKey);
    }
  }

}
