import {Component, OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import {catchError, takeUntil, switchMap} from 'rxjs/operators';
import {Subject, of} from 'rxjs';
import {ProfilesService} from '../../services/profiles.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {HttpResponse} from '@angular/common/http';


@Component({
  selector: 'app-search-profiles',
  templateUrl: './search-profiles.component.html',
  // styleUrls: ['./search-profiles.component.scss']
})
export class SearchProfilesComponent implements OnInit, OnDestroy {
  @Input() target = '';
  @Input() inputValue = '';
  @Input() passengerKey: string;
  @Input() isDisabled: boolean;
  @Input() noInputFocus;
  @Input() isValid = true;
  @Input() isInvalid = false;
  @Output() emitProfileData = new EventEmitter();
  @Output() emitSearchValue = new EventEmitter();
  searchPlaceholder = 'Search traveler profile';
  listData: any[] = [];

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(public profilesService: ProfilesService,
              private ls: LocalStorageService) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  searchProfiles(event) {
    const corporateID = JSON.parse(this.ls.profileCorporate).corporate_id;
    if (event && corporateID) {
      this.profilesService.searchProfiles(event, corporateID)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((res: any) => {
          if (res.body && res.body.profiles) {
            this.listData = res.body.profiles.map(val => {
              return {
                id: val.id,
                text: `${val.name} ${val.surname} (${val.email})`
              };
            });
          } else {
            this.listData = [];
          }
        }, err => console.log(err));
    } else {
      this.listData = [];
    }

  }

  /**
   * emitSelectedItem
   * @param event
   */
  onEmitSelectedProfile(event) {
    this.searchProfiles(event);
    this.emitSearchValue.emit(event);
  }

  onSelectedItem(event) {
    let data: any = {
      passengerKey: this.passengerKey
    };

    if (!event) {
      this.emitProfileData.emit(data);
      return;
    }

    const corporateID = JSON.parse(this.ls.profileCorporate).corporate_id;
    this.profilesService
      .getTraveler(event.id, corporateID)
      .subscribe(res => {
        data.response = (<HttpResponse<any>>res).body;
        this.emitProfileData.emit(data);
      });
  }
}
