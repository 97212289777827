import {Corporate} from "./corporate";

export class Profile {
  id: string;
  corporate_id: string;
  role: Role;
  has_travel_profile: boolean;
  created_at: string;
  updated_at: string;
  name: string;
  surname: string;
  gender?: string;
  title?: string;
  email: string;
  birthdate: string;
  documents: Document[];
  phone_country_code: string;
  phone_code: string;
  phone_number: string;
  address_country_code: string;
  address_postal_code: string;
  address_city_name: string;
  address_street: string;
  frequent_flyer_numbers: FrequentFlyerNumber[];
  account: any;
  corporate: Corporate;
}

export class Document {
  id: string;
  type: string;
  fiscal_name: string;
  expiration_date: any;
  issuing_country_code: string;
  residence_country_code: string;
  citizenship_country_code: string;
}

export class FrequentFlyerNumber {
  alliance: string;
  member_number: string;
}

export class ProfileForm {
  name: string;
  role: string;
  has_travel_profile: string;
  surname: string;
  gender?: string;
  title?: string;
  email: string;
  birthdate: string;
  document_type: string;
  document_id: string;
  document_expiration_date: string;
  document_fiscal_name: string;
  document_issuing_country_code: string;
  document_citizenship_country_code: string;
  document_residence_country_code: string;
  phone_country_code: string;
  phone_code: string;
  phone_number: string;
  address_country_code: string;
  address_postal_code: string;
  address_city_name: string;
  address_street: string;
  frequent_flyer_numbers: string;
}

export interface EditFrequentFlyerNumber {
  idx: number;
  frequentFlyerNumber: FrequentFlyerNumber;
}

export type Role = 'manager' | 'traveler';
