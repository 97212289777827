import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { tap } from 'rxjs/operators';
import { NDCApiService } from "../services/ndc-api.service";
import {LocalStorageService} from "../services/local-storage.service";
import {ERROR_CODES} from "../constants";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private ndcApiService: NDCApiService, private ls: LocalStorageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modifiedRequest = request;

    if (this.ls.agency) {
      modifiedRequest = request.clone({
        setHeaders: {
          'Ag-Agency': this.ls.agency
        }
      });
    }

    return next.handle(modifiedRequest).pipe(tap(() => { }, (err: HttpResponse<any>) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.authService.logout();
        }
        if (err.status === 401 || (err.status === 403 && err?.error?.group === ERROR_CODES.ACCESS_RESOURCE_FORBIDDEN)) {
          this.ndcApiService.catchTrackErrors(err, false);
        }
      }
    }));
  }
}
