import { Pipe, PipeTransform } from '@angular/core';
import {DateService} from "../services/date.service";
import {TimezoneService} from "../services/timezone.service";
import moment from 'moment';
import {Observable} from "rxjs/internal/Observable";
import {map} from "rxjs/operators";
import {combineLatest} from "rxjs";

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  constructor(private dateService: DateService,
              private timezoneService: TimezoneService) {
  }

  transform(value: string | number, changeTimezone: boolean = false, time?: string): Observable<string> {
    return combineLatest([
      this.dateService.getDateFormat(),
      this.timezoneService.getTimezone()
    ]).pipe(
      map(([format, timezone]) => {
        if (changeTimezone) {
          return time
            ? moment(value).tz(timezone).format(format + ' ' + time).toUpperCase()
            : moment(value).tz(timezone).format(format).toUpperCase();
        } else {
          return time
            ? moment.utc(value).format(format + ' ' + time).toUpperCase()
            : moment.utc(value).format(format).toUpperCase();
        }
      })
    );
  }
}
