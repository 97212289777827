export class ErrorAlert {
  message: string;
  type: ErrorType;

  constructor() {
    this.message = '';
    this.type = 'danger';
  }
}

type ErrorType = 'danger' | 'danger-provider';
