import {Injectable} from '@angular/core';
import moment from 'moment';

@Injectable()
export class PnrGeneratorService {
  public order;
  public flights = [];
  public flightsRows = [];

  public generatePnr () {
    this.getInfo();
    this.flights.map((flight, index) => {
      let flightPnrString = (index < 9 ? '0' + (index + 1) : '' + (index + 1)) + ' '
        + flight.airline + flight.flight_number + ' '
        + flight.class_service + ' '
        +  moment(flight.dep_date).format('DDMMM').toUpperCase() + ' '
        +  moment(flight.dep_date).day() + '*'
        + flight.dep_airport + flight.arr_airport + ' '
        + flight.segment_status + this.order.passengers.length + ' '
        + flight.dep_time.split(':').join('') + ' '
        + flight.arr_time.split(':').join('') + ' '
        + moment(flight.arr_date).format('DDMMM').toUpperCase() + ' '
        + 'E' + ' '
        + flight.stops + ' '
        + flight.aircraft_type + ' '
        + 'M';
        flightPnrString = flightPnrString.replace(/  +/g, ' ');

        this.flightsRows.push(flightPnrString);
    });

    return this.flightsRows.join('\n');
  }

  private getInfo() {
    this.order?.flights.map(flight => {
      flight.segments.map((leg) => {
        let flightInfo = {};
        flightInfo['airline'] = leg.marketingCarrier.airlineID;
        flightInfo['flight_number'] = leg.marketingCarrier.flightNumber;
        flightInfo['class_service'] = leg.detail.classOfService.code;
        flightInfo['dep_date'] = leg.originDestination.departure.date;
        flightInfo['arr_date'] = leg.originDestination.arrival.date;
        flightInfo['dep_airport'] = leg.originDestination.departure.airportCode;
        flightInfo['arr_airport'] = leg.originDestination.arrival.airportCode;
        flightInfo['segment_status'] = leg.detail.segmentType;
        flightInfo['dep_time'] = leg.originDestination.departure.time;
        flightInfo['arr_time'] = leg.originDestination.arrival.time;
        flightInfo['stops'] = leg.detail.stopQuantity;
        flightInfo['aircraft_type'] = leg.equipment.aircraftCode;

        this.flights.push(flightInfo);
      });
    });
  }
}
