import {Pipe, PipeTransform} from "@angular/core";


@Pipe({ name: 'parseDuration' })
export class ParseDurationPipe implements PipeTransform {
  transform(duration: string) {
    try {
      if (!duration.startsWith('PT') || !duration.includes('H') || !duration.includes('M')) {
        throw new Error('Invalid duration format');
      }
      let hoursIndex = duration.indexOf('H');
      let minutesIndex = duration.indexOf('M', hoursIndex);

      let hours = duration.substring(duration.indexOf('T') + 1, hoursIndex);
      let minutes = duration.substring(hoursIndex + 1, minutesIndex);

      if (minutes.length === 1) {
        minutes = '0' + minutes;
      }

      let result = `${parseInt(hours)}h${minutes}min`;

      return result;
    } catch (error) {
      return duration;
    }
  }
}
