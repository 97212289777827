import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-processing',
  templateUrl: './show-processing.component.html',
  styleUrls: ['./show-processing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowProcessingComponent implements OnInit {

  @Input() processInfo = 'Processing';
  @Input() type = 'danger';

  constructor() { }

  ngOnInit() {
  }

}
