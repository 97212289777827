import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'htmlToStringParser'})
export class HtmlToStringParserPipe implements PipeTransform {
  transform(value: string): string {
    let parser = new DOMParser();
    let doc = parser.parseFromString(value, 'text/html');
    return doc.body.innerText;
  }
}
