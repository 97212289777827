export interface BaggageAllowance {
  checked: BaggageAllowanceInfo[];
  carryOn: BaggageAllowanceInfo[];
}

export interface  BaggageAllowanceInfo {
  passengerRefs: string;
  chooseType: OPTION_CHOOSE_TYPE;
  options: BaggageAllowanceOption[];
  type: string;
}

export interface BaggageAllowanceOption {
  text: string;
  quantity: number;
  properties: DisclosureDescriptionProperties[];
  type?: string;
}

export interface DisclosureDescription {
  category: string;
  item: string;
  metadataToken?: OPTION_CHOOSE_TYPE;
  originDestinationReference: string;
  text: string;
  units: string;
  properties: DisclosureDescriptionProperties[];
  media?: {
    link: string;
  };
}

export interface DisclosureDescriptionProperties {
  UOM: string;
  type: string;
  value: string;
}

export interface BaggageAllowanceData {
  passengersInfo: string[];
  options?: Option[];
  status: BAGGAGE_ALLOWANCE_STATUS;
  chooseType?: OPTION_CHOOSE_TYPE;
  type?: string;
}

export interface Option {
  text: string;
  properties: OptionProperty[];
  quantity?: number;
}

export interface OptionProperty {
  text: string;
  type: string;
}

export interface Disclocures {
  descriptions: DisclosureDescription[];
  listKey: string;
}

export interface DisclosureTableData {
  route?: RouteInfo;
  generalRoute?: string;
  disclosures: Map<string, DisclosureDescription[]>;
}

export interface RouteInfo {
  depAirportCode: string;
  arrAirportCode: string;
  depAirportName: string;
  arrAirportName: string;
  depTerminalName: string;
  arrTerminalName: string;
}

export enum BAGGAGE_ALLOWANCE_TYPE {
  BAGGAGE_CHECKED = 'BAGGAGE_CHECKED',
  BAGGAGE_CARRYON = 'BAGGAGE_CARRYON',
  BAGGAGE_HANDBAG = 'BAGGAGE_HANDBAG',
  UNKNOWN = ''
}

export enum BAGGAGE_ALLOWANCE_STATUS  {
  ALLOWED,
  DISALLOWED
}

export enum OPTION_CHOOSE_TYPE {
  OR = 'one',
  AND = 'many',
  DEFAULT = ''
}
