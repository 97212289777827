import { AbstractControl } from '@angular/forms';

export class DateValidators {
  static ngbDateVaidator(AC: AbstractControl) {
    const date = AC.value;
    if (date && (!date.year || !date.month || !date.day)) {
      return { 'invalidDate': true };
    }
    return null;
  }
}
