import {
  Component, ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {getTravelerTitle, PaxBreakdownInfo} from "../../interfaces/passenger";
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-show-pax-breakdown-info',
  templateUrl: './show-pax-breakdown-info.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./show-pax-breakdown-info.component.scss']
})
export class ShowPaxBreakdownInfoComponent implements OnChanges, OnInit {

  @Input() consumerPrice;
  @Input() passengers = [];
  @Input() passengerType: string;
  @Input() services = [];
  @Input() seats = [];
  @Input() isPDF: boolean;
  @ViewChild('popover') popover: NgbPopover;

  travelers: {[key: string]: number} = {};
  paxes: PaxBreakdownInfo[];
  getTravelerTitle = getTravelerTitle;

  servicesTotalPrice = 0;
  seatsTotalPrice = 0;

  buttonName = 'info_outline';

  @HostListener('click') onClick() {
    if (this.popover && !this.popover.isOpen()) {
      this.popover.open();
    }
  }

  @HostListener('mouseover') onMouseEnter() {
    this.buttonName = 'info';
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.popover && !this.popover.isOpen()) {
      this.buttonName = 'info_outline';
    }
  }

  @HostListener('document:click', ['$event']) onClickOut(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.buttonName = 'info_outline';
    }
  }

  constructor(private elementRef: ElementRef) { }

  ngOnChanges(changes: SimpleChanges) {
    this.prepareTravelers();
    this.preparePaxes();
  }

  ngOnInit() {
    this.seatsTotalPrice = this.getTotalPrice(this.seats);
    this.servicesTotalPrice = this.getTotalPrice(this.services);
  }

  getTotalPrice(arr: any[]): number {
    let totalPrice = 0;
    if (arr?.length) {
      arr.map((el) => {
        totalPrice += el.price.consumer.total;
      });
    }
    return totalPrice;
  }

  preparePaxes() {
    this.paxes = [];
    let holder = {};
    this.consumerPrice?.breakdown?.forEach((pax) => {
      holder[pax.passengerType + pax.base + pax.tax?.total] = {
        base: pax.base,
        surcharge: pax.surcharge,
        total: pax.tax?.total,
        passengerType: pax.passengerType,
        passengerRefs: pax.passengerRefs
      };
    });

    for (let prop in holder) {
      this.paxes.push(
        { base: holder[prop].base,
          surcharge: holder[prop].surcharge,
          total: holder[prop].total,
          passengerType: holder[prop].passengerType,
          passengerRefs: holder[prop].passengerRefs
        });
    }
  }


  prepareTravelers() {
    if (this.passengers?.length) {
      [...this.passengers].forEach(passenger => {
        const type = passenger.passengerType.toLowerCase();
        this.travelers[type] ? this.travelers[type] += 1 : this.travelers[type] = 1;
      });
    }
  }

  getTotal(base: any, tax: any) {
    tax = tax ?? 0;
    const total = base * 100 + tax * 100;
    return parseInt(total.toString()) / 100;
  }

  getPaxNumber(pax: PaxBreakdownInfo) {
    return this.travelers[pax.passengerType?.toLowerCase()] ||
      (pax.passengerRefs ? pax.passengerRefs.split(' ').length : 1);
  }

}
