import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-services-table',
  templateUrl: './services-table.component.html',
  styleUrls: ['./services-table.component.scss']
})
export class ServicesTableComponent implements OnInit {

  @Input() services;
  @Input() passengerRef;
  @Input() selectedServicesCopy;
  @Input() selectedServicesMapCopy;
  @Input() validationErrors;
  @Output() emitUpdatedValues = new EventEmitter<{
    selectedServicesCopy: any[],
    selectedServicesMapCopy: { [key: string]: boolean },
    validationErrors: { [key: string]: string }
  }>();

  constructor() { }

  ngOnInit(): void {
  }

  toggleServiceSelection(service, passengerRef) {
    let code = service.serviceID + passengerRef + service.segmentReferences;
    const copyService = JSON.parse(JSON.stringify(service));
    if (this.selectedServicesMapCopy[code]) {
      service.bookingInstructions?.instructions?.forEach((instruction, instructionIdx) => {
        delete this.validationErrors[code + instructionIdx];
      });
      delete this.selectedServicesMapCopy[code];
      this.selectedServicesCopy = this.selectedServicesCopy.filter((srv) => {
        return srv.serviceID + srv.segmentReferences + srv.travelerReferences !== copyService.serviceID + copyService.segmentReferences + passengerRef;
      });
    } else {
      copyService.travelerReferences = passengerRef;
      if (service.bookingInstructions.mandatoryText === 'mandatory') {
        service.bookingInstructions?.instructions?.forEach((instruction, instructionIdx) => {
          this.validationErrors[code + instructionIdx] = 'Required field';
        });
      }
      this.selectedServicesMapCopy[code] = true;
      this.selectedServicesCopy.push(copyService);
    }

    this.emitUpdatedValues.emit({
      selectedServicesCopy: this.selectedServicesCopy,
      selectedServicesMapCopy: this.selectedServicesMapCopy,
      validationErrors: this.validationErrors
    });
  }

  atLeastOneHaveComment(services) {
    let hasComment = false;
    services.map(s => {
      if (s.bookingInstructions && s.bookingInstructions.mandatoryText !== 'not_allowed') {
        hasComment = true;
      }
    });
    return hasComment;
  }

  showCommentInput(srv: any, passengerRef: string) {
    return srv.ref !== 'Seat' &&
      this.selectedServicesMapCopy[srv.serviceID + passengerRef + srv.segmentReferences] &&
      srv.bookingInstructions && srv.bookingInstructions.mandatoryText !== 'not_allowed';
  }

  getServiceValue(service: any, passengerRef: string, instructionIdx: number) {
    let code = service.serviceID + passengerRef + service.segmentReferences;

    const selectedService = this.selectedServicesCopy.find(selectedService => {
      let selectedServiceCode = selectedService.serviceID + selectedService.travelerReferences + selectedService.segmentReferences;
      return selectedServiceCode === code;
    });

    return selectedService.bookingInstructions?.instructions?.[instructionIdx]?.value || '';
  }

  handleCommentInput($event: Event, service: any, passengerRef: string, instruction: any, instructionIdx: number) {
    this.validationErrors = {};
    let code = service.serviceID + passengerRef + service.segmentReferences;
    this.selectedServicesCopy.map((srv, idx) => {
      let currentCode = srv.serviceID + srv.travelerReferences + srv.segmentReferences;
      if (currentCode === code) {
        if (this.selectedServicesCopy[idx]?.bookingInstructions?.instructions?.[instructionIdx]) {
          this.selectedServicesCopy[idx].bookingInstructions.instructions[instructionIdx].value = ($event.target as HTMLInputElement).value.toUpperCase();
        }
        let newValue = ($event.target as HTMLInputElement).value.toUpperCase();
        let text = this.selectedServicesCopy[idx].text || '';

        // Create a regex to find the varkey in the text
        let regex = new RegExp(`(${instruction.varkey}\\w*)`, 'g');
        let found = text.match(regex);

        if (found) {
          // Replace the existing varkey value
          text = text.replace(regex, `${instruction.varkey}${newValue}`);
        } else {
          // Add the new varkey value
          text += ` ${instruction.varkey}${newValue}`;
        }

        // Update the text in the selected service copy
        this.selectedServicesCopy[idx].text = text.trim();
      }

      if (srv.bookingInstructions?.instructions?.length) {
        srv.bookingInstructions.instructions.forEach((instruction, instructionIdx) => {
          if (!instruction.value && srv.bookingInstructions.mandatoryText === 'mandatory') {
            this.validationErrors[currentCode + instructionIdx] = 'Required field';
          } else if (instruction.pattern) {
            const pattern = new RegExp(`^${instruction.pattern}$`);
            const isMatch = pattern.test(instruction.value);
            if (!isMatch) {
              this.validationErrors[currentCode + instructionIdx] = this.getPatternErrorMessage(instruction.pattern);
            }
          }
        });
      }
    });

    this.emitUpdatedValues.emit({
      selectedServicesCopy: this.selectedServicesCopy,
      selectedServicesMapCopy: this.selectedServicesMapCopy,
      validationErrors: this.validationErrors
    });
  }

  getPatternErrorMessage(pattern: string): string {
    // Check for digit patterns
    let digitMatch = pattern.match(/\[0-9\]\{(\d+)(?:,(\d+))?\}/);
    if (digitMatch) {
      if (digitMatch[2]) {
        return `Value must be between ${digitMatch[1]} and ${digitMatch[2]} digits.`;
      } else {
        return `Value must be ${digitMatch[1]} digits.`;
      }
    }

    // Check for uppercase letter patterns
    let letterMatch = pattern.match(/\[A-Z\]\{(\d+)(?:,(\d+))?\}/);
    if (letterMatch) {
      if (letterMatch[2]) {
        return `Value must be between ${letterMatch[1]} and ${letterMatch[2]} uppercase letters.`;
      } else {
        return `Value must be ${letterMatch[1]} uppercase letters.`;
      }
    }

    // Check for any number of digits
    if (pattern === '[0-9]*') {
      return 'Value must be any number of digits.';
    }

    // Check for any number of uppercase letters
    if (pattern === '[A-Z]*') {
      return 'Value must be any number of uppercase letters.';
    }

    // Check for specific words
    let specificWordsMatch = pattern.match(/\(\?:\s*([A-Z]+(?:\|[A-Z]+)*)\s*\)/);
    if (specificWordsMatch) {
      let options = specificWordsMatch[1].split('|').join(' or ');
      return `Value must be ${options}.`;
    }

    // Default message for unsupported patterns
    return `Invalid value. Expected pattern: ${pattern}`;
  }

}
