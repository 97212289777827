import {Pipe, PipeTransform} from "@angular/core";

@Pipe({ name: 'isExistItemInArray' })
export class IsExistItemInArrayPipe implements PipeTransform {
  transform(item: string, arr: any[], field: string = '') {
    let value = false;
    if (field && arr.length && !!arr[0] && typeof arr[0] === 'object' && Object.keys(arr[0]).indexOf(field) !== -1) {
      arr.map(m => {
        if (m[field] === item) {
          value = true;
        }
      });
    } else {
      value = arr.indexOf(item) !== -1;
    }
    return value;
  }
}
