import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-date-with-popover',
  templateUrl: './date-with-popover.component.html',
  styleUrls: ['./date-with-popover.component.scss']
})
export class DateWithPopoverComponent implements OnInit {
  @Input() date: string | number;

  constructor() { }

  ngOnInit(): void {
  }

}
