import {Pipe, PipeTransform} from "@angular/core";
import {HelpersService} from "../services/helpers.service";


@Pipe({ name: 'getKeyByValueFormObj' })
export class GetKeyByValueFormObjPipe implements PipeTransform {

  constructor(
    public helpers: HelpersService
  ) {}

  transform(obj: any, value) {
    return this.helpers.getKeyByValue(obj, value);
  }
}
