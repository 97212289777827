import { Pipe, PipeTransform } from '@angular/core';
import { Traveler } from '../models/order-split-data';

@Pipe({
  name: 'removeInfantTravelersPipe'
})
export class RemoveInfantTravelersPipe implements PipeTransform {

  transform(travelers: Traveler[]): Traveler[] {
    const travelersCopy = [...travelers];
    travelersCopy.forEach((traveler) => {
      const infantIdx = travelers.findIndex(trav => traveler.infantReference === trav.travelerReference);
      if (infantIdx !== -1) {
        travelersCopy.splice(infantIdx, 1);
      }
    });
    return travelersCopy;
  }

}
