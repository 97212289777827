import { Injectable } from '@angular/core';

@Injectable()
export class ProjectContextDataService {

  constructor() { }

  public data = {};

  public setMetaData(value) {
   this.data['metaData'] = value;
  }

  public setOrderRetrieve(value) {
    this.data['orderRetrieve'] = value;
  }

  public setOrderCreate(value) {
    this.data['orderCreate'] = value;
  }

  public setOfferPrice(value) {
    this.data['offerPrice'] = value;
  }

  public setSeatAvailability(value) {
    this.data['seatAvailability'] = value;
  }

  public setAirDocIssue(value) {
    this.data['airDocIssue'] = value;
  }

  public setOrderHistory(value) {
    this.data['orderHistory'] = value;
  }

}
