import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AirlineNotification} from "../../shared/interfaces/airline-notification";
import {NDCApiService} from "../../shared/services/ndc-api.service";
import {ErrorAlert} from "../../shared/models/error-alert";
import {HelpersService} from "../../shared/services/helpers.service";

@Component({
  selector: 'app-airline-notification-modal',
  templateUrl: './airline-notification-modal.component.html',
  styleUrls: ['./airline-notification-modal.component.scss']
})
export class AirlineNotificationModalComponent implements OnInit {

  @Input() orderID: string;
  @Input() airlineID: string;
  @Input() airlineNotification: AirlineNotification;
  @Output() emitClose = new EventEmitter();
  @Output() emitButtonClick = new EventEmitter<string>();
  @Output() emitNotificationChange = new EventEmitter();

  orderNotificationSeenToggleError: ErrorAlert = new ErrorAlert();

  constructor(private ndcApiService: NDCApiService, private helpers: HelpersService) { }

  ngOnInit(): void {
  }

  onNotificationChange() {
    this.orderNotificationSeenToggleError = new ErrorAlert();
    const body = {
      id: this.orderID,
      notificationIds: [this.airlineNotification.id]
    };
    this.ndcApiService.orderNotificationSeenToggle(body, false).then((res) => {
      this.airlineNotification = res.airlineNotifications.find((airlineNotification: AirlineNotification) => airlineNotification.id === this.airlineNotification.id);
      this.emitNotificationChange.emit(res);
    }).catch((err) => {
      this.orderNotificationSeenToggleError = this.helpers.getError(err);
    });
  }

}
