import {Component, Input, OnInit} from '@angular/core';
import {interval} from "rxjs";
import {map} from "rxjs/operators";
import moment from "moment";

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit {

  @Input() limit = '';
  @Input() label = 'This order expires in ';
  @Input() onExpiredMessage = 'Order Expired.';
  isAlreadyExpired = false;
  private subscription: any;
  $counter: any;
  clock = {
    yearDiff: 0 ,
    monthDiff: 0 ,
    dayDiff: 0 ,
    hourDiff: 0 ,
    minuteDiff: 0 ,
    secondDiff: 0 ,
  };
  readonly forbiddenLimits = ['0001-01-01T00:00:00Z', '0001-01-01 00:00'];

  constructor() {
  }

  ngOnInit() {
    this.prepareOrderExpireMessage();

    this.$counter = interval(1000).pipe(
      map((x) => {
        return x;
      })
    );

    this.subscription = this.$counter
      .subscribe(() => this.prepareOrderExpireMessage());
  }

  prepareOrderExpireMessage() {
    let currentTime = moment.utc();
    let paymentTimeLimit = moment.utc(this.limit);

    if (paymentTimeLimit.isAfter(currentTime)) {
      let diff = moment.duration(paymentTimeLimit.diff(currentTime));

      this.clock.yearDiff = diff.years() || 0;
      this.clock.monthDiff = diff.months() || 0;
      this.clock.dayDiff = diff.days() || 0;
      this.clock.hourDiff = diff.hours() || 0;
      this.clock.minuteDiff = diff.minutes() || 0;
      this.clock.secondDiff = diff.seconds() || 0;
      this.isAlreadyExpired = false;
      return;
    }

    this.isAlreadyExpired = true;
  }
}
