import {Directive, ElementRef, HostListener} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appUppercaseOnly]'
})
export class UppercaseOnlyDirective {

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const input = this.el.nativeElement;
    const transformed = input.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    this.control.control?.setValue(transformed, { emitEvent: false });
  }

}
