import {Component, ElementRef, Input, EventEmitter, HostListener, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {HelpersService} from "../../services/helpers.service";

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent implements OnInit {

  @Input() timeValue: string;
  @Input() disabled: boolean;
  @Input() isScrollable = true;
  @Output() emitTime = new EventEmitter<any>();
  @ViewChild('dropdownScroll') dropdownScroll: ElementRef;
  constructor(private elementRef: ElementRef) { }

  timeForm: FormControl;
  hours: string[] = [];
  isShowDropdown = false;
  timeString: string;
  isClearPossible = false;

  @HostListener('document:click', ['$event'])
  clickOutside({ target }) {
    if (this.isShowDropdown && !this.elementRef.nativeElement.contains(target)) {
      this.isShowDropdown = false;
    }
  }

  ngOnInit(): void {
    this.timeForm = new FormControl('', [Validators.maxLength(2),
      Validators.pattern("^[0-9]*$")]);

    this.validateInputTime();
    this.hours = this.getFillNumberArray(23, 1);
    if (this.timeValue) {
      this.timeForm.patchValue(Math.floor(parseInt(this.timeValue) / 60).toString().padStart(2, '0'));
      this.emitTime.emit(parseInt(this.timeValue));
    }
  }

  getFillNumberArray(count: number, period = 1, start = 0) {
    const array: string[] = [];
    for (let i = start; i <= count; i = i + period) {
      array.push(i.toString());
    }
    return array;
  }

  setHours(event, index: number) {
    this.timeForm.patchValue(this.hours[index].padStart(2, '0'));
    this.checkAndCloseDropdown();

    this.isClearPossible = true;
  }

  clearTimePickerField() {
    this.timeForm.patchValue('');
    this.isClearPossible = false;
  }

  checkAndCloseDropdown() {
    this.isShowDropdown = false;
    this.emitTime.emit(this.timeToMinute(this.timeForm.value));
  }

  openDropdown() {
    this.isShowDropdown = !this.isShowDropdown;
    if (this.isShowDropdown && this.timeForm.value) {
      const interval = setInterval(() => {
        if (this.dropdownScroll) {
          this.dropdownScroll.nativeElement.scroll({top: this.hours.indexOf(this.timeForm.value.toString()) * 34, behavior: "smooth"});
          clearInterval(interval);
        }
      }, 50);
    }
  }

  validateInputTime() {
    this.timeForm.valueChanges.subscribe(value => {
      if (this.timeForm.invalid) {
        this.timeForm.patchValue(value.substring(0, value.length - 1));
      } else if (value.length === 1 && parseInt(value) > 2) {
        this.timeForm.patchValue(value.padStart(2, '0'));
      } else if (value.length === 2 && parseInt(value) > 24) {
        this.timeForm.patchValue(value[0]);
      }
      this.emitTime.emit(this.timeToMinute(this.timeForm.value));
    });
  }

  timeToMinute(time: any) {
    return Number.parseInt(time) * 60;
  }
}
