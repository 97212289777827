import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {GetAirlinePipe} from "../../pipes/get-airline.pipe";
import {AIRCRAFT_CODES} from "../../constants";

@Component({
  selector: 'app-airline-img',
  templateUrl: './airline-img.component.html',
  styleUrls: ['./airline-img.component.scss']
})
export class AirlineImgComponent implements OnChanges {

  defaultImages = {
    plane: this.getPath('YY'),
    train: this.getPath('TRS'),
    bus: this.getPath('BUS')
  };

  @Input() airlineID: string;
  @Input() title: string;
  @Input() aircraftCode: string;

  src: string;
  aircraftCodes = AIRCRAFT_CODES;

  get defaultPath() {
    return this.aircraftCode === this.aircraftCodes.BUS ? this.defaultImages.bus
            : this.aircraftCodes.TRAIN.includes(this.aircraftCode) ? this.defaultImages.train
              : this.defaultImages.plane;
  }

  constructor(private getAirlinePipe: GetAirlinePipe) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.airlineID && changes.airlineID.currentValue) {
      this.airlineID = changes.airlineID.currentValue;
      this.setSrc();
    }
  }

  setSrc() {
    this.src = this.airlineID === 'YY' ? this.defaultPath : this.getPath(this.airlineID);
  }

  getPath(name: string) {
    return `assets/img/providers/jpg/${this.getAirlinePipe.transform(name)}.jpg`;
  }

}
