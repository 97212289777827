import { Pipe, PipeTransform } from '@angular/core';
import {Dictionary} from "../types/dictionary";


@Pipe({
  name: 'getDisruptionDescription'
})
export class GetDisruptionDescriptionPipe implements PipeTransform {

  transform(disruptionCode: string): string {
    return Dictionary.DisruptionCodes[disruptionCode] || disruptionCode;
  }

}
