import {Component, EventEmitter, forwardRef, HostListener, Input, OnInit, Output} from '@angular/core';
import {DataTransferService} from "../../services/data-transfer.service";
import {Subscription} from "rxjs";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

interface SelectOptionModel {
  optionValue: string;
  value: string;
}

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CustomSelectComponent),
  multi: true
};

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CustomSelectComponent implements OnInit, ControlValueAccessor {

  private innerValue;
  @Input() addEmptyOption = false;
  @Input() multiSelect = false;
  @Input() placeHolder = '';
  @Input() mapObject;
  @Input() options: SelectOptionModel[] | string[] = [];
  @Output() emitValueChange = new EventEmitter();
  private select_id;
  showOptionList = false;
  alreadyEmitedValue = true;
  subscription: Subscription;

  @HostListener('document:click', ['$event']) clickout({target}) {
    if (!target?.classList.contains('custom-select-btn')
      && !target?.classList.contains('options-list-wp')
      && !target?.classList.contains('select-btn-text-wp')) {
      this.showOptionList = false;
      if (this.multiSelect && !this.alreadyEmitedValue) {
        this.onChangeCallback(this.innerValue);
        this.emitValueChange.emit(this.innerValue);
        this.alreadyEmitedValue = true;
      }
    }
  }

  constructor(
    private dataTransferService: DataTransferService,
  ) { }

  ngOnInit() {
    this.innerValue = this.multiSelect ? [] : '';
    this.select_id = Math.random().toString(36).substr(2, 9);
    this.subscription = this.dataTransferService.customSelectActive$.subscribe(data => {
      if ( this.select_id !== data ) {
        this.showOptionList = false;
        if (this.multiSelect && !this.alreadyEmitedValue) {
          this.onChangeCallback(this.innerValue);
          this.emitValueChange.emit(this.innerValue);
          this.alreadyEmitedValue = true;
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onChangeValue(item, event) {
    if (this.multiSelect) {

      if (!Array.isArray(item)) {
        if (event.altKey) {
          event.stopPropagation();
          const indexCandidate = this.innerValue.indexOf(item);
          (indexCandidate === -1)
            ? this.innerValue.push(item)
            : this.innerValue.splice(indexCandidate, 1)
          this.innerValue = [...this.innerValue];
          this.alreadyEmitedValue = false;
        }

        if(!event.altKey && !this.alreadyEmitedValue) {
          const indexCandidate = this.innerValue.indexOf(item);
          (indexCandidate === -1)
            ? this.innerValue.push(item)
            : this.innerValue.splice(indexCandidate, 1)
          this.onChangeCallback(this.innerValue);
          this.emitValueChange.emit(this.innerValue);
          this.showOptionList = false;
          this.alreadyEmitedValue = true;
        } else if(!event.altKey && this.alreadyEmitedValue) {
          this.innerValue = [item];
          this.onChangeCallback(this.innerValue);
          this.emitValueChange.emit(this.innerValue);
          this.showOptionList = false;
          this.alreadyEmitedValue = true;
        }
      } else {
        this.innerValue = [];
        this.emitValueChange.emit(this.innerValue);
        this.onChangeCallback(this.innerValue);
      }

    } else {
      this.writeValue(item.value ? item.value : item);
      this.emitValueChange.emit(item);
      this.showOptionList = false;
    }
  }

  toggleOptionList(event) {
    if (!this.multiSelect || this.multiSelect && !event.altKey) {
      this.dataTransferService.customSelectActive$.next(this.select_id);
      this.showOptionList = !this.showOptionList;
    }
  }

  //Placeholders for the callbacks which are later providesd
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //get accessor
  get value(): any {
    return this.innerValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (!this.multiSelect && value !== this.innerValue) {
      this.innerValue = value;
      this.onChangeCallback(value);
    }
    if (this.multiSelect) {
      this.innerValue = value;
      this.onChangeCallback(value);
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
