import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPresetTravelerType'
})
export class GetPresetTravelerTypePipe implements PipeTransform {

  transform(defaultTravelerType: string, travelers: {travelerType: string, defaultType: string}[]): any {
    const foundTraveler = travelers.find((traveler) => traveler.defaultType === defaultTravelerType);
    if (foundTraveler) {
      return foundTraveler.travelerType;
    } else {
      return `<span class="dark-red-color">X</span>`;
    }
  }

}
