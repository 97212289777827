import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class MinMaxDate {
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
}

export const ProviderAgesScheme = {
  BA: {
    ADT: [16, 150],
    VFR: [16, 150],
    YAD: [12, 16],
    V14: [12, 16],
    CHD: [2, 12],
    VNN: [2, 12],
    INF: [0, 2],
    VNF: [0, 2]
  },

  VY: {
    ADT: [0, 150],
    VFR: [0, 150],
    CHD: [2, 16],
    VNN: [2, 16],
    INF: [0, 2],
    VNF: [0, 2]
  },

  default: {
    ADT: [12, 150],
    VFR: [12, 150],
    JCB: [12, 150],
    IIT: [12, 150],
    YAD: [12, 16],
    V14: [12, 16],
    CHD: [2, 12],
    VNN: [2, 12],
    CNN: [2, 12],
    INN: [2, 12],
    JNN: [2, 12],
    INF: [0, 2],
    VNF: [0, 2],
    ITF: [0, 2],
    JNF: [0, 2]
  }
};

export const FullMinMaxAgeRange = [0, 150];
