import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupByODAndMarketingName'
})
export class GroupByODAndMarketingNamePipe implements PipeTransform {

  transform(offers: any[], selectedOffer: any, currentOD: number) {
    if (!offers.length) {
      return [];
    }
    return offers.filter((offer) => {
      let flag = true;
      for (let i = 0; i < currentOD; i++) {
        if (
          (this.getODAndMarketingNameInfo(offer, i) !== this.getODAndMarketingNameInfo(selectedOffer, i))
        ) {
          flag = false;
          return;
        }
      }
      return flag;
    });
  }

  getODAndMarketingNameInfo(offer: any, flightIdx: number): string {
    return offer.flights && !!offer.flights[+flightIdx]
      ? offer.flights[flightIdx].segments.map(seg => `${seg.marketingCarrier.airlineID}${seg.marketingCarrier.flightNumber}${seg.detail.classOfService?.fare?.marketingName}`).join('-')
      : '';
  }

}
