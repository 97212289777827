import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-notification-seen-checkbox',
  templateUrl: './notification-seen-checkbox.component.html',
  styleUrls: ['./notification-seen-checkbox.component.scss']
})
export class NotificationSeenCheckboxComponent implements OnInit {
  @Input() checked: boolean;
  @ViewChild('popover') popover: NgbPopover;
  @Output() emitNotificationChange = new EventEmitter();

  buttonName = 'help_outline';


  onMouseEnter() {
    this.buttonName = 'help';
  }

  onMouseLeave() {
    if (this.popover && !this.popover.isOpen()) {
      this.buttonName = 'help_outline';
    }
  }

  @HostListener('document:click', ['$event']) onClickOut(event) {
    if (!this.elementRef.nativeElement.contains(event.target) || !event.target.className.includes('help-icon')) {
      this.buttonName = 'help_outline';
    }
  }

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
  }

  onChange($event) {
    this.emitNotificationChange.emit();
    $event.target.checked = this.checked;
  }

}
