import {Pipe, PipeTransform} from "@angular/core";
import {HelpersService} from "../services/helpers.service";

@Pipe({ name: 'containItemInArray', pure: false })
export class ContainItemInArrayPipe implements PipeTransform {

  constructor(
    public helpers: HelpersService
  ) {}

  transform(arr , item) {
    return arr.length && arr.indexOf(item) !== -1;
  }
}
