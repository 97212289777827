import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getProviderName'
})
export class GetProviderNamePipe implements PipeTransform {

  transform(provider: string): string {
    switch (provider) {
      case "LH":
        return 'LHG';
      default:
        return provider;
    }
  }
}
