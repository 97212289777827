import { Pipe, PipeTransform } from '@angular/core';
import {Dictionary} from "../types/dictionary";


@Pipe({
  name: 'getImageDescription'
})
export class GetImageDescriptionPipe implements PipeTransform {

  transform(owner: string, flights: any): string {
    const imageDescription = Dictionary.AirlineDesignators[owner] ? (owner + ' - ' + Dictionary.AirlineDesignators[owner])
                             : flights ? (flights[0].segments[0].marketingCarrier.airlineID + ' - ' + flights[0].segments[0].marketingCarrier.name)
                             : '';
    return imageDescription;
  }

}
