import {Injectable} from '@angular/core';
import {DashboardHelpersService} from './dashboard-helpers.service';

@Injectable()
export class PnrParserService {

  private passengerRows;
  private odRows;
  private parsedData;

  constructor(
    private dashboardHelpersService: DashboardHelpersService,
  ) {
  }

  public startPnrParser(text) {
    try {
      this.findRowsForParser(text);
      this.extractPassengers();
      if (this.parsedData.parsedSuccess) {
        this.extractODInfo();
      }
      if (this.parsedData &&
        (this.parsedData.passengers && !this.parsedData.passengers.length
          || this.parsedData.segments && !this.parsedData.segments.length
        )
      ) {
        throw new Error('parsing pnr was broken');
      }
      return this.parsedData;
    } catch (e) {
      console.log(e);
      this.parsedData = {parsedSuccess: false};
      return this.parsedData;
    }
  }

  private findRowsForParser(text) {
    this.passengerRows = [];
    this.odRows = [];

    const trimmedRows = text.split(/\n/).map((row, idx) => {
      const trimRow = row.trim();
      // remove prefix "row number"
      let match = trimRow.match(/^[0-9]{1,2}\s(.*)/);
      if (match && match[1]) {
        return match[1].trim();
      }
      return trimRow;
    });

    let firstODIdx = -1;
    trimmedRows.map((row, idx) => {
      // row must have 2-letter code and 4 digits, or 2-letter code and 3 digits
      if (row.match(/[A-Z0-9]{2}[ ]{0,1}[0-9]{3,4}/)) {
        if (firstODIdx < 0) {
          firstODIdx = idx;
        }
        let subRows = row.split(/\d\./ig).filter(v => !!v).map(v => v.trim())
        subRows.map(r => {
          this.odRows.push(r);
        });
      }
    });

    trimmedRows.map((row, idx) => {
      if (row.match(/[a-zA-Z ]+\/[a-zA-Z ]+( MR| MS){0,1}/) && idx < firstODIdx) {
        let subRows = row.split(/\d\./ig).filter(v => !!v).map(v => v.trim())
        subRows.map(r => {
          this.passengerRows.push(r)
        });
      }
    });
  }

  private extractPassengers() {
    this.parsedData = {parsedSuccess: true};
    if (this.passengerRows.length) {
      let infantsCount = 0;
      if (!this.parsedData.passengers) {
        this.parsedData.passengers = [];
      }
      this.passengerRows.map(item => {
        let pocket = item.split(' ').pop().match(/INF|CHD|YTH/);
        let [name,surname] = item.match(/([a-zA-Z/]+)/)[0].split('/');
        let passengerType = 'ADT';
        let appendInfants = [];

        if (pocket && pocket[0]) {
            passengerType = pocket[0];
            if (passengerType == 'YTH') {
              passengerType = 'YAD';
            }
            if (passengerType == 'INF') {
              passengerType = 'ADT';
              infantsCount++;
              appendInfants.push({
                name: "INFANT #" + infantsCount,
                type: 'INF',
              });
            }
        }

        if (name && surname && passengerType) {
          this.parsedData.passengers.push({
            name: name,
            surname: surname,
            type: passengerType,
          });
          if (appendInfants.length) {
            appendInfants.map(inf => {
              this.parsedData.passengers.push(inf);
            })
          }
        }
      });
    } else {
      this.parsedData.parsedSuccess = false;
    }
  }

  prefInt(number, len) {
    if (number.length < len) {
      return (Array(len).join('0') + number).slice(-len);
    } else {
      return number;
    }
  }

  private extractODInfo() {
    this.parsedData.segments = [];
    this.odRows.map(row => {
      const odSegment = {
        date: {day: '', month: ''},
        dateInFormat: {},
        departureAirPort: '',
        classCode: '',
        arrivalAirPort: '',
        airline: '',
        flightNumber: '',
        departureTime: '',
        arrivalTime: '',
        arrivalTimeTimeZone: ''
      };
      const startWithAirline = row;
      odSegment.airline = startWithAirline.substring(0, 2);
      const startWithFlightNumber = startWithAirline.substring(2);
      odSegment.flightNumber = startWithFlightNumber.substring(0, 5).trim();
      if (!Number(odSegment.flightNumber)) {
        this.parsedData.parsedSuccess = false;
        return;
      }
      odSegment.flightNumber = this.prefInt(odSegment.flightNumber, 4);
      const startWithClassCode = startWithFlightNumber.substring(5);
      odSegment.classCode = startWithClassCode.substring(0, 2).trim();
      const startWithDate = startWithClassCode.substring(2);
      const date = startWithDate.substring(0, 6).trim();
      odSegment.dateInFormat = this.dashboardHelpersService.convertDate(date);
      if (!odSegment.dateInFormat) {
        this.parsedData.parsedSuccess = false;
        return;
      }
      odSegment.date.day = date.substring(0, 2);
      odSegment.date.month = date.substring(2);
      const startWithRoute = startWithDate.substring(8).trim();
      const route = startWithRoute.substring(0, 7).trim();
      odSegment.departureAirPort = route.substring(0, 3);
      odSegment.arrivalAirPort = route.substring(3);
      const depTimeIndex = startWithRoute.search(/[0-9]{4}/);
      if (depTimeIndex !== -1) {

        const startWithDepartureTime = startWithRoute.substring(depTimeIndex);
        const departureTime = startWithDepartureTime.substring(0, 4).trim();
        odSegment.departureTime = `${departureTime.substring(0, 2)}:${departureTime.substring(2)}`;
        const startWithArrivalTime = startWithDepartureTime.substring(4).trim();
        const arrivalTime = startWithArrivalTime.substring(0, 4).trim();
        odSegment.arrivalTime = `${arrivalTime.substring(0, 2)}:${arrivalTime.substring(2)}`;
        const startWithArrivalTimeZone = startWithArrivalTime.substring(4).trim();
        if (startWithArrivalTimeZone[0] === '-' || startWithArrivalTimeZone[0] === '+') {
          odSegment.arrivalTimeTimeZone = startWithArrivalTimeZone.substring(0, 3).trim();
        }
      } else {
        this.parsedData.parsedSuccess = false;
      }

      this.parsedData.segments.push(odSegment);
    });
  }

}
