import {Pipe, PipeTransform} from "@angular/core";

@Pipe({ name: 'getPassengerNameByRefPipe' })
export class GetPassengerNameByRefPipe implements PipeTransform {
  transform(passengers: any[], reference: string) {
    let result;
    if (passengers && passengers.length) {
      passengers.map(passenger => {
        if (passenger.travelerReference === reference) {
          result = `${passenger.data.name} ${passenger.data.surname}`;
        }
      });
    }
    return result ? result : reference;
  }
}
