import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable()
export class DataTransferService {
  public umbrellaLogOut$ = new Subject();
  public selectedCabin$ = new Subject();
  public itinReshopData$ = new Subject();
  public consoleSearchTrigger$ = new Subject();
  public customSelectActive$ = new Subject();
  public inputDropDown$ = new Subject();

  public changeDateOrderData = {
    targetOrder: {},
    selectedDate: {},
    originDestinations: [],
    type: '',
  };
}
