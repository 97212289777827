import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-connection-list-view',
  templateUrl: './connection-list-view.component.html',
  styleUrls: ['./connection-list-view.component.scss']
})
export class ConnectionListViewComponent {

  isDirectFlightsOnly = false;
  isDirectFlightsIncluded = true;
  @Input() connectionsSimpleModel: any;
  @Input() route: any;
  @Input() isFirstRoute: boolean;
  @Input() connection: any;
  @Input() connectionPrices: any;
  @Output() emitConnectionsValueChanged = new EventEmitter();
  @Output() emitSelectedOneConnection = new EventEmitter();
  @Output() emitDirectFlightsSelected = new EventEmitter();
  @Output() emitDirectFlightsOnly = new EventEmitter();
  @Output() emitSelectAllCheckboxes = new EventEmitter();

  directFlightsOnly() {
    this.isDirectFlightsOnly = true;
    this.isDirectFlightsIncluded = true;
    this.emitDirectFlightsOnly.emit({isDirectFlightOnly: this.isDirectFlightsOnly, route: this.route});
  }

  directFlightsSelected(value) {
    this.emitDirectFlightsSelected.emit(value);
    this.isDirectFlightsIncluded = value;
  }

  selectedOneConnection(connection, route) {
    this.isDirectFlightsIncluded = false;
    this.emitSelectedOneConnection.emit({connection: connection, route: route});
  }

  constructor() { }

  selectAllCheckboxes(route) {
    this.directFlightsSelected(true);
    this.emitSelectAllCheckboxes.emit(route);
  }
}
