import {ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {interval} from 'rxjs';
import {map} from 'rxjs/operators';
import moment from 'moment';

@Component({
  selector: 'app-offer-expiration',
  templateUrl: './offer-expiration.component.html',
  styleUrls: ['./offer-expiration.component.scss'],
})
export class OfferExpirationComponent implements OnInit {
  @Input() time = 0;
  @Input() expiresAt = 0;
  @Output() emitOfferExpiration = new EventEmitter();

  $counter: any;
  timeExpiresAt: any = 0;
  calculatedTime: any = 0;
  message = '';
  private subscription: any;

  constructor(
    private cdRef: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
  }

  ngOnInit() {
    this.cdRef.detach();
    if (this.expiresAt) {
      this.timeExpiresAt = moment(this.expiresAt).utc().unix();
    } else {
      this.timeExpiresAt = moment(this.time).utc().unix() + 60 * 15;
    }

    let timestamp = moment().utc().unix();
    this.calculatedTime = this.timeExpiresAt - timestamp;
    this.message = this.formatTime(this.calculatedTime);

    this.$counter = interval(1000).pipe(
      map((x) => {
        let timestamp = moment().utc().unix();
        this.calculatedTime = this.timeExpiresAt - timestamp;
        if (this.calculatedTime <= 0) {
          this.emitOfferExpiration.emit(true);
        }
        return x;
      })
    );

    this.ngZone.runOutsideAngular(() => {
      this.subscription = this.$counter
        .subscribe((x) => {
          this.message = this.formatTime(this.calculatedTime);
          if (!this.cdRef['destroyed']) {
            this.cdRef.detectChanges();
          }
        });
    });


  }

  formatTime(t) {
    let minutes, seconds;
    minutes = Math.floor(t / 60) % 60;
    t -= minutes * 60;
    seconds = t % 60;

    return minutes + ':' + seconds.toString().padStart(2, '0');
  }
}
