import { Component, OnInit } from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {Agent} from "../../shared/types/agent";
import {Subject} from "rxjs";
import {HubAgencyService} from "../../shared/services/hub-agency.service";

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss']
})
export class AgentsComponent implements OnInit {

  agents: Agent[] = [];
  copiedAgents: Agent[] = [];
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private agencyService: HubAgencyService) { }

  ngOnInit(): void {
    this.getAgents();
  }

  getAgents() {
    this.agencyService.getAll()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res: any) => {
        if (res.body) {
          this.agents = res.body.filter((agent: Agent) => agent.account_type === 'agent');
          this.copiedAgents = JSON.parse(JSON.stringify(this.agents));
        }
      }, err => console.log(err));
  }

}
