import {Pipe, PipeTransform} from "@angular/core";

@Pipe({ name: 'padStart' })
export class PadStartPipe implements PipeTransform {
  transform(value: string | number, count: number = 0, symbol: string) {
    return typeof value === 'number'
      ? value.toString().padStart(count, symbol)
      : (typeof value === 'string')
        ? value.padStart(count, symbol)
        : value;
  }
}
