import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortFlightsByRoutes'
})
export class SortFlightsByRoutesPipe implements PipeTransform {

  transform(sortingArr: any[], flightsArr: any[], flightList = true): any[] {

    let segments = [];
    let key = '';
    if (flightsArr) {
      flightsArr.map(flight => {
        if (flightList) {
          key = flight.departure.airportCode + '-' + flight.arrival.airportCode;
        } else {
          key = flight.originDestination.departure.airportCode + '-' + flight.originDestination.arrival.airportCode;
        }
        if (!segments.includes(key)) {
          segments.push(key);
        }
      });
      sortingArr.sort((a, b) => segments.indexOf(a.key) - segments.indexOf(b.key));
    }

    return sortingArr;
  }

}
