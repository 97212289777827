import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  BAGGAGE_ALLOWANCE_STATUS, BAGGAGE_ALLOWANCE_TYPE,
  BaggageAllowance,
  BaggageAllowanceData,
  BaggageAllowanceInfo,
  BaggageAllowanceOption,
  Option,
  OPTION_CHOOSE_TYPE,
  OptionProperty
} from '../../interfaces/disclosures';
import {GroupByKeyPipe} from "../../pipes/group-by-key.pipe";
import {getTravelerTitle, ShortPassenger} from "../../interfaces/passenger";
import {KeyValuePair} from "../../interfaces/key-value-pair";
import * as pluralize from 'pluralize';

@Component({
  selector: 'app-baggage-allowance-info',
  templateUrl: './baggage-allowance-info.component.html',
  styleUrls: ['./baggage-allowance-info.component.scss']
})
export class BaggageAllowanceInfoComponent implements OnInit, OnChanges {

  @Input() baggageAllowance: BaggageAllowance;
  @Input() iconSize = 38;
  @Input() passengers?: ShortPassenger[];
  @Input() isPdf = false;
  @Input() isCollapsed = false;
  BAGGAGE_ALLOWANCE_STATUS = BAGGAGE_ALLOWANCE_STATUS;
  OPTION_CHOOSE_TYPE = OPTION_CHOOSE_TYPE;
  BAGGAGE_ALLOWANCE_TYPE = BAGGAGE_ALLOWANCE_TYPE;
  baggageCheckedList: BaggageAllowanceData[] = [];
  baggageCarryOnList: BaggageAllowanceData[] = [];
  baggageCheckedQuantity = 0;
  baggageCarryOnQuantity = 0;

  constructor(private groupGyKey: GroupByKeyPipe) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.baggageAllowance ) {
      this.baggageCheckedList = this.prepareBaggageAllowanceData(this.baggageAllowance?.checked);
      this.baggageCarryOnList = this.prepareBaggageAllowanceData(this.baggageAllowance?.carryOn);

      this.baggageCheckedQuantity = this.countBaggageQuantity(this.baggageCheckedList);
      this.baggageCarryOnQuantity = this.countBaggageQuantity(this.baggageCarryOnList);
    }
  }

  ngOnInit(): void {
  }

  prepareBaggageAllowanceData(baggageAllowance: BaggageAllowanceInfo[]): BaggageAllowanceData[]  {
    if (baggageAllowance?.length > 0) {
      const baggageAllowanceData = baggageAllowance.map(baggage => {
        if (baggage.options?.length > 0) {
          const status = this.checkStatus(baggage.options)
            ? BAGGAGE_ALLOWANCE_STATUS.ALLOWED
            : BAGGAGE_ALLOWANCE_STATUS.DISALLOWED;
          const options: Option[] = baggage.options?.map(option => {
              const groupedProperties = this.groupGyKey.transform(option.properties, 'type');
              const optionProperties: OptionProperty[] = [];
              for (let type in groupedProperties) {
                if (groupedProperties.hasOwnProperty(type)) {
                  optionProperties.push({
                    text: groupedProperties[type].reduce((acc, curr, i) => {
                      const divider = i === 0 ? ': ' : ' / ';
                      return acc + divider + (curr.value || '') + curr.UOM?.toLowerCase();
                    }, type),
                    type: type
                  });
                }
              }
              return { properties: optionProperties, text: option.text, quantity: option.quantity };
          });

          return { passengersInfo: this.getPassengerInfo(baggage), status, options, chooseType: baggage.chooseType, type: baggage.type };
        } else {
          return null;
        }
      }).filter(baggage => baggage !== null);

      if (baggageAllowanceData?.length > 0 && baggageAllowanceData.findIndex(baggage => baggage?.status === BAGGAGE_ALLOWANCE_STATUS.ALLOWED) === -1) {
        return baggageAllowanceData.slice(0, 1);
      } else {
        return this.removeBaggageDuplicate(baggageAllowanceData);
      }
    }
    return [];
  }


  getPassengerInfo(baggage: BaggageAllowanceInfo): string[] {
    const passengerRefsArr = baggage.passengerRefs.split(' ');
    const passengerCountByType: KeyValuePair<number> = {};
    passengerRefsArr.forEach(passengerRef => {
      const findPassenger =
        this.passengers?.find(passenger => passenger.travelerReference === passengerRef);

      if (findPassenger) {
        passengerCountByType[findPassenger.passengerType] =
          passengerCountByType.hasOwnProperty(findPassenger.passengerType)
            ? passengerCountByType[findPassenger.passengerType] + 1
            : 1;
      }
    });
    const passengersInfo: string[] = [];

    for (let key in passengerCountByType) {
      if (passengerCountByType.hasOwnProperty(key) && getTravelerTitle(key)) {
        passengersInfo.push(passengerCountByType[key] + ' ' + pluralize(getTravelerTitle(key)?.toLowerCase(), passengerCountByType[key]));
      }
    }

    const result = [passengerRefsArr.length + ' ' + pluralize('passenger', passengerRefsArr.length)];
    if (passengersInfo?.length > 0) {
      result.push('(' + passengersInfo.join(', ') + ')');
    }
    return result;
  }

  removeBaggageDuplicate(baggageList: BaggageAllowanceData[]): BaggageAllowanceData[] {
    if (baggageList?.length > 0) {
      const uniqSet = new Set<string>();
      return baggageList.filter(baggage => {
        const uniqKey = baggage.passengersInfo + baggage.options?.map(opt => opt.text).join('_');
        if (uniqSet.has(uniqKey)) {
          return false;
        } else {
          uniqSet.add(uniqKey);
          return true;
        }
      });
    }

    return [];
  }

  checkStatus(options: BaggageAllowanceOption[]) {
    return options?.length > 0 && (options.findIndex(o => o.quantity > 0) > -1);
  }

  countBaggageQuantity(baggageAllowanceData: BaggageAllowanceData[]) {
    let quantity = 0;
    baggageAllowanceData.map(baggage => {
      quantity += baggage.options.reduce((accumulator, option) => accumulator + option.quantity, 0);
    });
    return quantity;
  }
}
