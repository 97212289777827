import {
  AfterViewChecked, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges, OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import { NavigationStart, Router, RoutesRecognized } from '@angular/router';
import {DataTransferService} from "../../shared/services/data-transfer.service";
import {Subject} from "rxjs";
import { take, takeUntil } from 'rxjs/operators';
import {DashboardHelpersService} from "../services/dashboard-helpers.service";
import {AmadeusParserToInput} from "../models/amadeus-parser-to-input.model";
import {ParserAmadeusCommandService} from "../services/parser-amadeus.command.service";
import moment from 'moment';

@Component({
  selector: 'app-amadeus-input',
  templateUrl: './amadeus-input.component.html',
  styleUrls: ['./amadeus-input.component.scss']
})
export class AmadeusInputComponent implements OnChanges, OnInit, AfterViewChecked, OnDestroy {

  inputValue = '';
  inputPlaceholder = '';
  @Output() emitParsedData = new EventEmitter();
  @Output() emitAbort = new EventEmitter();
  @Output() emitInputValue = new EventEmitter();
  @Input() allowNewSearch;
  @Input() gdsString;
  @ViewChild('searchInputElement', { static: true }) searchInputElement: ElementRef;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  errorInStep = '';
  setFocus = 0;

  constructor(
    private dataTransferService: DataTransferService,
    private dashboardHelpersService: DashboardHelpersService,
    private parserAmadeusCommandService: ParserAmadeusCommandService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.gdsString && changes.gdsString.currentValue) {
      this.inputValue = changes.gdsString.currentValue;
    }
  }

  ngOnInit() {

    this.setSimpleCommand();

      this.dataTransferService.consoleSearchTrigger$
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(res => {
        if (res) {
          this.amadeusSearch();
        }
      })

    this.parserAmadeusCommandService.amadeusComandParsedData$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(res => {
        this.newGoToSearch(res as AmadeusParserToInput);
      })
  }

  ngAfterViewChecked(): void {
    if (this.setFocus === 0) {
      this.searchInputElement.nativeElement.focus();
      this.setFocus = 1;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  amadeusSearch() {
    this.errorInStep = '';
    this.inputValue = this.inputValue.trim();
    if (this.inputValue.match(/^[0-9]{2}[a-zA-Z]{3}/)) {
      if (this.inputValue.length && this.allowNewSearch) {
        this.emitInputValue.emit(this.inputValue);
        this.parserAmadeusCommandService.starSearch(this.inputValue);
      }
    } else {
      this.showParseError();
    }

  }

  private newGoToSearch(data: AmadeusParserToInput) {
    if (!data.errorMsg && data.data.originDestinations.length) {
      this.emitParsedData.emit(data.data);
    } else {
      this.showParseError(data.errorMsg ? data.errorMsg : '');
    }
  }

  showParseError(place = '') {
    this.errorInStep = place ? place : `Incorrect command for search.`;
  }

  setSimpleCommand() {
    const departureDate = moment();
    const arrivalDate = moment().add(3, 'days');
    const depDay = departureDate.date();
    const depMonth = departureDate.month(departureDate.month()).format('MMM').toUpperCase()
    const arrDay = arrivalDate.date();
    const arrMonth = arrivalDate.month(arrivalDate.month()).format('MMM').toUpperCase();
    this.inputPlaceholder = `${depDay <= 9 ? ('0' + depDay) : depDay}${depMonth}MADJFK17P/XZRH*${arrDay <= 9 ? ('0' + arrDay) : arrDay}${arrMonth}JFKBCN2100/FN`;
  }

  onFocus() {
    if (this.inputValue === this.inputPlaceholder) {
      this.inputValue = '';
      this.cdr.detectChanges();
    }
  }

  onBlur() {
    if (this.inputValue === '') {
      this.inputValue = this.inputPlaceholder;
      this.cdr.detectChanges();
    }
  }
}
