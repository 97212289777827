import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Config} from "../models/config.model";

@Injectable()
export class ConfigService {

  constructor(private http: HttpClient) { }

  KEYS: Config;

  load() {
      return  this.http.get<Config>('assets/config/config.json').toPromise().then(config => {
        this.KEYS = config;
      }).catch(error => {
        this.KEYS = {
          oldGtmId: '',
          gtmId: '',
          gtagId: 'UA-XXXXXXXX-X',
          sentryDSN: '',
          coviewAppKey: 'XXXXXXXXXXX',
          logoName: 'bookingpad.svg',
          title: 'Title',
          helpCrunchSecret: ''
        };
      });
  }
}
