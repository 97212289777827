import {Pipe, PipeTransform} from "@angular/core";

@Pipe({ name: 'formatFlightNumber' })
export class FormatFlightNumberPipe implements PipeTransform {
  transform(flightNumber: string, onlyNumbers: boolean = false) {
    if (flightNumber) {
      if (onlyNumbers) {
        return flightNumber.substring(2);
      } else {
        return `${flightNumber.substring(0, 2)} ${flightNumber.substring(2)}`;
      }
    }
    return flightNumber;
  }
}
