import { Pipe, PipeTransform } from '@angular/core';
import {ServicePerPsgAndSegmentPipe} from "./service-per-psg-and-segment.pipe";
import {SeatsPerPsgAndSegmentPipe} from "./seats-per-psg-and-segment.pipe";
import {SsrPerPsgAndSegmentPipe} from "./ssr-per-psg-and-segment.pipe";

@Pipe({
  name: 'segmentHasAncillaries'
})
export class SegmentHasAncillariesPipe implements PipeTransform {

  constructor(private servicePerPsgAndSegmentPipe: ServicePerPsgAndSegmentPipe,
              private seatsPerPsgAndSegmentPipe: SeatsPerPsgAndSegmentPipe,
              private ssrPerPsgAndSegmentPipe: SsrPerPsgAndSegmentPipe) {
  }

  transform(order: any, passenger: any, segment: any): boolean {
    let hasServices = this.servicePerPsgAndSegmentPipe.transform(order.services, passenger.travelerReference, segment.segmentID)?.length;
    let hasSeats = this.seatsPerPsgAndSegmentPipe.transform(order.seats, passenger.travelerReference, segment.segmentID)?.length;
    let hasSSR = this.ssrPerPsgAndSegmentPipe.transform(passenger.specialServices, segment.segmentID)?.length;

    return hasServices || hasSeats || hasSSR;
  }

}
