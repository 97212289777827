import {Injectable} from "@angular/core";
import moment from 'moment';
import {MAX_DAYS_IN_MONTH} from "../../shared/constants";

@Injectable()
export class DashboardHelpersService {

  MAX_DAYS_IN_MONTH = MAX_DAYS_IN_MONTH;

   convertDate(str) {
    let temp = {};
    const currentDate = new Date();
    const day = +str.substr(0, 2);

    const tempMonthIdx = moment.monthsShort().indexOf(`${str.substr(2, 1).toUpperCase()}${str.substr(3).toLowerCase()}`);

    if (tempMonthIdx !== -1) {

      temp['month'] = tempMonthIdx + 1;

      if (typeof day === 'number' && day <= this.MAX_DAYS_IN_MONTH[temp['month']]) {
        temp['day'] = day;
      } else {
        temp = null;
        return;
      }

      if (tempMonthIdx === currentDate.getMonth() && temp['day'] === currentDate.getDate() ) {
        temp['year'] = currentDate.getFullYear();
      } else {
        const candidateUnix = moment(`${currentDate.getFullYear()}-${temp['month']}-${temp['day']}`,'YYYY-MM-DD').unix();
        temp['year'] = moment().unix() > candidateUnix ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
      }
    } else {
      temp = null;
    }
    return temp;
  }}
