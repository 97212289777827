import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fare-rules-filter',
  templateUrl: './fare-rules-filter.component.html',
  styleUrls: ['./fare-rules-filter.component.scss']
})
export class FareRulesFilterComponent implements OnInit {

  @Input() label = "Change";
  @Input() selectedOption = "Disallowed(All)";
  @Output() onOptionSelected = new EventEmitter();


  constructor() { }

  options = [
    { name: "Not Allowed", value: "Any" },
    { name: "Allowed", value: "Allowed", description: "Only Allowed" },
    { name: "For Fee", value: "With Fee", description: "Included: With Fee, Allowed" },
  ];


  ngOnInit(): void {
  }

  selectOptions(option: any) {
    this.selectedOption = option.value;
    this.onOptionSelected.emit(option);
  }
}
