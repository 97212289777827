import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPassengerFromControlName'
})
export class GetPassengerFromControlNamePipe implements PipeTransform {

  transform(controlName: string | any): string | any {
    if (controlName?.includes('passenger')) {
      let splittedName = controlName.split('_');
      return splittedName[1].toUpperCase() + ' ' + (+splittedName[2] + 1);
    } else {
      return controlName;
    }
  }

}
