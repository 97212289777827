import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-show-alternative-ids',
  templateUrl: './show-alternative-ids.component.html',
  styleUrls: ['./show-alternative-ids.component.scss']
})
export class ShowAlternativeIdsComponent implements OnInit {

  @Input() alternativeIDs: {id: string, providerID: string}[];
  @Input() isOpened = false;
  @ViewChild('popover') popover: NgbPopover;

  buttonName = 'info_outline';

  @HostListener('click') onClick() {
    if (this.popover && !this.popover.isOpen()) {
      this.popover.open();
    }
  }

  @HostListener('mouseover') onMouseEnter() {
    this.buttonName = 'info';
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.popover && !this.popover.isOpen()) {
      this.buttonName = 'info_outline';
    }
  }

  @HostListener('document:click', ['$event']) onClickOut(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.buttonName = 'info_outline';
    }
  }

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
  }

}
