import {Pipe, PipeTransform} from "@angular/core";

@Pipe({ name: 'ng5OptionPipe' })
export class Ng5OptionPipe implements PipeTransform {
  transform(minValue: number, maxValue: number, step = 1 ) {
    return {
      floor: minValue,
      step: step,
      ceil: maxValue,
      minRange: 2,
      enforceStep: false,
      enforceRange: false,
      pushRange: true,
    };
  }
}
