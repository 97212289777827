import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CounterComponent implements OnInit {
  @Input() count = 0;
  @Input() minValue = 0;
  @Input() maxValue = Number.MAX_VALUE;
  @Input() iconFontSize = '30px';
  @Input() textFontSize = '20px';
  @Output() emitCountChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit() {
    this.count = !!this.count ? this.count : 0;
  }

  increaseCounter() {
    if (this.count < this.maxValue) {
      this.count++;
      this.emitCountChange.emit(this.count);
    }

  }

  decreaseCounter() {
    if (this.count > this.minValue) {
      this.count--;
      this.emitCountChange.emit(this.count);
    }
  }
}
