import { Pipe, PipeTransform } from '@angular/core';
import {HelpersService} from "../services/helpers.service";

@Pipe({
  name: 'getAirline'
})
export class GetAirlinePipe implements PipeTransform {

  constructor(private helpers: HelpersService) {
  }

  transform(value: string): string {
    return value && value[2] && this.helpers.isNumber(value[2]) ? value.substring(0, 2) : value;
  }

}
