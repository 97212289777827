import {Injectable} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateService} from "./date.service";
import moment from 'moment';

export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

const monthNameToIndex = {
  JAN: 1,
  FEB: 2,
  MAR: 3,
  APR: 4,
  MAY: 5,
  JUN: 6,
  JUL: 7,
  AUG: 8,
  SEP: 9,
  OCT: 10,
  NOV: 11,
  DEC: 12,
};

const monthNames = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

@Injectable()
export class AirGatewayDateFormatter extends NgbDateParserFormatter {
  constructor(private dateService: DateService) {
    super();
  }
  parse(value: string): NgbDateStruct {
    if (value) {
      let stringLen = value.length;
      if (stringLen < 9) {
        value = value.padStart(7, '0');
      }
      let dt = {
        year: toInteger(value.substr(5, 4)),
        month: monthNameToIndex[value.substr(2, 3).toUpperCase()],
        day: toInteger(value.substr(0, 2))
      };
      if (monthNameToIndex[value.substr(2, 3).toUpperCase()] !== undefined &&
        toInteger(value.substr(5, 2)) &&
        toInteger(value.substr(0, 2))) {
        return dt;
      }
    }

    return null;
  }

  format(date: NgbDateStruct): string {
    return date ? moment.utc(`${date.day}-${date.month}-${date.year}`, 'DD-MM-YYYY').format(this.dateService.dateFormat).toUpperCase() : '';
  }

}
