import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Discount, EditDiscount, DiscountForm } from "../../types/discount-code";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {LOYALTY_PROGRAM_ACCOUNT_SUPPORTED_PROVIDERS} from "../../constants";

@Component({
  selector: 'app-discounts-form',
  templateUrl: './discounts-form.component.html',
  styleUrls: ['./discounts-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscountsFormComponent implements OnInit, OnDestroy {
  @Input() discounts: Discount[];
  @Input() showAddedEditedLoader = false;
  @Input() showRemovedLoader = false;
  @Output() emitAddDiscount: EventEmitter<Discount> = new EventEmitter<Discount>();
  @Output() emitRemoveDiscount: EventEmitter<number> = new EventEmitter<number>();
  @Output() emitEditDiscount: EventEmitter<EditDiscount> = new EventEmitter<EditDiscount>();

  readonly LOYALTY_PROGRAM_ACCOUNT_SUPPORTED_PROVIDERS = LOYALTY_PROGRAM_ACCOUNT_SUPPORTED_PROVIDERS;

  validateManagerTriggered = false;
  editIdx = -1;
  discountForm: FormGroup;
  formControlNames: DiscountForm = {
    provider: 'provider',
    discountCode: 'discountCode',
    loyaltyProgramNumber: 'loyaltyProgramNumber'
  };
  removeDiscountInfo: EditDiscount;
  showRemovedLoaderInForm = false;

  // pagination
  page = 1;
  pageSize = 10;


  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.buildDiscountForm();
    this.fillDiscountFormData();
    this.parseUrl();
  }

  showManagerForm() {
    this.validateManagerTriggered = false;
    this.resetDiscountForm();
  }

  validateManager() {
    this.validateManagerTriggered = true;
    return this.discountForm.valid;
  }

  startEditDiscount(idx: number, dc: Discount) {
    this.resetDiscountForm();
    this.discountForm.setValue({
      provider: dc.provider,
      discountCode: dc.discountCode || '',
      loyaltyProgramNumber: dc.loyaltyProgramNumber || ''
    } as DiscountForm);
    this.editIdx = idx;
  }

  finishEditDiscount(c) {
    if (!this.validateManager()) {
      return false;
    }
    this.emitEditDiscount.emit({
      discount: this.discountForm.value,
      idx: this.editIdx
    });
    this.resetDiscountForm();
    this.editIdx = -1;
    c('ok');
  }

  addDiscount(c) {
    if (!this.validateManager()) {
      return false;
    }
    this.emitAddDiscount.emit(this.discountForm.value);
    this.resetDiscountForm();
    c('ok');
  }

  removeDiscount(c) {
    this.emitRemoveDiscount.emit(this.removeDiscountInfo.idx);
    c('ok');
  }

  cancelDiscountForm() {
    this.resetDiscountForm();
    this.editIdx = -1;
  }

  private buildDiscountForm() {
    this.discountForm = this.fb.group({
      [this.formControlNames.provider]: ['', Validators.required],
      [this.formControlNames.discountCode]: [''],
      [this.formControlNames.loyaltyProgramNumber]: [''],
    });
  }

  private fillDiscountFormData() {
    let currentProvider = '';
    this.discountForm.get('provider').valueChanges.subscribe(provider => {
      if (currentProvider !== provider) {
        currentProvider = provider;
        const discount = this.discounts.find(disc => disc.provider === provider);
        this.discountForm.patchValue({
          discountCode: discount?.discountCode || '',
          loyaltyProgramNumber: discount?.loyaltyProgramNumber || ''
        } as DiscountForm);
      }
    });
  }

  private resetDiscountForm() {
    this.discountForm.reset();
    this.validateManagerTriggered = false;
  }

  openRemoveModal(content, discount: Discount, idx, size: any = 'sm') {
    this.removeDiscountInfo = {
      idx, discount
    };
    this.open(content, size);
  }

  open(content, size: any = 'sm') {
    this.modalService.open(content, {
      size,
      keyboard: false,
      backdrop: 'static'
    }).result.then((result) => {}, (reason) => {});
  }

  onPageChange() {
    let params = { ...this.route.snapshot.queryParams };
    params['discountsPage'] = this.page;
    params['pageSize'] = this.pageSize;
    this.router.navigate([], {queryParams: params});
  }

  parseUrl() {
    let params = { ...this.route.snapshot.queryParams };
    this.page = parseInt(params.discountsPage) || 1;
    this.pageSize = parseInt(params.pageSize) || 10;
  }

  ngOnDestroy() {
  }
}
