export const environment = {
  appName: 'bookingpad',
  name: 'staging',
  production: true,
  ndcApiEndpoint: 'https://api.airgateway.work/',
  ndcApiEndpointV2: 'https://api.airgateway.work/v2.0/',
  keycloakUrl: 'https://auth.airgateway.work',
  hashVersion: '6aa7716f',
  coviewAppKey: 'UtgDnY7wWIk',
  domain: '.work',
  default: {
    logoName: 'bookingpad.svg',
    title: 'BookingPad :: NDC-enabled Booking Tool',
    showSearch: true,
    showOrders: true,
    showSubagencies: true,
    showFooter: true,
    showPrivacy: true,
    showCoView: true,
    showSettings: true,
    showCorporates: true,
    showMaintenancePage: false
  },
  frontendDependencies: [{
    logoName: 'apg_logo.svg',
    subDomain: 'apg.bookingpad.work',
    title: 'Orchestra NDC Orders',
    showSearch: false,
    showOrders: true,
    showSubagencies: false,
    showFooter: false,
    showPrivacy: false,
    showCoView: false,
    showSettings: false,
    showCorporates: false,
    showMaintenancePage: false
  }]
};
