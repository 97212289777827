import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";
import {HubCorporateService} from "../../shared/services/hub-corporate.service";
import {takeUntil} from "rxjs/operators";
import {Corporate} from "../../shared/types/corporate";
import {NotificationService} from "../../shared/services/notification.service";
import {HelpersService} from "../../shared/services/helpers.service";

@Component({
  selector: 'app-delete-corporate-modal',
  templateUrl: './delete-corporate-modal.component.html',
  styleUrls: ['./delete-corporate-modal.component.scss']
})
export class DeleteCorporateModalComponent implements OnInit {

  @Input() corporate: Corporate;
  @Input() isRedirectionNeeded = false;
  @Output() emitClose = new EventEmitter();
  @Output() emitDismiss = new EventEmitter();
  @Output() emitDeleteCorporate = new EventEmitter<boolean>();

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private hubCorporateService: HubCorporateService,
              private _notificationSvc: NotificationService,
              private helpers: HelpersService) { }

  ngOnInit(): void {
  }

  delete() {
    this.hubCorporateService.delete(this.corporate.id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response: any) => {
        const res = response.body;
        if (res.status === 'OK') {
          if (this.isRedirectionNeeded) {
            this.helpers.go('/corporates');
          }
          const notificationMessage = 'Corporate has been successfully deleted';
          this._notificationSvc.clearNotifications();
          this._notificationSvc.success('SUCCESS', notificationMessage, 0);
          this.emitDeleteCorporate.emit(true);
          this.emitClose.emit();
        }
      });
  }
}
