import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./dashboard.component";
import { AuthGuard } from "../shared/guards/auth.guard";
import { UmbrellaAuthComponent } from "../umbrella-auth/umbrella-auth.component";
import { ShowFrontGuard } from "../shared/guards/show-front.guard";
import {KeycloakGuard} from "../shared/guards/keycloak-guard";

const routes: Routes = [
  {
    path: 'search',
    canActivate: [ShowFrontGuard, AuthGuard, KeycloakGuard],
    data: {route: 'search'},
    component: DashboardComponent
  },
  {
    path: 'umbrella-auth',
    canActivate: [AuthGuard, KeycloakGuard],
    component: UmbrellaAuthComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {}
