import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import {ManagementRoutingModule} from "./management-routing.module";
import {ManagementComponent} from "./management.component";
import { AgentsComponent } from './agents/agents.component';
import { SubagenciesComponent } from './subagencies/subagencies.component';
import {AgentListComponent} from "./agent-list/agent-list.component";
import { SubagencyAgentsComponent } from './subagencies/subagency-agents/subagency-agents.component';


@NgModule({
  declarations: [
    ManagementComponent,
    AgentListComponent,
    AgentsComponent,
    SubagenciesComponent,
    SubagencyAgentsComponent
  ],
  imports: [
    ManagementRoutingModule,
    SharedModule
  ],
  providers: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class ManagementModule { }
