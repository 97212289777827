import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HubService {
  private baseUrl = environment.ndcApiEndpointV2 + 'agent/account/';

  constructor(private http: HttpClient) { }


  getMe() {
    const url = this.baseUrl + 'info';

    return this.http.get(url)
      .toPromise();
  }

  getAgencies() {
    const url = this.baseUrl + 'agencies';

    return this.http.get(url).toPromise();
  }

}
