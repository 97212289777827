import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Observable} from "rxjs/internal/Observable";
import * as moment from 'moment-timezone';
import {LocalStorageService} from "./local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  private timezoneSubject$ = new BehaviorSubject<string>(this.getDefaultTimezone());

  constructor(private ls: LocalStorageService) { }

  getTimezones(): string[] {
    return moment.tz.names();
  }

  getDefaultTimezone(): string {
    return this.ls.timezone || moment.tz.guess() || 'UTC';
  }

  setTimezone(timezone: string): void {
    this.timezoneSubject$.next(timezone);
    this.ls.timezone = timezone;
  }

  getTimezone(): Observable<string> {
    return this.timezoneSubject$.asObservable();
  }
}
