import {Component, Input, OnInit} from '@angular/core';
import {rotateAnimation} from "angular-animations";

@Component({
  selector: 'app-order-log',
  templateUrl: './order-log.component.html',
  styleUrls: ['./order-log.component.scss'],
  animations: [
    rotateAnimation({ degrees: -180, duration: 500 }),
  ]
})
export class OrderLogComponent implements OnInit {
  @Input() logs = [];

  isDefaultSort = true;

  constructor() { }

  ngOnInit(): void {
  }

  toggleSort() {
    this.isDefaultSort = !this.isDefaultSort;
    this.logs = this.logs.slice().reverse();
  }

}
