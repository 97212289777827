import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortPassengerControlNames'
})
export class SortPassengerControlNamesPipe implements PipeTransform {

  transform(array: any): any {

    const sortPriority = [
      'name', 'surname', 'birthdate', 'title', 'gender',
      'email', 'countryCode', 'countryCodeISO', 'number', 'airlineID',
      'documentType', 'documentID', 'expirationDate', 'fiscalName', 'documentIdResident',
      'documentTypeResident', 'documentIdFamily', 'documentTypeFamily'
    ];

    return array?.sort((a, b) => sortPriority.indexOf(a?.key) - sortPriority.indexOf(b?.key));
  }

}
