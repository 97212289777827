import { AbstractControl, ValidatorFn } from '@angular/forms';

//
export class RemarksValidators {
  static floatTypeWithNumber(maxDigAfterDot: number | null): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const query = parseFloat(control.value);
      const numbersAfterDot = query?.toString().split('.')[1]?.length;
      if (!control.value?.length || (!isNaN(query) && numbersAfterDot <= maxDigAfterDot)) {
        return null;
      }

      return {floatTypeWithNumber: true};
    };
  }

  static floatType (): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const query = parseFloat(control.value);
      if (!control.value?.length || !isNaN(query)) {
        return null;
      }
      return {floatType: true};
    };
  }
}
