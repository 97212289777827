import { Component, ElementRef, Input, OnInit, Output, Renderer2, ViewChild, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RemarksValidators } from '../../../shared/validators/remarks.validators';
import { KeyValuePair } from '../../interfaces/key-value-pair';
import {
  REMARK_ELEM_TYPE,
  REMARK_NAME,
  RemarkDataToUpdate,
  RemarkElement,
  RemarkTextToUpdate
} from '../../interfaces/remarks';
import {LocalStorageService} from "../../services/local-storage.service";

@Component({
  selector: 'app-remarks-template',
  templateUrl: './remarks-template.component.html',
  styleUrls: ['./remarks-template.component.scss']
})
export class RemarksTemplateComponent implements OnInit {


  @Input() readonly remark: any;
  @Input() orderInfo;
  @Input() templates;
  @Input() isButtonVisible;
  @Input() mandatoryRemarks;
  @Input() isRemarksAfterOrderCreation: boolean;
  @Input() validateTriggered = false;
  @Input() bothRemarksAreFilled = false;
  @Output() emitTemplateSelected = new EventEmitter<boolean>();
  @Output() emitUpdatedRemark = new EventEmitter<RemarkDataToUpdate>();

  @ViewChild('remarks') remarksRef: ElementRef;
  constructor(private ls: LocalStorageService) { }
  
  parsedRemarks: RemarkElement[][] = [];
  inputValues: KeyValuePair<string> = {};
  remarkFormGroup = new FormGroup({});
  selectedTemplate: string;
  remarkName: string;
  fieldToDisable = ['origin', 'destination', 'psg-index', 'psg-sales-price', 'travelerReference', 'agent-id', 'agent-custom-id', 'agency-id', 'order-id', 'number'];
  psgInfo = [];

  ngOnInit(): void {
    this.preparePaxesInfo();
    if (this.mandatoryRemarks && !this.remark?.result) {
      this.remarkName = this.mandatoryRemarks?.name;
      this.selectedTemplate = this.mandatoryRemarks?.data;
      this.parseRemarkTemplate();
      this.emitTemplateSelected.emit(false);
      this.updateRemarksData();
    } else if (this.remark && this.remark.data?.variables && this.remark.data.template) {
      this.selectedTemplate = this.remark.data.template;
      let remark = this.templates.find(template => template.data === this.remark.data.template);
      this.remarkName = this.remark.template ? this.remark.data?.name : remark?.name || this.templates[0]?.name;

      this.parseRemarkTemplate();
      this.remarkFormGroup.patchValue(this.remark.data.variables);
    } else if (this.templates.length === 1) {
      this.selectTemplate(this.templates[0].name);
    }
  }

  preparePaxesInfo() {
    this.orderInfo.passengers?.map((pax, idx) => {
      let preparedInfo = {};
      preparedInfo['totalPrice'] = this.getPricePerPassenger(idx);
      preparedInfo['passengerIdx'] = this.padLeadingZeros(idx + 1, 3);
      preparedInfo['passengerInfo'] = 'passenger ' + pax.data.name + ' ' +  pax.data.surname + ' (' + pax.travelerReference + ')';
      preparedInfo['passengerType'] = pax.passengerType;
      preparedInfo['passengerNumber'] = 'P' + (idx + 1);
      preparedInfo['travelerReference'] = pax.travelerReference;
      this.psgInfo.push(preparedInfo);
    });
  }

  getPricePerPassenger(idx: number) {
    const price = this.orderInfo.psgInfo.breakdown
                  ? ((this.orderInfo.psgInfo.breakdown[idx]?.tax?.total || 0) + (this.orderInfo.psgInfo.breakdown[idx]?.base || 0))
                  : 0;
    return price.toFixed(2).toString();
  }

  padLeadingZeros(num, size) {
    let s = num + "";
    while (s.length < size) { s = "0" + s; }
    return s;
  }

  selectTemplate(name) {
    this.selectedTemplate = this.templates.find(template => template.name === name)?.data || this.templates.find(template => template.name === name)?.template;
    this.remarkName = this.templates.find(template => template.name === name)?.name;
    this.parseRemarkTemplate();
    this.emitTemplateSelected.emit(false);
    this.updateRemarksData();
  }

  setValidatorsToForm() {
    this.remarkFormGroup = new FormGroup({});
    let passangerCounter = 0;
    for (let remarkLine of this.parsedRemarks) {
      remarkLine.map((field, index) => {
        if (field.type === REMARK_ELEM_TYPE.INPUT) {
          const validators = [];
          if (field.maxLength && field.typeOfContent !== 'float') {
            validators.push(Validators.maxLength(field.maxLength));
          }
          if (field.minLength && field.maxLength) {
            validators.push(Validators.maxLength(field.maxLength));
            validators.push(Validators.minLength(field.minLength));
          }
          if (field.typeOfContent === 'int') {
            validators.push(Validators.pattern(/^\d+$/));
          }
          if ((field.typeOfContent === 'float' && !field.maxDigitsAfterDot)) {
            validators.push(RemarksValidators.floatType());
          }
          if (field.typeOfContent === 'float' && field.maxDigitsAfterDot) {
            validators.push(RemarksValidators.floatTypeWithNumber(field.maxDigitsAfterDot));
          }
          if (field.isRequired) {
            validators.push(Validators.required);
          }
          if (field.typeOfContent === 'origin') {
            this.remarkFormGroup.addControl(field.placeholder, new FormControl(this.orderInfo.origin, validators));
          } else if (field.typeOfContent === 'destination') {
            this.remarkFormGroup.addControl(field.placeholder, new FormControl(this.orderInfo.destination, validators));
          } else if (field.typeOfContent === 'agent-id') {
            const agent = this.ls.email.split('@')[0];
            this.remarkFormGroup.addControl(field.placeholder, new FormControl(agent, validators));
          } else if (field.typeOfContent === 'agent-custom-id') {
            this.remarkFormGroup.addControl(field.placeholder, new FormControl(this.ls.agentID, validators));
          } else if (field.typeOfContent === 'agency-id') {
            this.remarkFormGroup.addControl(field.placeholder, new FormControl(this.ls.agency, validators));
          } else if (field.typeOfContent === 'order-id') {
            this.remarkFormGroup.addControl(field.placeholder, new FormControl(this.orderInfo.id || '', validators));
          } else if (field.typeOfContent === 'account-id') {
            this.remarkFormGroup.addControl(field.placeholder, new FormControl(this.orderInfo.accountId || '', validators));
          } else if (field.typeOfContent === 'ticketnumber') {
            this.remarkFormGroup.addControl(field.placeholder, new FormControl(this.orderInfo.ticketNumber || '', validators));
          } else if (field.typeOfContent === 'ticketserialnumber') {
            this.remarkFormGroup.addControl(field.placeholder, new FormControl(this.orderInfo.ticketSerialNumber || '', validators));
          } else if (field.typeOfContent === 'airlinecodeticket') {
            this.remarkFormGroup.addControl(field.placeholder, new FormControl(this.orderInfo.airlineCodeTicket || '', validators));
          } else if (field.typeOfContent === 'psg-sales-price' && passangerCounter < this.psgInfo.length) {
            this.remarkFormGroup.addControl(field.placeholder, new FormControl(this.psgInfo[passangerCounter].totalPrice, validators));
          } else if (field.typeOfContent === 'psg-index' && passangerCounter < this.psgInfo.length) {
            this.remarkFormGroup.addControl(field.placeholder, new FormControl(this.psgInfo[passangerCounter].passengerIdx, validators));
            if (index === remarkLine.length - 1) {
              passangerCounter++;
            }
           } else if (field.typeOfContent === 'travelerReference' && passangerCounter < this.psgInfo.length) {
            this.remarkFormGroup.addControl(field.placeholder, new FormControl(this.psgInfo[passangerCounter].travelerReference, validators));
            if (index === remarkLine.length - 1) {
              passangerCounter++;
            }
          } else if (field.typeOfContent === 'number' && passangerCounter < this.psgInfo.length) {
            this.remarkFormGroup.addControl(field.placeholder, new FormControl(this.psgInfo[passangerCounter].passengerNumber, validators));
            if (index === remarkLine.length - 1) {
              passangerCounter++;
            }
          } else {
            this.remarkFormGroup.addControl(field.placeholder, new FormControl('', validators));
          }
        }
      });
    }
  }

  parseRemarkTemplate() {
    const splitRemarksText = this.selectedTemplate.split('\n');
    this.updateRemarksText(splitRemarksText);
    this.removeExtraRemarklines(splitRemarksText);
    let uniqueIdx = 0;
    this.parsedRemarks = splitRemarksText.map((remarkLine, idx) => {
      const parsedRemarkLine: RemarkElement[] = [];
      let remarkLineCopy = remarkLine.trim();
      while (remarkLineCopy.length !== 0) {
        const matchedPhrase = remarkLineCopy.match(/{(.*?)\}/);
        if (!matchedPhrase) {
          const remarkElement: RemarkElement = {
            type: REMARK_ELEM_TYPE.TEXT,
            content: remarkLineCopy,
          };
          parsedRemarkLine.push(remarkElement);
          break;
        }

        const index = matchedPhrase.index;

        if (index !== 0) {
          const remarkElement: RemarkElement = {
            type: REMARK_ELEM_TYPE.TEXT,
            content: remarkLineCopy.substring(0, index),
          };
          parsedRemarkLine.push(remarkElement);
          remarkLineCopy = remarkLineCopy.substring(index);
        } else {
          let typeOfContent = '';
          let contentInfo = '';
          let maxDigitsAfterDot;
          let isRequired;
          let isOptional;
          let minLength;
          let maxLength;
          let list = [];
          let placeholder = matchedPhrase[1];
          const dividedTypeIndex = matchedPhrase[1].indexOf(':');
          if (dividedTypeIndex > 0) {
            if (remarkLine.includes('psg-index') || remarkLine.includes('{passenger')) {
              contentInfo = placeholder.substring(dividedTypeIndex + 1);
              placeholder = placeholder.substring(0, dividedTypeIndex) + '[' + uniqueIdx + ']';
              uniqueIdx++;
            } else {
              contentInfo = placeholder.substring(dividedTypeIndex + 1);
              placeholder = placeholder.substring(0, dividedTypeIndex);
            }
          } else {
            typeOfContent = 'string';
          }

          const lastSymbol = matchedPhrase[1][matchedPhrase[1].length - 1];
          isRequired = lastSymbol === '!';
          isOptional = lastSymbol === '?';

          let listObj = contentInfo.match(/\(([^)]+)\)/);
          if (contentInfo.slice(0, listObj?.index) === 'list') {
            list = listObj[1].split(',');
            typeOfContent = contentInfo.slice(0, listObj.index);
          }

          let digits = contentInfo.match(/\d+/g);
          let index = contentInfo.indexOf('(');
          if (digits) {
            if (digits.length === 2) {
              minLength = +digits[0];
              maxLength = +digits[1];

              typeOfContent = contentInfo.slice(0, index);
            } else {
              typeOfContent = contentInfo.slice(0, index);
              if (typeOfContent === 'float' && digits) {
                maxDigitsAfterDot = +digits[0];
              } else {
                if (typeOfContent !== 'list') {
                  maxLength = +digits[0];
                  typeOfContent = contentInfo.slice(0, index);
                }
              }
            }
          } else if (!digits && !listObj) {
            if (contentInfo.charAt(contentInfo.length - 1) === '!' || contentInfo.charAt(contentInfo.length - 1) === '?') {
              typeOfContent = contentInfo.slice(0, contentInfo.length - 1);
            } else {
              typeOfContent = contentInfo;
            }
          }

          const remarkElement: RemarkElement = {
            type: REMARK_ELEM_TYPE.INPUT,
            content: '',
            typeOfContent: typeOfContent,
            isRequired: isRequired,
            isOptional: isOptional,
            placeholder: placeholder,
            maxLength: maxLength,
            minLength: minLength,
            list: list,
            maxDigitsAfterDot: maxDigitsAfterDot,
          };

          this.inputValues[remarkElement.placeholder] = '';
          parsedRemarkLine.push(remarkElement);
          remarkLineCopy = remarkLineCopy.substring(matchedPhrase[0].length);
        }
      }

      return parsedRemarkLine;
    });

    this.setValidatorsToForm();
  }

  updateRemarksText(splitRemarksText) {
    let allowToAdd = true;
    let iteratePassengers = false;
    splitRemarksText.map((remarkLine, index) => {
      if (remarkLine.includes('{{#passengers}}')) {
        iteratePassengers = true;
      } else if (remarkLine.includes('{{/passengers}}')) {
        iteratePassengers = false;
      } else {
        if (remarkLine.includes('psg-index')) {
          iteratePassengers = true;
        }
        if (iteratePassengers && allowToAdd) {
          const reversedPsgInfo = this.psgInfo.slice().reverse()
          for (let i = 0; i <= this.psgInfo.length - 1; i++) {
            let passengerInfoLine = reversedPsgInfo[i].passengerInfo.toUpperCase();
            splitRemarksText.splice(index, 0, passengerInfoLine);
            if (i < this.psgInfo.length - 1) {
              splitRemarksText.splice(index, 0, remarkLine);
            }
          }
          allowToAdd = false;
        }
      }
    });
  }

  removeExtraRemarklines(splitRemarksText) {
    splitRemarksText.map((remarkLine, index) => {
      if (remarkLine.includes('{{#passengers}}') || remarkLine.includes('{{/passengers}}')) {
        splitRemarksText.splice(index, 1);
      }
    });
  }

  combineText() {
    let remarkText = '';

    const controlValues = this.remarkFormGroup.getRawValue();
    let currentIdx = 0;
    if (this.remarkName === REMARK_NAME.iteratePassengers) {
      for (let remarksLine of this.parsedRemarks) {
        for (let remark of remarksLine) {
          if (remark.type === REMARK_ELEM_TYPE.TEXT) {
            if (remark.content === 'RM\\*ACESAL-') {
              remarkText = remarkText + '[PASSENGER-' + this.psgInfo[currentIdx].travelerReference +  ']' + remark.content;
            } else if (!remark.content.toLowerCase().startsWith('passenger')) {
              remarkText = remarkText + remark.content;
            }
          } else {
            const value = controlValues[remark.placeholder];
            if (value === this.psgInfo[currentIdx]?.travelerReference) {
              remarkText = remarkText + this.psgInfo[currentIdx].travelerReference + '[/PASSENGER-' + this.psgInfo[currentIdx].travelerReference +  ']';
              currentIdx++;
            } else if (remarkText.lastIndexOf("\n") > 0 && remark.isOptional && !value) {
              remarkText = remarkText.substring(0, remarkText.lastIndexOf("\n"));
            } else {
              remarkText = remarkText + value;
            }
          }
        }
        remarkText = remarkText + `\n`;
      }
    } else {
      for (let remarksLine of this.parsedRemarks) {
        for (let remark of remarksLine) {
          if (remark.type === REMARK_ELEM_TYPE.TEXT) {
            if (!remark.content.toLowerCase().startsWith('passenger')) {
              remarkText = remarkText + remark.content;
            }
          } else {
            const value = controlValues[remark.placeholder];
            if (remarkText.lastIndexOf("\n") > 0 && remark.isOptional && !value) {
              remarkText = remarkText.substring(0, remarkText.lastIndexOf("\n"));
            } else {
              remarkText = remarkText + value;
            }
          }
        }
        remarkText = remarkText + `\n`;
      }
    }

    remarkText = remarkText.replace(/[\n]{2,}/g, "\n");
    return remarkText;
  }

  prepareDataToUpdate() {
    const dataToUpdate: RemarkDataToUpdate = {
      data: {
        name: this.remarkName,
        template: this.selectedTemplate,
        variables: this.remarkFormGroup.getRawValue()
      },
      id: this.orderInfo.id,
      result: this.combineText(),
    };
    this.remarkFormGroup.markAllAsTouched();

    return !this.remarkFormGroup.invalid && dataToUpdate.result ? dataToUpdate : null;
  }

  prepareDataToReset() {
    const dataToUpdate: RemarkDataToUpdate = {
      data: {
        name: this.mandatoryRemarks ? this.mandatoryRemarks?.name : '',
        template: this.selectedTemplate,
        variables: { }
      },
      id: this.orderInfo.id,
      result: "",
    };

    return dataToUpdate;
  }

  enableForm(event?) {
    if (this.remarkFormGroup.disabled) {
      this.remarkFormGroup.enable();
      Object.keys(this.remarkFormGroup.controls).forEach(key => {
        if (key.startsWith('psg-index') || key.startsWith(('psg-sales-price')) || key.startsWith(('passenger'))) {
          this.fieldToDisable.push(key);
        }
      });

      this.fieldToDisable.forEach(field => {
        if (this.remarkFormGroup.get(field)) {
          this.remarkFormGroup.get(field).disable();
        }
      });
    }
    if (event) {
      event.target.focus();
      this.isButtonVisible.edit = false;
      this.isButtonVisible.cancel = true;
      this.isButtonVisible.save = true;
    }
  }

  onKeyDown(event: KeyboardEvent, type: string) {
    if (type !== 'int' && type !== 'float') {
      return;
    }

    const forbiddenCharacters = type === 'int' ?  ['e', '+', '-', '.'] : ['e', '+', '-'];
    if (forbiddenCharacters.includes(event.key)) {
      event.preventDefault();
    }
  }

  onSelectChange(value: string, path: string) {
    if (!value) {
      this.remarkFormGroup.get(path).setValue('');
    }
  }

  selectOptionFromList(option, placeholder) {
    this.remarkFormGroup.patchValue({[placeholder]: option});
  }

  updateRemarksData() {
    const dataToUpdate = this.prepareDataToUpdate();
    if (dataToUpdate) {
      this.emitUpdatedRemark.emit(dataToUpdate);
      this.remarkFormGroup.disable();
    }
  }

  get isEditAllowed() {
    return this.orderInfo.status === 'Pending' && this.isButtonVisible.edit;
  }

  get isSelectTemplateDisabled() {
    return this.orderInfo.status !== 'Pending' || (this.templates.length === 1 && this.selectedTemplate);
  }

  get showTextArea() {
    return (this.isButtonVisible.edit || this.orderInfo.status !== 'Pending') && this.remark?.result;
  }

  get rows() {
    return this.remark.result.split('\n').length;
  }
}


