import { Pipe, PipeTransform } from '@angular/core';
import {AIRCRAFT_CODES} from "../constants";

@Pipe({
  name: 'getAircraftIcon'
})
export class GetAircraftIconPipe implements PipeTransform {
  aircraftCodes = AIRCRAFT_CODES;

  transform(aircraftCode: string): string {

    if (aircraftCode === this.aircraftCodes.BUS) {
      return 'directions_bus_filled';
    } else if (this.aircraftCodes.TRAIN.includes(aircraftCode)) {
      return 'train';
    } else {
      return 'flight';
    }
  }

}
