import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {LocalStorageService} from "../services/local-storage.service";
import {ROLES} from "../constants";

@Injectable({
  providedIn: 'root'
})
export class PresetsGuard implements CanActivate {
  constructor(private ls: LocalStorageService,
              private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.ls.role === ROLES.MANAGER || this.ls.role === ROLES.SUBAGENCY_MANAGER) {
      return true;
    } else {
      this.router.navigateByUrl('/search');
      return false;
    }
  }

}
