import {Pipe, PipeTransform} from "@angular/core";

@Pipe({ name: 'excludePassengersPipe' })
export class ExcludePassengersPipe implements PipeTransform {
  transform(arr: any[], isEqual: boolean = false, passengerType: string = 'INF') {
    if (isEqual) {
      return arr.filter(f => f.passengerType === passengerType);
    } else {
      return arr.filter(f => f.passengerType !== passengerType);
    }
  }
}
