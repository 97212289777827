import { Pipe, PipeTransform } from '@angular/core';
import {KeyValuePair} from "../interfaces/key-value-pair";

@Pipe({
  name: 'groupByKey'
})
export class GroupByKeyPipe implements PipeTransform {

  transform<T>(value: T[], key: string): KeyValuePair<T[]> {
    const keyValue: KeyValuePair<T[]> = {};
    if (typeof key === 'string' && value?.length > 0) {
      for (let elem of value) {
        if (elem[key] in keyValue) {
          keyValue[elem[key]] = [...keyValue[elem[key]], elem];
        } else {
          keyValue[elem[key]] = [elem];
        }
      }
    }

    return keyValue;
  }

}
