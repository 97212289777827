import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class CorporateDiscountCodesService {

  private corporateCodes: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public corporateCodes$ = this.corporateCodes.asObservable();

  private selectedCorporate: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public selectedCorporate$ = this.selectedCorporate.asObservable();

  constructor() {
  }

  set setCorporateCodes(value: any) {
    this.corporateCodes.next(value);
  }

  set setSelectedCorporate(value: any) {
    this.selectedCorporate.next(value);
  }
}
