import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {Notification, NotificationType} from "../models/notification";
import {ErrorTypes} from "../types/dictionary";
import {HelpersService} from "./helpers.service";


@Injectable()
export class NotificationService {

  private _subject = new Subject<Notification>();
  private _idx = 0;
  private  prevMessage = '';

  constructor(private helpers: HelpersService) { }

  getObservable(): Observable<Notification> {
    return this._subject.asObservable();
  }

  info(title: string, message: string, timeout = 3000) {
    this._subject.next(new Notification(this._idx++, NotificationType.info, title, message, timeout));
  }

  clearNotifications() {
    this._subject.next(null);
  }

  success(title: string, message: string, timeout = 3000) {
    this._subject.next(new Notification(this._idx++, NotificationType.success, title, message, timeout));
  }

  warning(type, message = '', title = '', timeout = 5000) {
    let msg = message;
    if (!message) {
      switch (type) {
        default:
          msg = 'Unexpected warning';
          break;
      }
    }
    this._subject.next(new Notification(this._idx++, NotificationType.warning, title, message, timeout));
  }

  error(type, message: string = '', code = 0,  timeout = 8000, title = '') {
    const errorTitle = this.formatTitle(title);

    this._subject.next(new Notification(this._idx++, NotificationType.error, errorTitle, message, timeout));
  }

  providerError(type, message: string = '', code = 0, timeout = 8000, title = '') {
    const errorTitle = this.formatTitle(title) || 'Provider Error';

    this._subject.next(new Notification(this._idx++, NotificationType.providerError, errorTitle, message, timeout));
  }

  private formatTitle(title: string): string {
    if (title) {
      const splittedTitle = title.split('_');
      return this.helpers.capitalizeWords(splittedTitle)?.join(' ');
    }
    return '';
  }
}
