import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal, NgbTabChangeEvent, NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import {HubCorporateService} from '../../shared/services/hub-corporate.service';
import {HelpersService} from '../../shared/services/helpers.service';
import {Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {NotificationService} from '../../shared/services/notification.service';
import {Corporate} from '../../shared/types/corporate';
import { RESPONSE_OK} from '../../shared/types/system';
import {HubProfileService} from '../../shared/services/hub-profile.service';
import {Discount, EditDiscount} from '../../shared/types/discount-code';
import {Profile} from '../../shared/types/profile';

type Tabs = 'corporate' | 'programs' | 'managers' | 'travelers';


@Component({
  selector: 'app-corporate-detail',
  templateUrl: './corporate-detail.component.html',
  styleUrls: ['./corporate-detail.component.scss']
})
export class CorporateDetailComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  corporate: Corporate = new Corporate();
  form: FormGroup;
  managers: Profile[] = [];
  travelers: Profile[] = [];

  discounts: Discount[] = [];

  notifications = {
    addDiscount: 'Discount has been successfully added',
    editDiscount: 'Discount has been successfully edited',
    removeDiscount: 'Discount has been successfully removed',
  };
  notificationMessage = '';

  showAddedEditedLoaderInDiscountForm = false;
  showRemovedLoaderInDiscountForm = false;

  domainPattern = /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/;

  selectedTab = 'corporate';
  breadcrumbs = [];
  isCorporateLoaded = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private svc: HubCorporateService,
              private travelerService: HubProfileService,
              private _notificationSvc: NotificationService,
              public helpers: HelpersService,
              private fb: FormBuilder,
              public modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.corporate = new Corporate();
    this.buildForm();
    this.getCorporate();
  }

  setBreadcrumbsLinks() {
    this.breadcrumbs = [
      {
        label: 'Corporates',
        url: '/corporates',
      },
      {
        label: this.corporate.name,
      },
    ];
  }

  getCorporate() {
    const id = this.route.snapshot.paramMap.get('id');
    this.svc.get(id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res: any) => {
        this.corporate = res.body;
        this.isCorporateLoaded = true;
        this.discounts = [];
        for (let i in this.corporate.discount_codes) {
          this.discounts.push({provider: i, discountCode: this.corporate.discount_codes[i]});
        }

        for (let i in this.corporate.loyalty_program_discount_codes) {
          const idx = this.discounts.findIndex(t => t.provider === i);
          if (idx !== -1) {
            this.discounts[idx].loyaltyProgramNumber = this.corporate.loyalty_program_discount_codes[i];
          } else {
            this.discounts.push({provider: i, loyaltyProgramNumber: this.corporate.loyalty_program_discount_codes[i]});
          }
        }

        const values = {
          name: this.corporate.name,
          account_number: this.corporate.account_number
        };

        this.form.patchValue(values);

        this.travelerService.getAll(id)
          .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe((res: any) => {
            if (res.body) {
              this.managers = res.body.filter(t => t.role === 'manager');
              this.travelers = res.body.filter(t => t.role === 'traveler');
            }
          }, err => console.log(err));

        let tab = this.route.snapshot.queryParamMap.get('tab') as Tabs;
        if (tab) {
          this.selectedTab = tab;
        }
        this.svc.corporate = this.corporate;
        this.setBreadcrumbsLinks();

      }, (err) => {
        if (err.status === 404) {
          this.helpers.go('/corporates');
        }
      });
  }

  private buildForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      account_number: ['', Validators.required],
      domains: this.fb.array([this.buildDomain()])
    });
  }

  save() {
    if (!this.validate()) {
      return;
    }

    this.populateModel();
    this.updateCorporate();
  }

  updateCorporate() {
    this.svc.update(this.corporate)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response: any) => {
        let res = response.body;
        this.hideLoadersInForm();
        if (res.status === RESPONSE_OK) {
          this.successSave();
        }
      });
  }

  addDiscount(event: Discount) {
    this.showAddedEditedLoaderInDiscountForm = true;
    this.safeAddDiscount(event, this.discounts);
    this.notificationMessage = this.notifications.addDiscount;
    this.save();
  }

  safeAddDiscount(discount: Discount, discounts: Discount[]) {
    let index = discounts.findIndex(disc => disc.provider === discount.provider);
    if (index === -1) {
      discounts.push(discount);
    } else {
      discounts[index] = discount;
    }
  }

  removeDiscount(idx: number) {
    this.showRemovedLoaderInDiscountForm = true;
    this.discounts.splice(idx, 1);
    this.notificationMessage = this.notifications.removeDiscount;
    this.save();
  }

  editDiscount(event: EditDiscount) {
    this.showAddedEditedLoaderInDiscountForm = true;
    this.discounts[event.idx] = event.discount;
    this.notificationMessage = this.notifications.editDiscount;
    this.save();
  }

  populateModel() {
    const data = this.form.value;
    const applyObject = {
      name: data.name,
      account_number: data.account_number,
      active: data.active,
      discount_codes: this.getDiscounts(this.discounts),
      loyalty_program_discount_codes: this.getDiscounts(this.discounts, false),
      domains: this.corporate.domains
    };

    this.corporate = Object.assign(this.corporate, applyObject);
  }

  getDiscounts(discounts: Discount[], isDefault = true) {
    let discountsObj = {};
    discounts.map(dc => {
      if (isDefault) {
        discountsObj[dc.provider] = dc.discountCode;
      } else {
        discountsObj[dc.provider] = dc.loyaltyProgramNumber;
      }
    });

    return discountsObj;
  }

  successSave() {
    this._notificationSvc.clearNotifications();
    this._notificationSvc.success('SUCCESS', this.notificationMessage, 0);
  }

  tabChanged($event: NgbTabChangeEvent) {
    let params = {...this.route.snapshot.queryParams};
    params.tab = $event.nextId;
    this.router.navigate([], {queryParams: params});
  }

  private validate(): boolean {
    return this.form.valid;
  }

  private hideLoadersInForm() {
    this.showAddedEditedLoaderInDiscountForm = false;
    this.showRemovedLoaderInDiscountForm = false;
  }

  buildDomain() {
    return this.fb.group({
      domain: ['', Validators.pattern(this.domainPattern)]
    });
  }

  selectTab(tab: string) {
    let params: any = {};
    if (tab !== 'corporate') {
      params.tab = tab;
    }

    this.router.navigate([], {queryParams: params});

    this.selectedTab = tab;
  }

  onSaveCorporate() {
    this.getCorporate();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
