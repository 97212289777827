import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[mask]'
})
export class MaskInputDirective {

  @Input('mask') set format(value: string) {
    this._format = value;
  }

  private _format = '';
  writeValue = '';
  separateReg = new RegExp('-|\\/|\\(/', 'g');
  numberReg = new RegExp('[0-9]', 'i');

  constructor(private elementRef: ElementRef) { }

  element = this.elementRef.nativeElement;
  prevDeleteCaretPos: number;
  clearValue = '';

  @HostListener('input')
  onInput() {
    this.element.value = this.writeValue;
    if (this.prevDeleteCaretPos !== undefined) {
      this.element.setSelectionRange(this.prevDeleteCaretPos - 1, this.prevDeleteCaretPos - 1);
    }
  }

  @HostListener('keydown', ['$event'])
  onPressKey(event) {
    const caretPosition = event.target.selectionEnd;

    switch (event.code) {
      case 'Backspace':
        this.prevDeleteCaretPos = caretPosition;
        this.clearValue = this.getClearValue();
        break;
      case 'Delete':
        this.prevDeleteCaretPos = caretPosition + 1;
        this.clearValue = this.getClearValue();
        break;
      default:
        this.addCharToValue(event.key, caretPosition);
        break;
    }

    this.handleWriteValue();
  }

  handleWriteValue() {
    let tempValue = this.clearValue;
    for (let i = 0; i < this._format.length; i++) {
      if (i >= tempValue.length) { break; }

      if (this._format[i] !== '0' && tempValue[i] !== this._format[i]) {
        tempValue = tempValue.substring(0, i) + this._format[i] + tempValue.substring(i);
      }
    }

    this.writeValue = tempValue;
  }

  addCharToValue(key, caretPosition) {
    if ((this.numberReg.test(key) || key === '-') && this.writeValue.length < this._format.length) {
      if (this.isLastPosCaret(caretPosition)) {
        if (!(key === '-' && caretPosition !== 4 && caretPosition !== 7)) {
          this.clearValue = this.clearValue + key;
          this.prevDeleteCaretPos = undefined;
        }
      } else {
        this.clearValue = (this.writeValue.substring(0, caretPosition) + key + this.writeValue.substring(caretPosition))
          .split(this.separateReg).join('');
        this.prevDeleteCaretPos++;
      }
    }
  }

  isLastPosCaret(selectionEnd: number): boolean {
    return this.writeValue.length === selectionEnd;
  }

  getClearValue(insert = ''): string {
    return (this.writeValue.substring(0, this.prevDeleteCaretPos - 1)  + insert
      + this.writeValue.substring(this.prevDeleteCaretPos)).split(this.separateReg).join('');
  }
}
