import {Component, Input, OnInit} from '@angular/core';
import {collapseAnimation, rotateAnimation} from "angular-animations";

@Component({
  selector: 'app-fare-rules',
  templateUrl: './fare-rules.component.html',
  styleUrls: ['./fare-rules.component.scss'],
  animations: [
    rotateAnimation({duration: 500 }),
    collapseAnimation()
  ]
})
export class FareRulesComponent implements OnInit {

  @Input() fareRules: any;
  @Input() offer: any;
  @Input() groupByPassengerType = false;
  @Input() isPDF = false;
  @Input() useNewDesign: boolean;

  showAllFareRules: boolean;
  isFareRulesOpened = true;
  activeODIndex = 0;
  activeTabIndex = 0;

  constructor() { }

  ngOnInit(): void {
  }

  toggleFareRules() {
    this.showAllFareRules = !this.showAllFareRules;
  }

}
