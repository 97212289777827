import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTicketNumber'
})
export class GetTicketNumberPipe implements PipeTransform {

  transform(value: string): string {
    if (value.includes('-')) {
      return value.replace(/-/, ' ');
    } else {
      return value.replace(/^(.{3})(.*)$/, "$1 $2");
    }
  }

}
