import { Component, Input, OnInit } from '@angular/core';
import {FareTypesCategory, PrepareFareTypeInfo} from "../../interfaces/fare-types";

@Component({
  selector: 'app-fare-type',
  templateUrl: './fare-type.component.html',
  styleUrls: ['./fare-type.component.scss']
})
export class FareTypeComponent implements OnInit {

  @Input() mainFareType: PrepareFareTypeInfo;
  @Input() fareTypeCategories: FareTypesCategory[];

  constructor() { }

  ngOnInit(): void {
  }

}
