import { Component, Input, OnInit } from '@angular/core';
import {LocalStorageService} from '../shared/services/local-storage.service';
import { VersionService } from '../shared/services/version.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() frontendSelectedDependency;
  currentVersion: string;

  constructor(
    public ls: LocalStorageService,
    public versionService: VersionService) {}

  ngOnInit() {
    this.currentVersion = this.versionService.version;
  }
}
