import {Injectable} from "@angular/core";
import {AmadeusOrderSegment} from "../models/amadeus-order-segment.model";
import {DashboardHelpersService} from "./dashboard-helpers.service";
import {Subject} from "rxjs";
import {FinalAmadeusParsedData} from "../models/final-amadeus-parsed-data.model";
import {AmadeusParserToInput} from "../models/amadeus-parser-to-input.model";
import moment from 'moment';

@Injectable()
export class OrderFromAmadeusParserService {

  constructor(
    private dashboardHelpersService: DashboardHelpersService,
  ) {}

  amadeusOrderParsedData$ = new Subject();
  private arrayOfRows = []
  private inputValue;
  private rowTemplate1 = /[0-9] [a-zA-Z]{2} [0-9]{3} [a-zA-Z] [0-9]{2}[a-zA-Z]{3}/gi;
  private rowTemplate2 = /[0-9] [a-zA-Z]{2}[0-9]{4} [a-zA-Z] [0-9]{2}[a-zA-Z]{3}/gi;
  private splitChar = '#@#';
  private parsedSegments: AmadeusOrderSegment[] = [];
  private errorMessage = '';

  private defaultSegment = {
    segmentNumber: 0,
    marketingCarrier: '',
    marketingFlightNumber: '',
    bookingClass: '',
    segmentDepartureDate: {},
    weekDayDepartureDate: 0,
    departureAirPort: '',
    arrivalAirPort: '',
    segmentStatusCode: '',
    numberOfSeatsHeld: 0,
    segmentDepartureTime: '',
    segmentArrivalTime: '',
    segmentArrivalDate: {},
    electronicTicketingIndicator: '',
    airlineRecordLocator: '',
  }

  public prepareArrayOfSegments(text: string) {
    this.parsedSegments = [];
    this.errorMessage = '';

     this.inputValue = text.replace(/\s\s+/g, ' ')

     const arrOfMatches = [...(this.inputValue.match(this.rowTemplate1) || []), ...(this.inputValue.match(this.rowTemplate2) || [])]
     if (arrOfMatches.length) {

       arrOfMatches.map(item => {
         const index = this.inputValue.indexOf(item);
         if (index > -1) {
           this.inputValue = `${this.inputValue.substr(0, index)}${this.splitChar}${this.inputValue.substr(index)}`;
         }
       })

       this.arrayOfRows = this.inputValue
         .split(this.splitChar)
         .filter(item => item.match(this.rowTemplate1) || item.match(this.rowTemplate2));

     } else {
       this.setError('');
       return;
     }

     if (this.arrayOfRows.length) {
       this.startParse();
     } else {
       this.setError('');
     }
  }

  private startParse() {

    this.arrayOfRows.forEach( (row) => {
      const parsed = {...this.defaultSegment};

      let currentIndex = 0

      const segmentNumber = +row.slice(currentIndex, 2).trim();
      if (typeof segmentNumber  === 'number') {
        parsed.segmentNumber = segmentNumber;
        currentIndex += 2;
      }

      const marketingCarrier = row.slice(currentIndex, currentIndex + 2)
      if (marketingCarrier.length === 2 && marketingCarrier.match(/[a-zA-Z]{2}/gi)) {
        parsed.marketingCarrier = marketingCarrier
        currentIndex += 2;
      }

      const marketingFlightNumber = row.slice(currentIndex, currentIndex + 4).trim();
      if ((marketingFlightNumber.length === 3 || marketingFlightNumber.length === 4) && marketingFlightNumber.match(/[0-9]{3}/gi) || marketingFlightNumber.match(/[0-9]{3}/gi)) {
        if (marketingFlightNumber.length === 3) {
          parsed.marketingFlightNumber = `0${marketingFlightNumber}`;
        }  else  {
          parsed.marketingFlightNumber = marketingFlightNumber
        }
        currentIndex += 4;
      }

      const bookingClass = row.slice(currentIndex, currentIndex + 2).trim();
      if (bookingClass.length === 1 && bookingClass.match(/[a-zA-Z]/gi)) {
        parsed.bookingClass = bookingClass;
        currentIndex += 2;
      }

      const segmentDepartureDate = row.slice(currentIndex, currentIndex + 7).trim();
      if (segmentDepartureDate.length === 5 && segmentDepartureDate.match(/[0-9]{2}[a-zA-Z]{3}/gi)) {
        const temDate = this.dashboardHelpersService.convertDate(segmentDepartureDate);
        if (!!temDate) {
          parsed.segmentDepartureDate = temDate;
          currentIndex += 7;
        } else {console.log('error')}
      } else {console.log('error')}

      const weekDayDepartureDate = +row.slice(currentIndex, currentIndex + 2).replace('*', '').trim();
      if (typeof weekDayDepartureDate  === 'number' && weekDayDepartureDate > 0 && weekDayDepartureDate < 8) {
        parsed.weekDayDepartureDate = weekDayDepartureDate;
        currentIndex += 2;
      }

      const od = row.slice(currentIndex, currentIndex + 6);
      if (od.length === 6 && od.match(/[a-zA-Z]{6}/gi)) {
        parsed.departureAirPort = od.slice(0, 3);
        parsed.arrivalAirPort = od.slice(3, 6);
        currentIndex += 6;
      }

      const statusCodeAndSeatsHeld = row.slice(currentIndex, currentIndex + 5).trim();
      if (statusCodeAndSeatsHeld.length === 3 && statusCodeAndSeatsHeld.match(/[a-zA-Z]{2}[0-9]/gi)) {
        parsed.segmentStatusCode = statusCodeAndSeatsHeld.slice(0, 2);
        parsed.numberOfSeatsHeld = statusCodeAndSeatsHeld.slice(2, 3);
        currentIndex += 5;
      }

      const depArrivalTime = row.slice(currentIndex, currentIndex + 9).trim().replace(' ', '');
      if (depArrivalTime.length === 8 && depArrivalTime.match(/[0-9]{8}/gi)) {
        const tempDep = depArrivalTime.slice(0, 4)
        parsed.segmentDepartureTime = `${tempDep.slice(0, 2)}:${tempDep.slice(2, 4)}`;
        const tempArr = depArrivalTime.slice(4, 8)
        parsed.segmentArrivalTime = `${tempArr.slice(0, 2)}:${tempArr.slice(2, 4)}`;
        currentIndex += 9;
      }

      const segmentArrivalDate = row.slice(currentIndex, currentIndex + 7).trim();
      if (segmentArrivalDate.length === 5 && segmentArrivalDate.match(/[0-9]{2}[a-zA-Z]{3}/gi)) {
        const temDate = this.dashboardHelpersService.convertDate(segmentArrivalDate);
        if (!!temDate) {
          parsed.segmentArrivalDate = temDate;
          currentIndex += 7;
        } else {console.log('error')}
      } else {console.log('error')}

      const electronicTicketingIndicator = row.slice(currentIndex, currentIndex + 2).trim();
      if (electronicTicketingIndicator) {
        parsed.electronicTicketingIndicator = electronicTicketingIndicator;
        currentIndex += 2;
      }

      const airlineRecordLocator = row.slice(currentIndex, currentIndex + 50).trim().split(' ');
      if (airlineRecordLocator[0]) {
        parsed.airlineRecordLocator = airlineRecordLocator[0];
      }

      this.parsedSegments.push(parsed);
    })

    this.createSearchModel();
  }

  private createSearchModel() {

    let model: FinalAmadeusParsedData = null;

    if (this.parsedSegments.length) {

      model = this.tryOneWayBuildModel();

      if(!model) {
        model = this.tryRoundTripBuildModel();
      }

      if (!!model) {
        this.sendModelToSearch(model);
      } else this.setError();

    } else this.setError();

  }

  private sendModelToSearch(searchModel: FinalAmadeusParsedData) {
    const data: AmadeusParserToInput = {
      errorMsg: this.errorMessage,
      data: this.errorMessage ? null : searchModel,
    }
    this.amadeusOrderParsedData$.next(data)
  }

  private setError(msg: string = ''): void {
    this.errorMessage = !!msg ? msg : `Incorrect command for filter.`;
    this.sendModelToSearch(null);
  }

  private tryOneWayBuildModel(){
    let model = new FinalAmadeusParsedData();

    /** build OW without connections **/
    if(this.parsedSegments.length === 1) {
      const od = {
        departureDate: this.parsedSegments[0].segmentDepartureDate,
        departure: this.parsedSegments[0].departureAirPort,
        arrival: this.parsedSegments[0].arrivalAirPort
      }
      model.originDestinations.push(od);
      model.flightNumbers.push(`${this.parsedSegments[0].marketingCarrier}${this.parsedSegments[0].marketingFlightNumber}`);
    }


    return model.originDestinations.length ? model : null;
  }

  private tryRoundTripBuildModel() {
    let model = new FinalAmadeusParsedData();

    /** check RT without connections **/
    model = this.checkRTWithOUTConnections();

    /** check RT with connections **/
    if (!model.originDestinations.length) {
      model = this.checkRTWitConnections();
    }

    if (!model.flightNumbers.length) {
      model = this.buildDefaultModel();
    }

    return model.flightNumbers.length ? model : null;
  }

  private checkDateRangeBetweenSegmentsMoreOneDay(arrayOfSegments: AmadeusOrderSegment[]) {
    const result = [];
    if (arrayOfSegments.length >=2) {
      arrayOfSegments.map((segment, index) => {
        if (arrayOfSegments[index + 1] !== undefined) {
          const dep_date_1 = arrayOfSegments[index].segmentDepartureDate;
          const firstSegmentTime =  moment(moment(`${dep_date_1.year}-${dep_date_1.month}-${dep_date_1.day}`,'YYYY-MM-DD')).unix();
          const dep_date_2 = arrayOfSegments[index + 1].segmentDepartureDate;
          const secondSegmentTime =  moment(moment(`${dep_date_2.year}-${dep_date_2.month}-${dep_date_2.day}`,'YYYY-MM-DD')).unix();
          if((secondSegmentTime - firstSegmentTime) > 86500) {
            result.push(`${index}-${index + 1}`)
          }
        }
      })
    }
    return result;
  }

  private checkRTWithOUTConnections() {
    let model = new FinalAmadeusParsedData();
    const isRTSimple =  this.parsedSegments.length === 2
      && this.parsedSegments[0].departureAirPort === this.parsedSegments[1].arrivalAirPort
      && this.parsedSegments[0].arrivalAirPort === this.parsedSegments[1].departureAirPort;

    if (isRTSimple) {
      this.parsedSegments.map((item, index) => {
        const tempOD = {
          departureDate: item.segmentDepartureDate,
          departure: item.departureAirPort,
          arrival: item.arrivalAirPort
        }
        model.originDestinations[index] = tempOD;
        model.flightNumbers.push(`${item.marketingCarrier}${item.marketingFlightNumber}`);
      })
    }
    return model;
  }

  private buildDefaultModel() {
    let model = new FinalAmadeusParsedData();
    const flightNumArr = [];

    if (this.parsedSegments.length) {
      this.parsedSegments.map(item => {
        const num = `${item.marketingCarrier}${item.marketingFlightNumber}`;
        if (!flightNumArr.includes(num)) flightNumArr.push(num);
      })
      model.flightNumbers = [...flightNumArr];
    }

    return model;
  }

  private checkRTWitConnections() {
    let model = new FinalAmadeusParsedData();
    const moreOneDayRanges = this.checkDateRangeBetweenSegmentsMoreOneDay(this.parsedSegments);
    if (moreOneDayRanges.length > 1) return model;

    if (moreOneDayRanges.length === 1) {
      const indexes = moreOneDayRanges[0].split('-');
      const segmentsForLeg = {};
      segmentsForLeg[0] = this.parsedSegments.slice(0, indexes[1]);
      segmentsForLeg[1] = this.parsedSegments.slice(indexes[1], this.parsedSegments.length);

      if (segmentsForLeg[0][0].departureAirPort === segmentsForLeg[1][segmentsForLeg[1].length - 1].arrivalAirPort && segmentsForLeg[0][segmentsForLeg[0].length - 1].arrivalAirPort === segmentsForLeg[1][0].departureAirPort) {
        const flightNumbers = [];
        Object.keys(segmentsForLeg).map(leg => {
          const connections = [];
          const tempOD = {
            departureDate: segmentsForLeg[leg][0].segmentDepartureDate,
            departure: segmentsForLeg[leg][0].departureAirPort,
            arrival: segmentsForLeg[leg][segmentsForLeg[leg].length - 1].arrivalAirPort
          }
          segmentsForLeg[leg].map(seg => {
            if (seg.arrivalAirPort !== tempOD.arrival && seg.arrivalAirPort !== tempOD.departure && !connections.includes(seg.arrivalAirPort)) {
              connections.push(seg.arrivalAirPort);
            }
            if (seg.departureAirPort !== tempOD.arrival && seg.departureAirPort !== tempOD.departure && !connections.includes(seg.departureAirPort)) {
              connections.push(seg.departureAirPort);
            }
            const tempNumber = `${seg.marketingCarrier}${seg.marketingFlightNumber}`
            if (!flightNumbers.includes(tempNumber)) flightNumbers.push(tempNumber);
          })
          model.originDestinations[leg] = tempOD;
          model.flightNumbers = flightNumbers;
          model[`connections_white_list_${leg}`] = connections;
        })
      }
    }

    return model;
  }

}
