import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input, OnDestroy,
  Renderer2,
  Self,
} from '@angular/core';
import {NgControl} from '@angular/forms';
import {Subscription} from "rxjs";
import {HelpersService} from "../services/helpers.service";
import {MinMaxDate} from "../interfaces/date";

const controlStatusHost = {};

@Directive({selector: '[formControlName],[ngModel],[formControl]'})
export class ValidateDirective implements OnDestroy {
  @Input() validate;
  @Input() tooltipErrorText: string;
  @Input() placement = 'bottom';
  @Input() delay = 100;
  @Input() maxCharsAllowed = 0;
  @Input() isShowTooltip = true;
  @Input() travelerType = 'ADT';
  @Input() minMaxDateOfBirth: MinMaxDate;
  @Input() minLength: number;
  @Input() maxLength: number;
  @Input() onFocusListener = false;
  private tooltip: HTMLElement;
  private errorText: string;


  readonly controlNamesMap = {
    'surchargeType': 'Surcharge Type',
    'cardNumber': 'Card Number',
    'seriesCode': 'CVC',
    'expiration_month': 'Month',
    'expiration_year': 'Year',
    'cardHolderTitle': 'Title',
    'cardHolderName': 'Name',
    'cardHolderSurname': 'Surname',
    'cardHolderEmail': 'Email',
    'number': 'Number',
    'countryCode': 'Country',
    'cityName': 'City',
    'postalCode': 'Postal Code',
    'stateProv': 'State',
    'street1': 'Street',
    'name': 'Name',
    'surname': 'Surname',
    'document_type': 'Document Type',
    'document_id': 'Document ID',
    'documentType': 'Document Type',
    'documentID': 'Document ID',
    'birthdate': 'Birthdate',
  };

  statusChangeSubscription: Subscription;

  public constructor(@Self() private control: NgControl,
                     private el: ElementRef,
                     private renderer: Renderer2) {
  }

  @HostBinding('class.is-valid')
  get ngClassValid(): boolean {
    if (!this.validate) {
      return false;
    }

    if (this.control.control == null) {
      return false;
    }

    return this.control.valid;
  }

  @HostBinding('class.is-invalid')
  get ngClassInvalid(): boolean {
    if (!this.validate) {
      return false;
    }
    if (this.control.control == null) {
      return false;
    }
    return this.control.invalid;
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.onFocusListener) {
      this.initializeTooltipHandling();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (!this.onFocusListener) {
      this.hideTooltip();
      this.unsubscribeStatusChange();
    }
  }

  @HostListener('focus', ['$event']) onFocus(event: FocusEvent) {
    if (this.onFocusListener) {
      this.initializeTooltipHandling();
    }
  }

  @HostListener('blur', ['$event']) onBlur(event: FocusEvent) {
    this.hideTooltip();
  }

  getErrorText() {
    const { name, errors } = this.control;

    if (errors) {
      if (errors.required) {
        return 'Required field';
      }
      if (errors.pattern) {
        if (['name', 'surname', 'cardHolderName', 'cardHolderSurname', 'cityName'].includes(<string>name)) {
          return `Please enter a valid ${this.controlNamesMap[name]}`;
        } else if (['number', 'cardNumber'].includes(<string>name)) {
          return `Please use only digits`;
        } else if (['email', 'cardHolderEmail'].includes(<string>name)) {
          return `Please enter a valid email address`;
        } else if (name === 'fiscalName') {
          return 'Special characters not allowed for LATAM in fiscal ID';
        }
        return 'Please use a valid format';
      }
      if (errors.floatType || errors.floatTypeWithNumber) {
        return 'Please enter a number with decimals';
      }
      if (errors.minlength) {
        if (this.minLength && this.maxLength) {
          return `Please enter ${this.minLength} to ${this.maxLength} characters`;
        } else {
          return `Minimum ${this.minLength} symbols required`;
        }
      }
      if (errors.maxlength) {
        return `Maximum ${this.maxLength} symbols`;
      }
      if (errors.email) {
        return 'Please enter a valid email address';
      }
      if (errors.wrongNumber) {
        return 'Please enter a valid phone number';
      }
      if (errors.invalidChars) {
        if (['cardNumber'].includes(<string>name)) {
          return `Please enter a valid ${this.controlNamesMap[name]}`;
        } else if (['expiration_month'].includes(<string>name)) {
          return 'Month must greater than or equal to the current month';
        } else if (this.maxCharsAllowed && ['name', 'surname', 'title'].includes(<string>name)) {
          return `It's not allowed to change more then ${this.maxCharsAllowed} characters`;
        }
        return 'Please use a valid chars';
      }
      if (errors.invalidStartOrEndChars) {
        return "It's not allowed to start/end with dash or space";
      }
      if (errors.invalidRut) {
        return 'Invalid RUT';
      }
      if (errors.ngbDate?.hasOwnProperty('invalid') || errors.ngbDate?.minDate || errors.ngbDate?.maxDate || errors.invalidDate) {
        if (['birthdate'].includes(<string>name)) {
          const minDate = HelpersService.getFormattedDate(this.minMaxDateOfBirth?.minDate);
          const maxDate = HelpersService.getFormattedDate(this.minMaxDateOfBirth?.maxDate);
          return `Date of birth must be between ${minDate} and ${maxDate}`;
        } else {
          return 'Invalid date';
        }
      }
      return 'Unknown error';
    }
  }

  private showTooltip() {
    this.createTooltip();
    this.setPosition();
    this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
  }

  private hideTooltip() {
    if (this.tooltip) {
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null;
    }
  }

  private createTooltip() {
    this.tooltip = this.renderer.createElement('div');
    this.renderer.appendChild(this.tooltip, this.renderer.createText(this.errorText));
    this.renderer.appendChild(document.body, this.tooltip);
    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);
    // delay
    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);
  }

  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltip.getBoundingClientRect();
    // window scroll top
    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const offset = 9;
    let top, left;

    if (this.placement === 'top') {
      top = hostPos.top - tooltipPos.height - offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'bottom') {
      top = hostPos.bottom + offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - offset;
    }

    if (this.placement === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + offset;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }

  private initializeTooltipHandling() {
    if (this.ngClassInvalid) {
      this.createErrorText();
      this.showTooltip();
    }

    let currentStatus = '';
    let currentErrorText = '';
    this.statusChangeSubscription = this.control.statusChanges?.subscribe(status => {
      if (status === 'INVALID') {
        this.createErrorText();
      }
      if (currentStatus !== status || currentErrorText !== this.errorText) {
        currentStatus = status;
        currentErrorText = this.errorText;
        if (status === 'VALID') {
          this.hideTooltip();
        } else {
          this.hideTooltip();
          this.showTooltip();
        }
      }
    });
  }

  private createErrorText() {
    this.errorText = this.tooltipErrorText || this.getErrorText();
  }

  private unsubscribeStatusChange() {
    if (this.statusChangeSubscription) {
      this.statusChangeSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.unsubscribeStatusChange();
    this.hideTooltip();
  }
}
