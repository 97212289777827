import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'replace'})
export class ReplacePipe implements PipeTransform {
  transform(value: string, searchSub, replaceSub): string {
    let regex = new RegExp(searchSub, 'g');
    if (!value) {
      return '';
    }
    return value.replace(regex, replaceSub);
  }
}
