import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import {KeycloakGuard} from "../shared/guards/keycloak-guard";
import {AgentsComponent} from "./agents/agents.component";
import {AgentListComponent} from "./agent-list/agent-list.component";
import {SubagenciesComponent} from "./subagencies/subagencies.component";
import {ManagementComponent} from "./management.component";
import {SubagencyAgentsComponent} from "./subagencies/subagency-agents/subagency-agents.component";

const routes: Routes = [
  {
    path: 'agents',
    canActivate: [AuthGuard, KeycloakGuard],
    data: {
      route: 'agents'
    },
    component: ManagementComponent,
    children: [
      {
        path: '',
        component: AgentsComponent,
      },
    ]
  },
  {
    path: 'agencies',
    canActivate: [AuthGuard, KeycloakGuard],
    data: {route: 'agencies'},
    component: ManagementComponent,
    children: [
      {
        path: '',
        component: SubagenciesComponent
      },
      {
        path: ':id',
        component: SubagencyAgentsComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManagementRoutingModule { }
