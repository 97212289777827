export interface ShortPassenger {
  passengerType: string;
  travelerReference: string;
}

export interface Address {
  cityName: string;
  countryCode: string;
  postalCode: string;
  street: string;
}

export interface Account {
  number: string;
}

export interface FqtvInfo {
  account: Account;
  airlineID: string;
}

export interface Data {
  address: Address;
  birthdate: string;
  email: string;
  fqtvInfo: FqtvInfo;
  gender: string;
  name: string;
  phone: string;
  surname: string;
  title: string;
}

export interface Document {
  documentID: string;
  documentType: string;
  expirationDate: string;
}

export interface Passenger {
  data: Data;
  document: Document;
  infantReference: string;
  passengerType: string;
  travelerReference: string;
}

export interface PaxBreakdownInfo {
  base: number;
  total: number;
  surcharge: number;
  passengerRefs: string;
  passengerType: string;
}

export function getTravelerTitle(code) {
  switch (code) {
    case 'ADT':
      return 'Adult';
    case 'CHD':
      return 'Child';
    case 'INF':
      return 'Infant';
    case 'YAD':
      return 'Young Adult';
    default:
      return code;
  }
}

