import { Pipe, PipeTransform } from '@angular/core';
import { PairsPipe } from 'ngx-pipes';


@Pipe({name: 'pairsImpure', pure: false})
export class PairsImpurePipe implements PipeTransform {
  constructor(private pipe: PairsPipe) {

  }

  transform(obj: any): any[] {
   return this.pipe.transform(obj);
  }
}
