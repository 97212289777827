import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { HelpersService } from '../../services/helpers.service';
import {rotateAnimation} from "angular-animations";


@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  animations: [
    rotateAnimation({ degrees: -180, duration: 500 }),
  ]
})
export class MultiSelectComponent implements OnInit, OnDestroy {

  isShowList = false;
  @Input() index: number;
  @Input() value = [];
  @Input() isShowFlightNumbers;
  @Input() set data(value: any) {
    this.filteredData = value;
    this.initData = value;
  }

  @Output() valueChanged = new EventEmitter();
  @ViewChild('selectedContainer', { static: true }) selectedContainer;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  elementId;

  filteredData = [];
  initData = [];
  queryValue = '';


  @HostListener('document:click', ['$event']) clickout(event) {
    const allowedClassNames = [
      'multi-select-wp',
      'selected-items-wp',
      'select-arrow',
      'selected-choice-cancel',
      'selected-choice-wp',
      'selected-choice-body',
      'material-icons arrow-icon',
      'list-of-items-wp',
      'select-query',
      'select-field',
      'arrow-icon'
    ];

    const clickedElement = event.target as HTMLElement;

    const isClickInsideComponent = allowedClassNames.some(className =>
      clickedElement.classList.contains(className)
    );

    if (!isClickInsideComponent && !event.altKey) {
      this.isShowList = false;
    }
  }

  /**
   * Default constructor
   * @param helpersService
   */
  constructor(
    private helpersService: HelpersService
  ) {
  }

  /**
   * On component init hook
   */
  ngOnInit() {
    this.elementId = Math.random().toString(36).substr(2, 9);
    this.helpersService.nextActiveMultiSelectId$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res !== this.elementId) {
          this.isShowList = false;
        }
      });
  }

  /**
   * Hooks destroying component
   */
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Responds for toggle value list
   * @param event
   */
  showList(event): void {
    if (!event.altKey) {
      this.isShowList = !this.isShowList;
      this.helpersService.nextActiveMultiSelectId$.next(this.elementId);
    }
  }

  /**
   * Remove item from value box
   * @param item
   * @param event
   */
  removeItem(item, event): void {
    const index = this.value.indexOf(item);
    if (index > -1) {
      this.value.splice(index, 1);
    }
    this.emitOnChange();
    this.showList(event);
    this.isShowList = false;
  }

  /**
   * Add/Remove item from value box
   * @param item
   * @param event
   */
  toggleItem(item, event): void {
    event.stopPropagation();
    const index = this.value.indexOf(item);
    if (index === -1) {
      this.value.push(item);
    } else {
      this.value.splice(index, 1);
    }
    this.emitOnChange();
    this.value = [...this.value];
    if (!event.altKey) {
      this.isShowList = false;
    }

    this.queryValue = '';
  }

  /**
   * Emit value out , if value was changed
   */
  emitOnChange(): void {
    const data = {
      value: this.value
    };
    this.valueChanged.emit(data);
  }

  onChange(queryValue) {
    this.queryValue = queryValue;
    this.filteredData = this.initData.filter(item => item.includes(queryValue.toUpperCase().replace(/\s+/g, '')));
  }
}
