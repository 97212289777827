import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';
import pluralize from 'pluralize';

@Pipe({name: 'dateRangePipe'})
export class DateRangePipe implements PipeTransform {
  unix = moment().unix();
  day = 60 * 60 * 24;
  today = this.unix - (this.unix % this.day);

  transform(obj: any) {
    return {
      floor: obj.min,
      step: 60 * 60 * 24, // 24 hours step
      ceil: obj.max,
      // minRange: 2,
      enforceStep: false,
      enforceRange: false,
      pushRange: true,
      translate: (value: number): string => {
        let sliderUnix = moment.unix(value).unix();
        sliderUnix -= (sliderUnix % this.day);
        const v = (sliderUnix - this.today) / (60 * 60 * 24);

        return sliderUnix === this.today ? 'all' : v + '+ ' + pluralize('day', v);
      }
    };
  }
}
