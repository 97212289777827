import {Component, Input, OnInit} from '@angular/core';
import {collapseAnimation, rotateAnimation} from "angular-animations";

@Component({
  selector: 'app-corporate-info',
  templateUrl: './corporate-info.component.html',
  styleUrls: ['./corporate-info.component.scss'],
  animations: [
    rotateAnimation({duration: 500 }),
    collapseAnimation()
  ]
})
export class CorporateInfoComponent implements OnInit {
  @Input() corporate: any;
  @Input() isPDFExtract: boolean;

  isCorporateOpened = true;

  constructor() { }

  ngOnInit(): void {
  }

}
