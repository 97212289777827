import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PassiveSegmentService {

  order;
  flights = [];
  passiveSegments = [];
  sabreNotAllowedAirlineCodes = ['BA', 'IB', 'VY', 'LH', 'LX', 'OS', 'SN', 'AF', 'AY', 'AV', 'WY', 'SV'];

  constructor() { }

  generate(format: string, anonymizePassiveSegment = false) {
    this.getInfo();
    let segmentDelimiter = ';';

    switch (format) {
      case 'amadeus':
        this.preparePassiveSegment('SS', 'GK', '/', anonymizePassiveSegment);
        break;
      case 'sabre':
        this.preparePassiveSegment('0', 'YK', this.order.pnr.length <= 6 ? '*' : '¥', anonymizePassiveSegment);
        segmentDelimiter = '§';
        break;
      case 'travelport':
        this.prepareTravelportPassiveSegment();
        break;
    }

    return this.passiveSegments.join(segmentDelimiter);
  }

  prepareTravelportPassiveSegment() {
    this.flights.map((flight) => {
      let passiveSegment = '0' + flight.airlineID + flight.flightNumber + flight.classOfService +
        moment(flight.departureDate).format('DDMMM').toUpperCase() + flight.departureAirportCode +
        flight.arrivalAirportCode + 'YK' + this.order.passengers.length;
      this.passiveSegments.push(passiveSegment);
      });
  }

  preparePassiveSegment(airSellEntry: string, ghostSegment: string, delimiter: string, anonymizePassiveSegment = false) {
    this.flights.map((flight) => {
      let passiveSegment = airSellEntry + (anonymizePassiveSegment ? 'YY' : flight.airlineID) +
        flight.flightNumber + flight.classOfService + moment(flight.departureDate).format('DDMMM').toUpperCase() +
        flight.departureAirportCode + flight.arrivalAirportCode + ghostSegment + this.order.passengers.length +
        '/' + flight.departureTime.split(':').join('') + flight.arrivalTime.split(':').join('') +
        (flight.arrivalDate !== flight.departureDate ? '+1' : '') + delimiter + this.order.pnr;
      this.passiveSegments.push(passiveSegment);
    });
  }

  getInfo() {
    this.order?.flights.map(flight => {
      flight.segments.map((leg) => {
        let flightInfo = {};
        flightInfo['airlineID'] = leg.marketingCarrier.airlineID; // 2 characters
        flightInfo['flightNumber'] = leg.marketingCarrier.flightNumber;
        flightInfo['classOfService'] = leg.detail.classOfService.code; // always 1 letter
        flightInfo['departureDate'] = leg.originDestination.departure.date;
        flightInfo['arrivalDate'] = leg.originDestination.arrival.date;
        flightInfo['departureAirportCode'] = leg.originDestination.departure.airportCode;
        flightInfo['arrivalAirportCode'] = leg.originDestination.arrival.airportCode;
        flightInfo['departureTime'] = leg.originDestination.departure.time;
        flightInfo['arrivalTime'] = leg.originDestination.arrival.time;
        this.flights.push(flightInfo);
      });
    });
  }
}
