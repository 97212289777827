import {CanDeactivate} from '@angular/router';
import { Injectable } from '@angular/core';
import {Observable} from "rxjs/internal/Observable";
import {ConfirmDialogService} from "../services/confirm-dialog.service";

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}


@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(public confirmDialogService: ConfirmDialogService) {
  }

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> | any {
    return component.canDeactivate() ? true : this.confirmDialogService.confirmDialog();
  }
}
