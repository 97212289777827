import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-show-taxes-info',
  templateUrl: './show-taxes-info.component.html',
  styleUrls: ['./show-taxes-info.component.scss']
})
export class ShowTaxesInfoComponent implements OnInit {

  @Input() taxesInfo;
  @Input() currency = '';
  @Input() isPDF: boolean;
  @ViewChild('popover') popover: NgbPopover;

  uniqueTaxes = [];
  buttonName = 'info_outline';

  @HostListener('click') onClick() {
    if (this.popover && !this.popover.isOpen()) {
      this.popover.open();
    }
  }

  @HostListener('mouseover') onMouseEnter() {
    this.buttonName = 'info';
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (!this.popover.isOpen()) {
      this.buttonName = 'info_outline';
    }
  }

  @HostListener('document:click', ['$event']) onClickOut(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.buttonName = 'info_outline';
    }
  }

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
    this.prepareTaxes();
  }

  prepareTaxes() {
    let holder = {};
    this.taxesInfo?.forEach((tax) => {
      if (holder.hasOwnProperty(tax.code)) {
        holder[tax.code] = {
          total: holder[tax.code].total + tax.total,
          currency: tax.currency
        };
      } else {
        holder[tax.code] = {
          total: tax.total,
          currency: tax.currency
        };
      }
    });

    for (let prop in holder) {
      this.uniqueTaxes.push({ code: prop, total: holder[prop].total, currency: holder[prop].currency });
    }
  }

}
