import { Pipe, PipeTransform } from '@angular/core';
import {Ticket} from "../interfaces/ticket";
import {Dictionary} from "../types/dictionary";

@Pipe({
  name: 'getCouponStatusCode'
})
export class GetCouponStatusCodePipe implements PipeTransform {

  transform(ticket: Ticket): any {
    const couponStatusCodePriority = ['warning', 'problem'];
    let highestStatusCode = '';
    ticket.coupons?.forEach(coupon => {
      const statusCode = Dictionary.CouponStatuses[coupon.status];
      const hasNoStatus = !coupon.status && !coupon.statusDefinition;

      coupon.statusCode = hasNoStatus ? (highestStatusCode = 'problem') : statusCode;
      if (statusCode && couponStatusCodePriority.indexOf(statusCode) > couponStatusCodePriority.indexOf(highestStatusCode)) {
        highestStatusCode = statusCode;
      }
    });
    return highestStatusCode;
  }

}
