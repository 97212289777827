import {NgModule} from '@angular/core';
import {ProfilesRoutingModule} from './profiles-routing';
import {CorporateListComponent} from './corporate-list/corporate-list.component';
import {CorporateDetailComponent} from './corporate-detail/corporate-detail.component';
import {CorporateComponent} from './corporate/corporate.component';
import {SharedModule} from '../shared/shared.module';
import {ProfileListComponent} from './profile-list/profile-list.component';
import {ProfileDetailComponent} from './profile-detail/profile-detail.component';
import { AddOrEditCorporateComponent } from './add-or-edit-corporate/add-or-edit-corporate.component';
import { DeleteCorporateModalComponent } from './delete-corporate-modal/delete-corporate-modal.component';

@NgModule({
  declarations: [
    CorporateComponent,
    CorporateListComponent,
    CorporateDetailComponent,
    ProfileListComponent,
    ProfileDetailComponent,
    AddOrEditCorporateComponent,
    DeleteCorporateModalComponent,
  ],
  imports: [
    SharedModule,
    ProfilesRoutingModule,
  ],
})
export class ProfilesModule {}
