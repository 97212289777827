import { Pipe, PipeTransform } from '@angular/core';
import { OfferService } from '../services/offer.service';

@Pipe({
  name: 'filterOfferByCabinClass'
})
export class FilterOfferByCabinClassPipe implements PipeTransform {

  constructor(
    private offerService: OfferService
  ){}

  transform(allOffers: any[], currentOffer: any, currentOD?: number, selectedOffer?: any): any[] {
    let offersWithSameOd = this.offerService.getOffersWithSameOD(currentOffer, allOffers, currentOD);

    if (selectedOffer && currentOD && currentOD > 0) {
      return offersWithSameOd.filter(offer => {
        let flag = true;
        for (let i = 0; i < currentOD; i++) {
          if (
            this.offerService.extractMarketingNames(offer, i) !== this.offerService.extractMarketingNames(selectedOffer, i)
          ) {
            flag = false;
            return;
          }
        }
        return flag;
      });
    }

    return offersWithSameOd;
  }

}
