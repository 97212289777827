import {FormControl} from "@angular/forms";

export class ExpirationsValidator {

  static YearValidator() {
    return function YearValidator(control: FormControl): { [s: string]: boolean } {
      if (!control.value.match(/^([2-9][3-9]|[3-9][0-9])$/)) {
        return {invalidChars: true};
      }
    };
  }
  static MonthValidator() {
    return function MonthValidator(control: FormControl): { [s: string]: boolean } {
      if (!control.value.match(/^(0[1-9]|1[0-2])$/)) {
        return {invalidChars: true};
      }
    }
  }
  static CvcValidator() {
    return function CvcValidator(control: FormControl): { [s: string]: boolean } {

      if (!control.value.match(/^([0-9]{3,4})$/)) {
        return {invalidChars: true};
      }
    }
  }
  static CardNumberValidator() {
    return function CardNumberValidator(control: FormControl): { [s: string]: boolean } {

      if (!control.value.match(/^([0-9]{14,16}|[0-9]{19})$/)) {
        return {invalidChars: true};
      }
    }
  }

  static CardNumberLuhnValidator(cardCode: any) {

    return function CardNumberLuhnValidator(control: FormControl): { [s: string]: boolean } {
      // credit cards types
      const acceptedCreditCards = {
        // visa
        VI: /^4[0-9]{12}(?:[0-9]{3})?$/,
        // MasterCard
        CA: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
        // American Express
        AX: /^3[47][0-9]{13}$/,
        // Diner's Club
        DC: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        // Discover
        DS: /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/,
        // Carte Blanche
        CB: /^389[0-9]{11}$/,
        //UATP/AIRPLUS
        TP: /^1\d{14}$/,
        // EasyPay
        EP: /^\d{10,20}$/,
        //Default
        DF: /(\d{1,4})(\d{1,4})?(\d{1,4})?(\d{1,4})?/,
      };

      // remove all non digit characters
      let value = control.value.replace(/\D/g, '');
      let sum = 0;
      let shouldDouble = false;
      // loop through values starting at the rightmost side
      for (let i = value.length - 1; i >= 0; i--) {
        let digit = parseInt(value.charAt(i));

        if (shouldDouble) {
          if ((digit *= 2) > 9) digit -= 9;
        }

        sum += digit;
        shouldDouble = !shouldDouble;
      }

      let valid = (sum % 10) === 0;
      let accepted = false;

      let chosenCode = 'DF';

      if (cardCode && acceptedCreditCards[cardCode.value]) {
        chosenCode = cardCode.value;
      }
      // loop through the keys (visa, mastercard, amex, etc.)
      let regex = acceptedCreditCards[chosenCode];
      let digitsRegex = /^\d+$/;

      if (regex.test(control.value) && digitsRegex.test(control.value)) {
        accepted = true;
      }

      if (!valid || !accepted) {
        return {invalidChars: true, invalidCard: true};
      }
    };
  }
}
