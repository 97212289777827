import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-aircraft-icon',
  templateUrl: './aircraft-icon.component.html',
  styleUrls: ['./aircraft-icon.component.scss']
})
export class AircraftIconComponent implements OnInit {
  @Input() aircraftCode = '';

  constructor() { }

  ngOnInit(): void {
  }

}
