import {Pipe, PipeTransform} from "@angular/core";
import {HelpersService} from "../services/helpers.service";

@Pipe({ name: 'groupedByFlights' })
export class GroupedByFlightsPipe implements PipeTransform {

  constructor(private helpers: HelpersService) {}

  transform(offers: any[]) {
    return this.helpers.groupOffersByFlights(offers);
  }
}
