import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSegmentsBySelectedSeats'
})
export class FilterSegmentsBySelectedSeatsPipe implements PipeTransform {

  transform(segments: any[], seats: any[]): any[] {

    let filteredSegments = [];
    segments.map(segment => {
      let isAllowed = false;
      seats.map(seat => {
        if (seat.segment === segment.segmentID) {
          isAllowed = true;
        }
      });
      if (isAllowed) {
        filteredSegments.push(segment);
      }
    });

    return filteredSegments;
  }

}
