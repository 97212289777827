import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortTravelersControls'
})
export class SortTravelersControlsPipe implements PipeTransform {

  transform(controls: any[], ptcsMap: any): any[] {
    const sortPriority = Object.values(ptcsMap);
    return controls.sort((a, b) => sortPriority.indexOf(a.key) - sortPriority.indexOf(b.key));
  }

}
