export interface Preset {
  title: string;
  airline: string;
  travelers: {travelerType: string, defaultType: string}[];
  qualifiers: {qualifierType: string}[];
  qualifier: Qualifier;
  preference: Preference[];
  preferences: Preference[];
  taxExemptions: { code: string }[];
  isDefault: boolean;
  isMandatory: boolean;
}

export interface Qualifier {
  type: string;
  code: string;
  airline: string;
}

export interface Preference {
  code: string;
  definition: string;
  id?: string;
  title: string;
}

export interface PresetForm {
  title: string;
  airline: string;
  travelerType: string;
  qualifierType: string;
  preferenceCode: string;
  preferenceDefinition: string;
  preferenceTitle: string;
}

export const DefaultQualifierTypes = ['account_id', 'contract_id', 'promo_code'];
export const DefaultQualifierNames = {'account_id': 'Account ID', 'contract_id': "Contract ID", 'promo_code': "Promo Code"};
