import {FormControl} from "@angular/forms";

function minimum(a: number, b: number, c: number) {
  if (a < b) {
    if (a < c) {
      return a;
    }
  } else {
    if (b < c) {
      return b;
    }
  }
  return c;
}

export class PassengerUpdatesValidator {

  static NameCorrectionValidator(startedName: any, correctionsAllowed: number) {
    return function NameCorrectionValidator(control: FormControl): { [s: string]: boolean } {
      if (!control || !control.value || !startedName) {
        return null;
      }

      let correctedName = control.value.replace(/\s+/g, ' ');
      const startedNameLength = startedName.length;
      const correctedNameLength = correctedName.length;

      if (correctedName.startsWith('-') || correctedName.endsWith('-') || correctedName.startsWith(' ') || correctedName.endsWith(' ')) {
        return {invalidStartOrEndChars: true};
      }

      if (correctionsAllowed !== -1 && ((startedNameLength - correctedNameLength > correctionsAllowed) || (correctedNameLength - startedNameLength > correctionsAllowed))) {
        return {invalidChars: true};
      }

      if (startedName !== correctedName) {
        let column = [];

        for (let i = 1; i <= startedNameLength; i++) {
          column[i] = i;
        }

        for (let x = 1; x <= correctedNameLength; x++) {
          column[0] = x;
          let lastKey = x - 1;

          for (let y = 1; y <= startedNameLength; y++) {
            let oldKey = column[y];
            let incr = 0;
            if (startedName[y - 1] !== correctedName[x - 1]) {
              incr = 1;
            }
            column[y] = minimum(column[y] + 1, column[y - 1] + 1, lastKey + incr);
            lastKey = oldKey;
          }
        }
        let countTheDifferenceOnName = column[startedNameLength];

        if (correctionsAllowed !== -1 && countTheDifferenceOnName > correctionsAllowed) {
          return {invalidChars: true};
        }
      }
    };
  }
}

