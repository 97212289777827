import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seatsPerPsgAndSegment'
})
export class SeatsPerPsgAndSegmentPipe implements PipeTransform {

  transform(seats: any[], pRef: string, sRef: string): any[] {
    if (!seats || !pRef || !sRef) {
      return [];
    }
    let seatsArr = JSON.parse(JSON.stringify(seats));
    return seatsArr.filter((seat) => seat.refs === pRef && seat.segment === sRef);
  }

}
