import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPriceGroupIndexForSeat'
})
export class GetPriceGroupIndexForSeatPipe implements PipeTransform {

  transform(price: number, priceRanges: { min: number; max: number }[]): number {
    // Find the group the price belongs to
    for (let i = 0; i < priceRanges.length; i++) {
      if (price >= priceRanges[i].min && price <= priceRanges[i].max) {
        return i; // Return the group index
      }
    }

    return -1;
  }

}
