import {Component, Input, OnInit} from '@angular/core';
import {AIRCRAFT_CODES} from "../../constants";

@Component({
  selector: 'app-main-flight-segment',
  templateUrl: './main-flight-segment.component.html',
  styleUrls: ['./main-flight-segment.component.scss']
})
export class MainFlightSegmentComponent implements OnInit {

  @Input() flightSegment;
  @Input() segIdx;
  @Input() flightIdx;
  @Input() fareNames;
  @Input() isPDFExtract;
  @Input() flightKey;
  @Input() disclosures;
  @Input() oldFareNames;
  @Input() oldFlightSegment;

  constructor() { }

  filteredFareNames = [];
  aircraftCodes = AIRCRAFT_CODES;

  ngOnInit() {
    if (this.fareNames?.[this.flightIdx]?.[this.segIdx]?.disclosures) {
      this.filteredFareNames = this.fareNames[this.flightIdx][this.segIdx].disclosures
        .filter(disclosure => {
          if (disclosure.category === 'EXCHANGE') {
            return false;
          }
          if (disclosure.originDestinationReference) {
            return disclosure.originDestinationReference.includes(this.flightSegment.ODRef);
          }
          return true;
        });
    }
  }
}
