import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getColor'
})
export class GetColorPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (!value) {
      return null;
    }
    if (value === 'HK') {
      return '#00D97E';
    } else if (value === 'UN') {
      return '#CD0005';
    } else {
      return 'gray';
    }
  }

}
