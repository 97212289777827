import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pnr-dropdown',
  templateUrl: './pnr-dropdown.component.html',
  styleUrls: ['./pnr-dropdown.component.scss']
})
export class PnrDropdownComponent implements OnInit, OnChanges {

  @Output() emitSelectedAirports = new EventEmitter();
  @Input() ODs;
  @Input() isDisabled;



  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  selectAirport(type, value, index) {

    this.ODs.map((od, i) => {
      this.ODs[i].isChange = false;
    });

    if (type === 'arrival') {
      this.ODs[index].arrivalSelected = value;
    }

    if (type === 'departure') {
      this.ODs[index].departureAirPorts = value;
    }

    this.ODs[index].isChange = true;

    this.emitSelectedAirports.emit(this.ODs);
  }
}
