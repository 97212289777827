import { Injectable } from '@angular/core';
import { ConfigService } from '../../shared/services/config.service';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import {environment} from "../../../environments/environment";

declare let gtag;

@Injectable({
  providedIn: 'root'
})
export class GtmService {

  constructor(
    private ls: LocalStorageService,
    private config: ConfigService
  ) { }

  addEvent(eventName: string) {
    if (environment.name === 'dev') {
      return;
    }

    if (gtag) {
      gtag('event', eventName, {
        'event_category': environment.name,
        'event_label': this.ls.agency
      });
    }
  }

  addData(data: {[key: string]: string}) {
    if (gtag && environment.name !== 'dev') {

      if (this.config.KEYS.gtmId) {
        gtag('config', this.config.KEYS.gtmId, data);
      }

      if (this.config.KEYS.oldGtmId) {
        gtag('config', this.config.KEYS.oldGtmId, data);
      }

    }
  }
}
