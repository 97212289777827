import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {ErrorAlert} from "../../models/error-alert";
import {NDCApiService} from "../../services/ndc-api.service";
import {HelpersService} from "../../services/helpers.service";
import {PaymentModuleService} from "../../services/payment-module.service";
import {ORDER_STATUS} from "../../constants";
import {convertPhoneIntoString} from "../../adapters/v1_2/adapters";

@Component({
  selector: 'app-installment-options',
  templateUrl: './installment-options.component.html',
  styleUrls: ['./installment-options.component.scss']
})
export class InstallmentOptionsComponent implements OnInit, OnDestroy {

  @Input() order;
  @Input() offerID;
  @Input() latestPrice;
  @Output() emitInstallmentId = new EventEmitter<string>();
  @Output() emitStepBack = new EventEmitter<boolean>();

  modalContentElement: HTMLElement | null;
  paymentInfoLoader = false;
  paymentInfoError: ErrorAlert = new ErrorAlert();
  installmentOptions = [];

  constructor(private ndcApiService: NDCApiService,
              private helpers: HelpersService,
              private paymentService: PaymentModuleService,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.modalContentElement = document.querySelector('.modal-content');
    if (this.modalContentElement) {
      this.renderer.addClass(this.modalContentElement, 'payment-form-modal-content');
    }
    this.sendPaymentInfo();
  }

  sendPaymentInfo() {
    const method = this.paymentService.form.get('method')?.value;
    const isCardOrSavedCard = method === 'card' || method === 'savedCard';
    if (isCardOrSavedCard && this.isPaymentInfoAllowed()) {
      this.paymentInfoLoader = true;
      const body: any = {
        payment: this.paymentService.getPaymentData()
      };
      if (this.offerID) {
        body.offerID = this.offerID;
      } else {
        body.id = this.order.id;
      }
      if (body.payment && body.payment.phone) {
        body.payment.phone = convertPhoneIntoString(body.payment.phone);
      }
      this.ndcApiService.sendPaymentInfo(body)
        .then(res => {
          this.paymentInfoLoader = false;
          if (res) {
            this.installmentOptions = res.installmentOptions;
            this.paymentService.selectedInstallmentId = this.installmentOptions?.[0]?.installmentId;
            this.latestPrice = {
              value: res.price.consumer.total,
              currency: res.price.consumer.currency
            };
          }
        }).catch(err => {
        this.paymentInfoLoader = false;
        this.paymentInfoError = this.helpers.getError(err);
      });
    } else {
      this.emitStepBack.emit(true);
    }
  }

  onInstallmentOptionChange(installmentId: string, isChecked: boolean) {
    this.paymentService.selectedInstallmentId = isChecked ? installmentId : '';
  }

  isPaymentInfoAllowed() {
    return this.paymentService.form.valid && (this.order.status === ORDER_STATUS.PENDING && this.order.allowedRequests.pending.PaymentInfo) ||
      (this.order.status === ORDER_STATUS.TICKETED && this.order.allowedRequests.ticketed.PaymentInfo);
  }

  ngOnDestroy() {
    if (this.modalContentElement) {
      this.renderer.removeClass(this.modalContentElement, 'payment-form-modal-content');
    }
  }

}
