import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PnrGeneratorService} from "../../../dashboard/services/pnr-generator.service";

@Component({
  selector: 'app-export-to-pnr',
  templateUrl: './export-to-pnr.component.html',
  styleUrls: ['./export-to-pnr.component.scss']
})
export class ExportToPnrComponent implements OnInit {

  @Input() order;
  @Input() popover;
  @Input() isBtn = true;

  PNR;

  constructor(private modalService: NgbModal,
              public pnrGeneratorService: PnrGeneratorService,
  ) { }

  ngOnInit(): void {
  }

  open(content, size: any = 'lg') {
    this.modalService.open(content, {
      size: size,
      windowClass: 'modal-pnr'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  openModalPNR(PNRmodal) {
    this.clearPNR();
    this.open(PNRmodal);
    this.pnrGeneratorService.order = this.order;
    this.PNR = this.pnrGeneratorService.generatePnr();
  }

  clearPNR() {
    this.pnrGeneratorService.flights = [];
    this.pnrGeneratorService.flightsRows = [];
  }

}

