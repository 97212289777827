import { Injectable } from '@angular/core';
import * as Sentry from "@sentry/browser";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  structuredContext = {};
  owner = '';
  lastIndexedDbDataToBeAdded = {};

  constructor() { }

  notify(error: any){
    console.error(error);
    if (environment.name !== 'dev') {
      Sentry.captureException(error.originalError || error.error || error);
    }
  }

  setContext(name: string, context: any) {
    Sentry.setContext(name, context);
  }

  setAdditionalData(session_id, request_id, request_body, http_code = 0, response_body = {}) {
    this.clearObjectFromForbiddenValues(request_body);
    this.structuredContext['data'] = {
      session_id,
      request_id,
      request: request_body,
      response: {
        http_code,
        body: response_body
      }
    };
  }

  clearObjectFromForbiddenValues(obj) {
    let hiddenValue = 'X';
    if (obj.payment) {
      const paymentForbiddenValues = ['cardNumber', 'cardCode', 'seriesCode', 'approvalCode'];
      paymentForbiddenValues.map(value => {
        if (obj.payment[value]) {
          obj.payment[value] = hiddenValue.repeat(obj.payment[value].length);
        }
      });
    }
  }
}
