import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {HelpersService} from '../../shared/services/helpers.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {LocalStorageService} from '../../shared/services/local-storage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HubProfileService} from '../../shared/services/hub-profile.service';
import {Profile, Role} from '../../shared/types/profile';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../shared/services/notification.service';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss']
})
export class ProfileListComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  @Input() corporateID: string;
  @Input() role: Role;
  @Input() set profiles(value: Profile[]) {
    this.filteredProfiles = [...value];
    this._profiles = [...value];
  }
  _profiles  = [];

  // pagination
  page = 1;
  pageSize = 10;
  totalCount = 0;

  filteredProfiles: Profile[] = [];
  searchForm: FormGroup;
  searchFormFields = {
    travelerSearch: 'travelerSearch'
  };

  isSearchInputFocused = false;

  constructor(public helpers: HelpersService,
              public fb: FormBuilder,
              public ls: LocalStorageService,
              public modalService: NgbModal,
              private router: Router,
              private route: ActivatedRoute,
              private _notificationSvc: NotificationService,
              private hubProfileService: HubProfileService,
  ) { }

  ngOnInit() {
    this.initSearch();
    this.parseUrl();
  }

  initSearch() {
    this.searchForm = this.fb.group({
      [this.searchFormFields.travelerSearch]: ['']
    });
    this.searchForm.get([this.searchFormFields.travelerSearch])
      .valueChanges
        .pipe(
          takeUntil(this.ngUnsubscribe$),
          debounceTime(500),
          distinctUntilChanged(),
        )
        .subscribe((value: string) => {
          this.filteredProfiles = value ? this.search(value) : this._profiles;
          if (value) {
            this.page = 1;
          }
        });
  }

  search(value: string): any[] {
    return this._profiles.filter((trv: Profile) => {
      const val = value.toLowerCase();

      return trv.email.toLowerCase().includes(val)
        || `${trv.name} ${trv.surname} ${trv.name}`.toLowerCase().includes(val);
      });
  }

  open(content, size: any = 'lg') {
    this.modalService.open(content, {
      size
    })
      .result.then((result) => {
    }, (reason) => {
    });
  }

  setRole(role: Role) {
    this.hubProfileService.role = role;
  }

  onPageChange() {
    let params = { ...this.route.snapshot.queryParams };
    params['page'] = this.page;
    params['pageSize'] = this.pageSize;
    this.router.navigate([], {queryParams: params});
  }

  parseUrl() {
    let params = { ...this.route.snapshot.queryParams };
    this.page = parseInt(params.page) || 1;
    this.pageSize = parseInt(params.pageSize) || 10;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}
