import {Directive, Input, ElementRef, HostListener, Renderer2, OnDestroy} from '@angular/core';
import { Dictionary } from "../types/dictionary";

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnDestroy {

  @Input('appTooltip') tooltipTitle: any;
  @Input() placement = 'top';
  @Input() delay = 100;
  tooltip: HTMLElement;
  airName: HTMLElement;
  terminal: HTMLElement;
  offset = 10;
  airportsInfo = Dictionary.Airports;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.el.nativeElement.style.cursor = 'default';
  }

  @HostListener('mouseover') onMouseEnter() {
    if (!this.tooltip) {
      this.show();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) {
      this.hide();
    }
  }

  ngOnDestroy() {
    if (this.tooltip) {
      this.hide();
    }
  }

  show() {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
  }

  hide() {
    this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
    if ( this.tooltip ) {
      if (document.body?.contains(this.tooltip)) {
        this.renderer.removeChild(document.body, this.tooltip);
      }
      this.tooltip = null;
    }
  }

  create() {
    this.tooltip = this.renderer.createElement('div');
    this.airName = this.renderer.createElement('div');
    this.terminal = this.renderer.createElement('div');


    this.renderer.appendChild(
      this.airName,
      this.renderer.createText(this.tooltipTitle.airportCode
      && this.airportsInfo[this.tooltipTitle.airportCode]
      && this.airportsInfo[this.tooltipTitle.airportCode]['name'] ? this.airportsInfo[this.tooltipTitle.airportCode]['name'] : '')
    );
    this.renderer.appendChild(
      this.terminal,
      this.renderer.createText(this.tooltipTitle.terminalName ? `Terminal: ${this.tooltipTitle.terminalName}` : ''));


    this.renderer.appendChild(this.tooltip, this.airName);
    this.renderer.appendChild(this.tooltip, this.terminal);
    this.renderer.appendChild(document.body, this.tooltip);
    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);

    // delay
    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);

    //positioning terminal
    this.renderer.setStyle(this.terminal, 'text-align', 'center');
  }

  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltip.getBoundingClientRect();
    // window scroll top
    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let top, left;

    if (this.placement === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }

}
