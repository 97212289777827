import {Component, Input, OnInit} from '@angular/core';
import {
  collapseAnimation,
  rotateAnimation
} from 'angular-animations';
@Component({
  selector: 'app-itinerary-details',
  templateUrl: './itinerary-details.component.html',
  styleUrls: ['./itinerary-details.component.scss'],
  animations: [
    rotateAnimation({duration: 500 }),
    collapseAnimation()
  ]
})
export class ItineraryDetailsComponent implements OnInit {

  @Input() flights;
  @Input() disclosures;
  @Input() fareNames;
  @Input() passengers;
  @Input() isPDFExtract;

  isItineraryDetailsOpened = true;

  constructor() { }

  ngOnInit(): void {
  }

}
