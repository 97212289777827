import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ssrPerPsgAndSegment'
})
export class SsrPerPsgAndSegmentPipe implements PipeTransform {

  transform(specialServices: any[], sRef: string): any[] {
    if (!specialServices || !sRef) {
      return [];
    }
    let specialServicesArr = JSON.parse(JSON.stringify(specialServices));
    return specialServicesArr.filter((ssr) => ssr.segmentIDs && ssr.segmentIDs.indexOf(sRef) !== -1);
  }

}
