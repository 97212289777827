import {Component, Input, OnInit} from '@angular/core';
import {collapseAnimation, rotateAnimation} from "angular-animations";

interface OsiInfo {
  actionCode: string;
  osiCode: string;
  passengerRef: string;
  text: string;
}

@Component({
  selector: 'app-osi-details',
  templateUrl: './osi-details.component.html',
  styleUrls: ['./osi-details.component.scss'],
  animations: [
    rotateAnimation({duration: 500 }),
    collapseAnimation()
  ]
})
export class OsiDetailsComponent implements OnInit {
  @Input() otherServiceInformation: OsiInfo[];
  @Input() passengers: any[];
  @Input() isPDFExtract: boolean;

  passengersExpanded = {};

  isOsiDetailsOpened = true;

  constructor() { }

  ngOnInit(): void {
    this.otherServiceInformation = this.groupOsiEntries(this.otherServiceInformation);
  }

  groupOsiEntries(osiArr: OsiInfo[]): OsiInfo[] {
    const result: OsiInfo[] = [];
    const map: Map<string, OsiInfo> = new Map();

    osiArr?.forEach(item => {
      const key = `${item.actionCode}-${item.osiCode}-${item.text}`;
      if (!map.has(key)) {
        map.set(key, { ...item, passengerRef: item.passengerRef });
        result.push(map.get(key));
      } else {
        const existingItem = map.get(key);
        existingItem.passengerRef += ` ${item.passengerRef}`;
      }
    });

    return result;
  }
}
