import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Agency} from "../types/agency";
import { ErrorResponse, MetaResponse} from "../types/system";
import { environment} from "../../../environments/environment";
import {Preset} from "../interfaces/presets";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HubAgenciesService {

  isPresetsLoaded = new BehaviorSubject(false);
  isPresetsLoaded$ = this.isPresetsLoaded.asObservable();
  presets: Preset[] = [];
  mandatoryPreset: Preset;
  defaultPreset: Preset;

  private subUrl = `${environment.ndcApiEndpointV2}agent/access/agency/`;

  constructor(public http: HttpClient) { }

  getAll() {
    const url = this.subUrl + 'list';
    return this.http.get<HttpResponse<Agency[]>>(url, this.options());
  }

  addAgency(body): any {
    const url = this.subUrl + 'add';
    return this.http.post<HttpResponse<MetaResponse | ErrorResponse>>(url, body, this.options());
  }

  deactivateAgency(body): any {
    const url = this.subUrl + 'deactivate';
    return this.http.post<HttpResponse<MetaResponse | ErrorResponse>>(url, body, this.options());
  }

  changeManagerPassword(body): any {
    const url = this.subUrl + 'change-manager-password';
    return this.http.post<HttpResponse<MetaResponse | ErrorResponse>>(url, body, this.options());
  }

  getPresets() {
    const url = this.subUrl + 'presets';
    return this.http.get<Preset[]>(url, {observe: "body"});
  }

  updatePresets(presets: Preset[]) {
    const url = this.subUrl + 'presets';
    return this.http.put<MetaResponse | ErrorResponse>(url, presets, {observe: "body"});
  }

  refreshPresets() {
    this.getPresets()
      .subscribe(presets => {
          this.mandatoryPreset = null;
          this.defaultPreset = null;

          this.presets = presets.sort((a, b) => {
            if (a.isMandatory) { this.mandatoryPreset = a; }
            if (a.isDefault) { this.defaultPreset = a; }
            return a.title.localeCompare(b.title);
          });

          this.isPresetsLoaded.next(true);
        },
        error => {
          this.isPresetsLoaded.next(true);
          console.error('Error fetching presets:', error);
        }
      );
  }

  private options() {
    let options: any = {
      observe: 'response',
    };
    return options;
  }
}
