import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {tap} from 'rxjs/operators';

import {LocalStorageService} from './local-storage.service';
import {environment} from '../../../environments/environment';
import queryString from 'query-string';

@Injectable({
  providedIn: 'root'
})

export class ProfilesService {
  private baseUrl = `${environment.ndcApiEndpointV2}agent/profiles`;
  selectedAgency = '';
  allAgencies: string[] = [];


  constructor(
    public http: HttpClient,
    private ls: LocalStorageService,
  ) {
  }

  searchCorporates(query: string) {
    let options: any = {
      observe: 'response',
    };
    let consumer = this.ls.consumerCode;
    let agency = this.selectedAgency || this.allAgencies.join(',') || this.ls.agency;
    const q = queryString.stringify({
      query,
      consumer,
      agency,
    });

    const url = `${this.baseUrl}/account/search?${q}`;

    return this.http.request<HttpResponse<any>>('GET', url, options);
  }

  addCorporate(body: string, owner: string = '*') {
    let options: any = {
      body,
      observe: 'response',
    };

    let consumer = this.ls.consumerCode;
    let agency = this.ls.agency;
    const q = queryString.stringify({
      consumer,
      agency,
    });


    const url = `${this.baseUrl}/account/add?${q}`;
    return this.http.request<HttpResponse<any>>('POST', url, options);
  }

  addCorporateManagers(profiles) {
    let body = JSON.stringify(profiles);
    let options: any = {
      body,
      observe: 'response',
    };
    const url = `${this.baseUrl}/account/add-manager`;
    return this.http.request<HttpResponse<any>>('POST', url, options);
  }

  searchProfiles(query: string, corporate_id: string) {
    let options: any = {
      observe: 'response',
    };
    const q = queryString.stringify({query, corporate_id});
    const url = `${this.baseUrl}/traveler/search?${q}`;

    return this.http.request<HttpResponse<any>>('GET', url, options);
  }

  setTravelers(corporateID, passengers) {
    let profiles: any = [];
    passengers.map(p => {
      const frequentFlyerNumbers = [];
      if (p.data.fqtvInfo?.account?.number !== '' && p.data.fqtvInfo?.airline_id !== '') {
        frequentFlyerNumbers.push({
          alliance: p.data.fqtvInfo.airline_id,
          member_number: p.data.fqtvInfo.account.number,
        });
      }
      const documents = [];
      if (p.documents?.length) {
        p.documents?.forEach(document => {
          documents.push({
            id: document.documentID,
            type: document.documentType,
            expiration_date: document.expirationDate,
            fiscal_name: document.fiscalName,
            issuing_country_code: document.issuingCountryCode,
            citizenship_country_code: document.citizenshipCountryCode,
            residence_country_code: document.residenceCountryCode
          });
        });
      }
      profiles.push({
        name: p.data.name,
        surname: p.data.surname,
        email: p.data.email,
        birthdate: p.data.birthdate,
        gender: p.data.gender,
        title: p.data.title,
        documents: documents,
        phone_country_code: p.data.phone.countryCodeISO,
        phone_code: p.data.phone.countryCode,
        phone_number: p.data.phone.number,
        address_country_code: p.data.address.countryCode,
        address_postal_code: p.data.address.postalCode,
        address_city_name: p.data.address.cityName,
        address_street: p.data.address.street,
        corporate_id: corporateID,
        frequent_flyer_numbers: frequentFlyerNumbers
      });
    });
    let body = JSON.stringify(profiles);

    let options: any = {
      body,
      observe: 'response',
    };

    const url = `${this.baseUrl}/traveler/set`;
    return this.http.request<HttpResponse<any>>('POST', url, options);
  }

  getTraveler(id: string, corporate_id: string, owner: string = '*') {
    let options: any = {
      observe: 'response',
    };
    const q = queryString.stringify({
      id,
      corporate_id,
    });

    return this.http.request<HttpResponse<any>>('GET', `${this.baseUrl}/traveler/get?${q}`, options);
  }
}
