import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'servicePerPsgAndSegment'
})
export class ServicePerPsgAndSegmentPipe implements PipeTransform {
  transform(services: any[], pRef: string, sRef: string, flightIdx = 0, segmentIdx = 0): any {
    if (!services || !pRef || !sRef) {
      return [];
    }
    let servicesArr = JSON.parse(JSON.stringify(services));
    return servicesArr.filter((service) => {
      const hasPRef = service.travelerReferences.split(' ').indexOf(pRef) !== -1;
      const hasSRef = service.segmentReferencesIncluded?.includes(sRef) || service.segmentReferences.split(' ').indexOf(sRef) !== -1;
      const isSegmentReferencesEmpty = service.segmentReferences === '';
      const segmentRefIncludedIndex = service.segmentReferencesIncluded?.indexOf(sRef);
      if (service.price.consumer.total && (isSegmentReferencesEmpty || segmentRefIncludedIndex > 0) && (flightIdx !== 0 || segmentIdx !== 0)) {
        service.price.consumer.total = 0;
        service.priceHidden = true;
      }

      return hasPRef && (hasSRef || isSegmentReferencesEmpty);
    });
  }

}
