import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-show-approval-statuses',
  templateUrl: './show-approval-statuses.component.html',
  styleUrls: ['./show-approval-statuses.component.scss']
})
export class ShowApprovalStatusesComponent implements OnInit {
  @Input() order;

  constructor() { }

  ngOnInit(): void {
  }

}
