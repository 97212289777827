import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isSeatExists'
})
export class IsSeatExistsPipe implements PipeTransform {

  transform(char: string, row: number, segment: string, seatDisplay: any[]): boolean {
    if (char === '-') { return false; }

    return seatDisplay?.some(({ segment: sSegment, rows, columns }) =>
      sSegment === segment &&
      row >= rows.first &&
      row <= rows.last &&
      columns?.includes(char)
    );
  }

}
