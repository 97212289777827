import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { UmbrellaService } from '../shared/services/umbrella.service';
import {LocalStorageService} from "../shared/services/local-storage.service";

@Component({
  selector: 'app-umbrella-auth',
  templateUrl: './umbrella-auth.component.html',
  styleUrls: ['./umbrella-auth.component.scss']
})
export class UmbrellaAuthComponent implements OnInit, OnDestroy {

  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  errorMessage = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private umbrellaService: UmbrellaService,
    private router: Router,
    private ls: LocalStorageService
    ) { }

  ngOnInit() {
    this.getUmbrellaToken();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  /**
   * get token from Umbrella after redirect hurricane.umbrellanet.ch
   */
  getUmbrellaToken() {
    const code = this.activatedRoute.snapshot.queryParams['code'];
    if (code) {
      this.umbrellaService.getToken(code)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res: any) => {
        window.location.href = this.ls.hrefLink;
        this.ls.resetHrefLink();
      }, err => {
        this.errorMessage = err.message;
      });
    } else {
      this.router.navigate(['/search']);
    }
  }
}
