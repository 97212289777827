import { Pipe, PipeTransform } from '@angular/core';
import {Passenger, PaxBreakdownInfo} from "../interfaces/passenger";

@Pipe({
  name: 'sortPassengers'
})
export class SortPassengersPipe implements PipeTransform {

  transform(passengers: (Passenger | PaxBreakdownInfo)[]): (Passenger | PaxBreakdownInfo)[] {
    const sortPriority = ['ADT', 'YAD', 'CHD', 'INF'];
    return passengers.sort((a, b) => sortPriority.indexOf(a.passengerType) - sortPriority.indexOf(b.passengerType));
  }

}
