import { Pipe, PipeTransform } from '@angular/core';
import {Passenger} from "../interfaces/passenger";

@Pipe({
  name: 'passengerByRef'
})
export class PassengerByRefPipe implements PipeTransform {

  transform(passengers: Passenger[], travelerReference: string): Passenger | null {
    return passengers.find(passenger => passenger.travelerReference === travelerReference);
  }
}
