import {Pipe, PipeTransform} from "@angular/core";
import {HelpersService} from "../services/helpers.service";

@Pipe({ name: 'isShowGridColumnCheckbox' })
export class IsShowGridColumnCheckboxPipe implements PipeTransform {

  constructor(
    public helpers: HelpersService
  ) {}

  transform(obj , cls) {
    let flag = false;
    Object.keys(obj).map(item => {
      if (!!obj[item][cls] && obj[item][cls].count > 0) {
        flag = true;
        return;
      }
    })
    return flag;
  }
}
