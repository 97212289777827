import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ShowFrontGuard implements CanActivate {

  constructor(public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const route = next.data.route;
    const subDomainName = window.location.hostname;
    const environmentSubDomain = environment.frontendDependencies.find(item => item.subDomain === subDomainName);
    let subDomain = environment.default;
    let showRoute = true;

    if (environmentSubDomain) {
      subDomain = environmentSubDomain;
    }

    if (route === 'orders') {
      showRoute = subDomain.showOrders;
    }

    if (route === 'subagencies') {
      showRoute = subDomain.showSubagencies;
    }

    if (route === 'search') {
      showRoute = subDomain.showSearch;
    }
    if (route === 'corporates') {
      showRoute = subDomain.showCorporates;
    }
    if (!showRoute) {
      this.router.navigate(['/not-found']);
      return false;
    }

    return true;
  }

}
