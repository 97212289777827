import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {NotificationService} from "../../services/notification.service";
import {Notification, NotificationType} from "../../models/notification";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

  notifications: Notification[] = [];
  private _subscription: Subscription;

  constructor(private _notificationSvc: NotificationService) { }

  ngOnInit() {
    this._subscription = this._notificationSvc.getObservable().subscribe(notification => {
      if (notification) {
        this._addNotification(notification);
      } else {
        this.notifications = [];
      }
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  private _addNotification(notification: Notification) {
    const foundNotification = this.notifications.find(error => error.title === notification.title && error.message === notification.message);
    if (!foundNotification) {
      this.notifications.push(notification);
    }

    if (notification.timeout !== 0) {
      setTimeout(() => this.close(notification), notification.timeout);

    }
  }

  close(notification: Notification) {
    this.notifications = this.notifications.filter(notif => notif.id !== notification.id);
  }

  className(notification: Notification): string {

    let style: string;

    switch (notification.type) {

      case NotificationType.success:
        style = 'success';
        break;
      case NotificationType.warning:
        style = 'warning';
        break;
      case NotificationType.error:
        style = 'error';
        break;
      case NotificationType.providerError:
        style = 'provider-error';
        break;
      default:
        style = 'info';
        break;
    }

    return style;
  }

}
