import { Injectable } from '@angular/core';
import {Disclocures, DisclosureDescription} from "../interfaces/disclosures";

@Injectable({
  providedIn: 'root'
})
export class DisclosureService {
  OTHER_CATEGORY = [
    "EXCHANGE",
    "PRIORITY",
    "UNKNOWN",
    "OTHER",
    "FARE_TYPE",
    "TICKETING",
    "CANCEL",
    "BOARDING",
    "CHANGES",
    "DISCLAIMER",
    "TRACKING"
  ];

  constructor() { }

  public groupDisclosuresByCategory(disclosureDescription: DisclosureDescription[]): Map<string, DisclosureDescription[]> {
    const groupedDisclosuresByCategory = new Map<string, DisclosureDescription[]>();
    for (let disclosure of disclosureDescription) {
      const category = this.OTHER_CATEGORY.includes(disclosure.category) ? "_OTHER" : disclosure.category;
      const existDisclosuresByCategory = groupedDisclosuresByCategory.get(category);
      if (existDisclosuresByCategory && this.isDisclosureExist(existDisclosuresByCategory, disclosure)) {
        existDisclosuresByCategory.push(disclosure);
        groupedDisclosuresByCategory.set(category, existDisclosuresByCategory);
      } else {
        groupedDisclosuresByCategory.set(category, [disclosure]);
      }
    }

    return groupedDisclosuresByCategory;
  }

  public filterDisclosuresByODR(disclosures: Disclocures[], key: string, iSAddGeneralDisclosures = true) {
    const filteredByFlightKey: DisclosureDescription[] = [];
    // if(disclosures != null) {
    if (key) {
      disclosures?.map(disclosure => {
        disclosure.descriptions?.map(descr => {
          if ((!descr.originDestinationReference && iSAddGeneralDisclosures) ||
                descr.originDestinationReference?.split(' ').includes(key)) {
            filteredByFlightKey.push(descr);
          }
        });
      });
    } else if (disclosures?.length > 0) {
      disclosures.forEach(disclosure => {
        if (disclosure.descriptions) {
          filteredByFlightKey.push(...disclosure.descriptions);
        }
      });
    }

    return filteredByFlightKey;
  }

  private isDisclosureExist(existDisclosuresByCategory: DisclosureDescription[], disclosure: DisclosureDescription) {
    for (let discl of existDisclosuresByCategory) {
      if (discl.text === disclosure.text) {
        return false;
      }
    }
    return true;
  }
}
