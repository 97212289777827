import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-offer-price-header-view',
  templateUrl: './offer-price-header-view.component.html',
  styleUrls: ['./offer-price-header-view.component.scss']
})
export class OfferPriceHeaderViewComponent implements OnInit {
  @Input() oldOffer: any;
  @Input() offer: any;
  @Input() isNew = false;

  flightType = '';
  offerClassCodes = '';

  constructor() { }

  ngOnInit(): void {
    this.flightType = this.getFlightType();
    this.offerClassCodes = this.getOfferClassCodes();
  }

  getOfferClassCodes() {
    if (!this.offer) {
      return '';
    }
    let codes = [];
    this.offer.flights.map(flight => {
      flight.segments.map(fs => {
        if (codes.indexOf(fs.detail.classOfService.code) === -1) {
          codes.push(fs.detail.classOfService.code);
        }
      });
    });
    return codes.join(',');
  }

  getFlightType() {
    let flights = this.offer.flights;
    let flightType = '';
    const flightTypes = {
      OW: 'One-Way',
      RT: 'Round-Trip',
      MC: 'Multi-City',
    };
    if (flights.length === 1) {
      flightType = flightTypes.OW;
    } else if (flights.length === 2) {
      let pocket = [flights[0].departure.airportCode, flights[0].arrival.airportCode];
      let pocket2 = [flights[1].departure.airportCode, flights[1].arrival.airportCode];
      if (pocket[0] === pocket2[1] && pocket[1] === pocket2[0]) {
        flightType = flightTypes.OW;
      } else {
        flightType = flightTypes.MC;
      }
    } else {
      flightType = flightTypes.RT;
    }
    return flightType;
  }

}
