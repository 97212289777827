import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";
import {collapseAnimation, rotateAnimation} from "angular-animations";

@Component({
  selector: 'app-order-expiration',
  templateUrl: './order-expiration.component.html',
  styleUrls: ['./order-expiration.component.scss'],
  animations: [
    rotateAnimation({duration: 500 }),
    collapseAnimation()
  ]
})
export class OrderExpirationComponent implements OnInit {
  @Input() order: any;
  @Input() currentDate: Date;
  @ViewChild('paymentTimeLimitPopover') paymentTimeLimitPopover: NgbPopover;
  @ViewChild('priceGuaranteeTimeLimitPopover') priceGuaranteeTimeLimitPopover: NgbPopover;

  buttonName = {priceExpiration: 'info_outline', orderExpiration: 'info_outline'};
  isTTLOpened = true;


  onMouseEnter(key: string) {
    this.buttonName[key] = 'info';
  }

  onMouseLeave(key: string, popover: NgbPopover) {
    if (popover && !popover.isOpen()) {
      this.buttonName[key] = 'info_outline';
    }
  }

  @HostListener('document:click', ['$event']) onClickOut(event) {
    if (!event.target?.className?.includes('payment-info-icon') && !event.target?.className?.includes('payment-limit')) {
      this.buttonName.orderExpiration = 'info_outline';
    }
    if (!event.target?.className?.includes('price-guarantee-info-icon') && !event.target?.className?.includes('price-guarantee-limit')) {
      this.buttonName.priceExpiration = 'info_outline';
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
