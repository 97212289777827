import {Directive, Input} from "@angular/core";
import {ControlValueAccessor, NgControl} from "@angular/forms";

@Directive({ selector: '[appTrim]' })
export class TrimDirective {
  @Input() trimSpaces = false;
  constructor(private ngControl: NgControl) {
    trimValueAccessor(ngControl.valueAccessor, this);
  }
}

function trimValueAccessor(valueAccessor: ControlValueAccessor, directiveInstance: TrimDirective) {
  const original = valueAccessor.registerOnChange;

  valueAccessor.registerOnChange = (fn: (_: unknown) => void) => {
    return original.call(valueAccessor, (value: unknown) => {
      const replaceValue = directiveInstance?.trimSpaces ? '' : ' ';
      return fn(typeof value === 'string' ? value.replace(/\s+/g, replaceValue).trim() : value);
    });
  };
}
