import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appLettersNumbersOnly]'
})
export class LettersNumbersOnlyDirective {
  private regex: RegExp = new RegExp(/^[A-Za-z0-9]+$/);

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const inputValue: string = this.el.nativeElement.value.concat(event.key);
    if (!this.regex.test(inputValue)) {
      event.preventDefault();
    }
  }

}
