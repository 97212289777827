import {
  ChangeDetectionStrategy,
  Component, EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-select-airline',
  templateUrl: './select-airline.component.html',
  styleUrls: ['./select-airline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectAirlineComponent),
      multi: true
    }
  ]
})
export class SelectAirlineComponent implements OnInit, ControlValueAccessor {

  @Input() placeholder = '';
  @Input() isProviders = false;
  @Input() isDisabled: boolean;
  @Output() changed = new EventEmitter<string>();

  value: string;
  isShowDropdown = false;
  searchPhrase = '';

  constructor() { }

  ngOnInit(): void {}

  private propagateChange: any = () => { };
  private propagateTouched: any = () => { };

  writeValue(airline: any): void {
    if (airline?.id) {
      this.propagateChange(airline.id);
    } else {
      this.value = airline;
    }
  }

  selectAirline(airline: any) {
    this.isShowDropdown = false;
    this.propagateTouched();
    this.value = airline.id;
    this.writeValue(airline);
  }

  openDropdown() {
    if (this.isProviders) {
      this.searchPhrase = '';
    }
    this.isShowDropdown = true;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onKeyup(value: string): void {
    this.searchPhrase = value;
    if (!value) {
      this.value = '';
      this.propagateChange();
      this.changed.emit(value);
    }
  }

  onBlur(): void {
    this.propagateTouched();
    setTimeout(() => {
      this.isShowDropdown = false;
    });
  }
}
