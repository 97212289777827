export class FakeError extends Error {

  constructor(message= "") {
    super(message);
    this.name = "FakeError";
    this.message = message;

    Object.setPrototypeOf(this, FakeError.prototype);
  }
}
