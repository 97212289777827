import {Component, Input, OnInit} from '@angular/core';
import { Ticket } from '../../interfaces/ticket';
import {Passenger} from "../../interfaces/passenger";
import {DateService} from "../../services/date.service";
import {
  collapseAnimation,
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  rotateAnimation,
  slideInDownOnEnterAnimation,
} from "angular-animations";

@Component({
  selector: 'app-tickets-info',
  templateUrl: './tickets-info.component.html',
  styleUrls: ['./tickets-info.component.scss'],
  animations: [
    rotateAnimation({ degrees: -180, duration: 500 }),
    fadeInOnEnterAnimation({ duration: 500 }),
    fadeOutOnLeaveAnimation({ duration: 500 }),
    slideInDownOnEnterAnimation({ duration: 300 }),
    collapseAnimation()
  ]
})
export class TicketsInfoComponent implements OnInit {

  @Input() order;
  @Input() passenger: Passenger;
  @Input() isPdf = false;
  @Input() showCell = {price: true};

  tickets: Ticket[] = [];
  showInnerTable: boolean[] = [];
  isOuterTableOpened = true;

  constructor(public dateService: DateService) { }


  ngOnInit(): void {
    this.filterTicketsByType();
  }

  filterTicketsByType() {
    this.tickets = this.order.tickets.filter(ticket => !ticket?.remark || !ticket?.remark?.includes('BWC'));
  }

  toggleInnerTable(index: number): void {
    this.showInnerTable[index] = !this.showInnerTable[index];
  }

}
