import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NDCApiService} from "../../shared/services/ndc-api.service";
import {PostOrderDateChangeModel} from "../../shared/models/post-order-date-change.model";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {ErrorAlert} from "../../shared/models/error-alert";
import {HelpersService} from "../../shared/services/helpers.service";
import {Process} from "../../shared/interfaces/show-process";
import {convertPhoneIntoString} from "../../shared/adapters/v1_2/adapters";
import {OfferService} from "../../shared/services/offer.service";
import {SentryService} from "../../shared/services/sentry.service";

@Component({
  selector: 'app-cabin-upgrade-modal',
  templateUrl: './cabin-upgrade-modal.component.html',
  styleUrls: ['./cabin-upgrade-modal.component.scss']
})
export class CabinUpgradeModalComponent implements OnInit {

  @Input() order;
  @Input() originDestinationsData = [];
  @Output() emitChangeSuccess = new EventEmitter();
  @Output() emitClose = new EventEmitter();

  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  showOrderReshopLoader: boolean;
  orderReshopError: ErrorAlert = new ErrorAlert();
  orderChangeError: ErrorAlert = new ErrorAlert();
  orderReshopRepriceError: ErrorAlert = new ErrorAlert();
  offers: any;
  desireOffer;
  step = 1;
  pingProceed = 0;
  proceedBtnIsAvailable: boolean;
  changeOrderProcess: Process = {
    isProcess: false,
    processTitle: 'Order rebooking...'
  };
  orderReshopRepriceProcess: Process = {
    isProcess: false,
    processTitle: 'Order repricing...'
  };


  constructor(private ndcApiService: NDCApiService,
              private helpers: HelpersService,
              private offerService: OfferService,
              private sentryService: SentryService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.desireOffer = this.order;
    this.getOffersForReshop();
  }

  getOffersForReshop() {
    this.showOrderReshopLoader = true;
    const options = this.originDestinationsData.map(od => 'upgrade');
    const itinReshopSendData: PostOrderDateChangeModel = {
      id: this.order.id,
      originDestinations: []
    };
    itinReshopSendData.originDestinations = this.originDestinationsData.map((item, index) => {
      return {
        departure: {
          airportCode: item.departure,
          terminalName: '',
          date: item.departureDate,
          time: '',
        },
        arrival: {
          airportCode: item.arrival,
          terminalName: '',
          date: item.departureDate,
          time: '',
        },
      };
    });
    this.ndcApiService.sendOrderReshop(itinReshopSendData)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        this.showOrderReshopLoader = false;
        this.offers = this.offerService.getOffersAndFlightIndexes(response.offers, this.order, options).offers;
      }, (error) => {
        this.showOrderReshopLoader = false;
        this.sentryService.setAdditionalData(this.ndcApiService.lastSessionID, this.ndcApiService.lastRequestID, itinReshopSendData, error.status, error);
        this.orderReshopError = this.helpers.getError(error);
        if (this.helpers.isCriticalError(error)) {
          throw error;
        }
      });
  }

  onSelectedOffer(selectedOfferData: { offerID: string, isOpenNewWindow: boolean }) {
    this.desireOffer = this.offers.find(offer => offer.offerID === selectedOfferData.offerID);
    this.proceedBtnIsAvailable = !!this.desireOffer;
  }

  onEmitFormData(event) {
    if (event.isValid) {
      const body = {
        id: this.order.id,
        payment: event.formData,
        reshopOfferID: this.desireOffer.offerID
      };
      if (body.payment && body.payment.phone) {
        body.payment.phone = convertPhoneIntoString(body.payment.phone);
      }
      this.changeOrderProcess.isProcess = true;
      this.cdr.detectChanges();
      this.ndcApiService.sendOrderChange(body, false)
        .then(response => {
          this.changeOrderProcess.isProcess = false;
          this.emitChangeSuccess.emit();
          this.step = 4;
        }).catch((error) => {
        this.changeOrderProcess.isProcess = false;
        this.orderChangeError = this.helpers.getError(error);
        this.sentryService.setAdditionalData(this.ndcApiService.lastSessionID, this.ndcApiService.lastRequestID, body, error.status, error);
        if (this.helpers.isCriticalError(error)) {
          throw error;
        }
      });
    }
  }

  proceedAction() {
    const body = {
      id: this.order.id,
      offerId: this.desireOffer.offerID
    };
    this.orderReshopRepriceProcess.isProcess = true;

    this.ndcApiService.sendOrderReshopReprice(body)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(response => {
        this.orderReshopRepriceProcess.isProcess = false;
        this.desireOffer = response.body;
        this.order.allowedPaymentMethods.none = !!this.order.allowedRequests.ticketed.RebookingOnHold;
        this.desireOffer.allowedPaymentMethods = this.order.allowedPaymentMethods;
        this.step = 2;
        this.sentryService.owner = this.desireOffer.owner;
      }, (error => {
        this.orderReshopRepriceProcess.isProcess = false;
        this.orderReshopRepriceError = this.helpers.getError(error);
        this.sentryService.setAdditionalData(this.ndcApiService.lastSessionID, this.ndcApiService.lastRequestID, body, error.status, error);
        if (this.helpers.isCriticalError(error)) {
          throw error;
        }
      }));
  }

}
