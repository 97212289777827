import { Pipe, PipeTransform } from '@angular/core';
import {Ticket} from "../interfaces/ticket";

@Pipe({
  name: 'ticketsByTravRef'
})
export class TicketsByTravRefPipe implements PipeTransform {

  transform(tickets: Ticket[], travelerRef: string): Ticket[] | null {
    if (!tickets) {
      return null;
    }

    return tickets.filter((tkt) => tkt.passengerRef === travelerRef);
  }
}
