import {
  AfterViewChecked,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  Provider, Renderer2,
  ViewChild
} from '@angular/core';
import {OfferGridComponent} from '../shared/components/offer-grid/offer-grid.component';
import {Dictionary} from '../shared/types/dictionary';
import {NgbDateParserFormatter, NgbDropdownConfig,} from '@ng-bootstrap/ng-bootstrap';
import {NDCApiService} from '../shared/services/ndc-api.service';
import {FormBuilder} from '@angular/forms';
import {HelpersService} from '../shared/services/helpers.service';
import {LocalStorageService} from '../shared/services/local-storage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Options} from '@angular-slider/ngx-slider';
import {AirGatewayDateFormatter} from '../shared/services/air-gateway-date-formatter.service';
import {URL_PARAMS} from '../shared/constants';
import {DataTransferService} from '../shared/services/data-transfer.service';
import {AuthService} from '../shared/services/auth.service';
import {ALL_RESET_KEYS_OBJ, OFFER_TAB} from './constants';
import moment from 'moment';
import {NotificationService} from '../shared/services/notification.service';
import {UmbrellaService} from '../shared/services/umbrella.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {OfferService} from '../shared/services/offer.service';
import {FilterLoaderService} from '../shared/services/filter-loader.service';
import {fareRulesToNumberMap} from '../shared/types/helper';
import {GetAirlinePipe} from "../shared/pipes/get-airline.pipe";
import {
  collapseAnimation,
  rotateAnimation,
} from "angular-animations";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    NgbDropdownConfig,
    <Provider>{
      provide: NgbDateParserFormatter,
      useClass: AirGatewayDateFormatter,
    },
  ],
  animations: [
    rotateAnimation({ degrees: -180, duration: 500 }),
    collapseAnimation()
  ]
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild(OfferGridComponent) child: OfferGridComponent;

  searchTypeOptions = Dictionary.getSearchTypeOptions();
  searchObj: any;
  OFFER_TAB = OFFER_TAB;
  tempOffers: null | any[];
  paginatedOffers = [];
  page = 1;
  pageSize = 10;
  sortStrategy = 'price_asc';
  isNewSearch = false;
  durationSliderOptions: Options[] = [];
  sidebarFilter: any = {
    maxStops: 10,
    timeRanges: {},
    paymentTimeLimitRanges: {},
    connections: {},
    connectionValues: {},
    connectionValueAfterSelect: {},
    durations: [],
    durationsTouched: [],
    flightNumbers: {},
    flightNumbersSelected: {},
    airlines: {}
  };
  sidebarFilterData: any = {
    connections: {},
    connectionNames: {},
    durations: [],
    durationNames: [],
  };
  sidebarIsShowResetButton: any = {
    maxStops: false,
    connections: false,
    airports: false,
    flightNumbers: false,
    paymentTimeLimit: false,
    flightDuration: false,
    priceRange: false,
    airlines: false
  };
  isShowResetTimeRangesButton = false;
  countSelectedSidebarFilters = 0;
  sidebarFilteredOffers = [];

  selectedOffers = [];

  sortStrategyOptions = {
    'price_asc': 'Lowest Price',
    'first_flight_dep': 'Earliest departure',
    'last_flight_dep': 'Latest departure',
    'first_flight_arr': 'Earliest arrival',
    'last_flight_arr': 'Latest arrival',
    'shorter_time': 'Shorter time',
  };

  fixedOriginDestinationArr = [];
  fixedOriginDestinationSegments: string;
  checkObject: any;
  offersForFilter = [];
  comingOffersLength = 0;
  cabinTypes = Dictionary.CabinTypes;
  urlParams = URL_PARAMS;
  offersCount = 0;

  hideCodeshares = false;
  checkedBaggage = '';
  refundableFares = 'light';

  isShowMainLoader;

  isOfferFiltersTouched = false;
  offersLengthBeforeTouch = 0;
  isAirlinesNeedChanges = true;

  newOffersCount = 0;
  activeOffersTab = OFFER_TAB.PER_LEG;
  public collapsedOptions = {
    price: true,
    paymentTimeLimit: true,
    maxStops: true,
    duration: true,
    airports: true,
    flightNumbers: true,
    connections: true,
    airlines: true
  };
  connectionsSimpleModel;
  connectionPrices = {};
  connectionsBlackList = {};
  connectionsWhiteList = {};

  sidebarPrices = {
    allPrices: {
      all: [],
      currency: [],
      minPrice: 0,
      minPriceValue: 0,
      maxPrice: 0,
      maxPriceValue: 0,
    },
  };

  airportsInfo;

  isChangePriceRange = true;
  durationsExtended;
  gridModel: any = {
    offers: [],
  };
  fareRules: string;
  isDirectFlightsIncluded = true;
  isDirectFlightsOnly = false;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  isFirstHandleGridEvent = true;
  offers: any[];

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    let pos =
      (document.documentElement.scrollTop || document.body.scrollTop) +
      document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
    if (pos > max - 15 && this.activeOffersTab === OFFER_TAB.FULL_TRIP) {
      this.loadMoreOffers();
    }
  }

  constructor(
    public helpers: HelpersService,
    public ls: LocalStorageService,
    private _notificationSvc: NotificationService,
    private service: NDCApiService,
    private dataTransferService: DataTransferService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private router: Router,
    private applicationRef: ApplicationRef,
    private authService: AuthService,
    private route: ActivatedRoute,
    config: NgbDropdownConfig,
    private umbrellaService: UmbrellaService,
    private offersService: OfferService,
    private filterService: FilterLoaderService,
    private getAirlinePipe: GetAirlinePipe,
    private renderer: Renderer2
  ) {
    config.autoClose = 'outside';
    this.renderer.addClass(document.body, 'bg-white');
  }

  ngOnInit() {
    this.getOffers();
    this.searchObj = {};
    this.service.price = {};

    this.extractFixedODFromUrl();

    if (this.ls.profilesType === 'umbrella') {
      this.umbrellaService
        .checkUmbrellaIsAuth()
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe();
    }

    const collapsedOptionsFromLs = !!this.ls.collapseOptions ? JSON.parse(this.ls.collapseOptions) : null;
    if (collapsedOptionsFromLs && typeof collapsedOptionsFromLs === 'object') {
      this.collapsedOptions = collapsedOptionsFromLs;
    } else {
      this.ls.collapseOptions = JSON.stringify(this.collapsedOptions);
    }

    this.getMaxStops();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    this.renderer.removeClass(document.body, 'bg-white');
  }

  getOffers() {
    this.offers = [];
    this.offersService.getOffers().subscribe(offers => {
      if (this.searchObj && Object.keys(this.searchObj).length && offers && offers.length) {
        this.offers = [...offers];
        this.onBuildDynamicSidebar(this.offers);
      }
    });
  }

  onResetSidebarFilter(event) {
    this.offers = [];
    this.searchObj = event;
    this.setFlightNumberValues(event);

    this.paginatedOffers = [];
    this.isFirstHandleGridEvent = true;
  }

  onBuildStaticSidebar(event) {
    this.searchObj = event;
    this.buildStaticSidebar();
  }

  onBuildDynamicSidebar(offers) {
    if (this.isOfferFiltersTouched) {
      if (this.offersLengthBeforeTouch && offers.length !== this.offersLengthBeforeTouch) {
        this.newOffersCount = offers.length - this.offersLengthBeforeTouch;
      }
      this.tempOffers = offers;
      if (this.route.snapshot.queryParams.selected_table_cells === 'ALL') {
        this.onRefreshOffers();
      }
    } else {
      this.onBuildDynamicSidebarInner();
    }
  }

  loadMoreOffers() {
    if (this.offersCount > this.pageSize) {
      this.refreshPaginatedOffers('scroll');
    }
  }

  onRefreshOffers() {
    if (this.tempOffers) {
      this.offers = this.tempOffers;
    }
    this.tempOffers = null;
    this.offersLengthBeforeTouch = this.offers.length;
    this.newOffersCount = 0;
    this.onBuildDynamicSidebarInner();
  }

  private onBuildDynamicSidebarInner() {
    if (this.offers.length && this.comingOffersLength !== this.offers.length) {
      this.offersForFilter = this.offers;
      this.buildDynamicSidebar();
      this.extractValuesFromUrl();
      this.applySidebarFilter();
      this.comingOffersLength = this.offers.length;
    }
  }

  private initDepartureArrivalTimes() {
    let originDestinations = this.searchObj.originDestinations;
    let timeRanges = {};
    originDestinations.map((od, i) => {
      timeRanges[i] = {
        depAirportCode: od.departure.airportCode,
        arrAirportCode: od.arrival.airportCode,
        departure: {
          minValue: 0,
          maxValue: 0,
          min: 0,
          max: 0,
          allTimes: [],
        },
        arrival: {
          minValue: 0,
          maxValue: 0,
          min: 0,
          max: 0,
          allTimes: [],
        },
      };
    });
    return timeRanges;
  }

  buildStaticSidebar() {
    let form = this.searchObj.form;
    const params = {...this.route.snapshot.queryParams};

    switch (form.type.toString()) {
      case Dictionary.FLIGHT_TYPE_ONE_WAY.toString():
        this.activeOffersTab = OFFER_TAB.FULL_TRIP;
        break;
      case Dictionary.FLIGHT_TYPE_ROUND_TRIP.toString():
        this.activeOffersTab = params['segment'] === 'full_trip' ? OFFER_TAB.FULL_TRIP : OFFER_TAB.PER_LEG;
        break;
      case Dictionary.FLIGHT_TYPE_MULTI_CITY.toString():
        this.activeOffersTab = OFFER_TAB.PER_LEG;
    }
  }

  private extractInfoForShowAirportsInSidebar(
    originDestinations,
    flightIdx,
    offer,
    flight
  ) {
    let route =
      originDestinations[flightIdx].departure.airportCode +
      originDestinations[flightIdx].arrival.airportCode;
    const cityCodes = Object.keys(Dictionary.getMetroCodeAirportMapping());
    const depAirportCode = originDestinations[flightIdx].departure.airportCode;
    const arrAirportCode = originDestinations[flightIdx].arrival.airportCode;
    const offerDepAirportCode = flight.departure.airportCode;
    const offerArrAirportCode = flight.arrival.airportCode;
    const totalPrice = offer.price.consumer.total;
    if (!this.airportsInfo) {
      this.airportsInfo = {};
    }
    if (this.airportsInfo && !this.airportsInfo[route]) {
      this.airportsInfo[route] = {
        depAirportCode: depAirportCode,
        arrAirportCode: arrAirportCode,
        departure: {
          airportCodes: [],
          airportMap: {},
          prices: {},
        },
        arrival: {
          airportCodes: [],
          airportMap: {},
          prices: {},
        },
      };
    }

    if (this.airportsInfo && this.airportsInfo[route]) {
      if (
        !this.airportsInfo[route].departure.airportCodes.includes(
          offerDepAirportCode
        )
      ) {
        this.airportsInfo[route].departure.airportCodes.push(
          offerDepAirportCode
        );
        this.airportsInfo[route].departure.airportMap[
          offerDepAirportCode
          ] = true; // isCityCodeDep || offerDepAirportCode === depAirportCode;
        this.airportsInfo[route].departure.prices[offerDepAirportCode] = {
          all: [],
          min: 0,
        };
      }

      if (
        !this.airportsInfo[route].departure.prices[
          offerDepAirportCode
          ].all.includes(totalPrice)
      ) {
        this.airportsInfo[route].departure.prices[offerDepAirportCode].all.push(
          totalPrice
        );
        this.airportsInfo[route].departure.prices[
          offerDepAirportCode
          ].min = Math.min(
          ...this.airportsInfo[route].departure.prices[offerDepAirportCode].all
        );
      }

      if (
        !this.airportsInfo[route].arrival.airportCodes.includes(
          offerArrAirportCode
        )
      ) {
        this.airportsInfo[route].arrival.airportCodes.push(offerArrAirportCode);
        this.airportsInfo[route].arrival.airportMap[offerArrAirportCode] = true; // isCityCodeArr || offerArrAirportCode === arrAirportCode;
        this.airportsInfo[route].arrival.prices[offerArrAirportCode] = {
          all: [],
          min: 0,
        };
      }

      if (
        !this.airportsInfo[route].arrival.prices[
          offerArrAirportCode
          ].all.includes(totalPrice)
      ) {
        this.airportsInfo[route].arrival.prices[offerArrAirportCode].all.push(
          totalPrice
        );
        this.airportsInfo[route].arrival.prices[
          offerArrAirportCode
          ].min = Math.min(
          ...this.airportsInfo[route].arrival.prices[offerArrAirportCode].all
        );
      }
    }
  }

  buildDynamicSidebar() {
    let connections = {};
    let names = {};
    let items = {};
    let originDestinations = this.searchObj.originDestinations;
    let durations = [];
    this.durationsExtended = {};
    let durationNames = [];
    let durationSliderOptions = [];
    let timeRange = {};
    if (originDestinations) {
      timeRange = this.initDepartureArrivalTimes();
    }
    let paymentTimeLimitRange = {
      min: 0,
      max: 0,
      value: 0,
      allTimes: [],
    };
    this.connectionPrices = {};
    this.fillFlightNumberIfNeed();
    this.airportsInfo = null;

    this.sidebarPrices.allPrices.all = [];
    this.sidebarPrices.allPrices.currency = [];

    let unix = moment().unix();
    let day = 60 * 60 * 24;
    let today = unix - (unix % day);

    this.offers.map((offer) => {
      if (offer.paymentTimeLimit) {
        let unix = moment(offer.paymentTimeLimit).utc().unix();
        let v = unix - (unix % day);

        paymentTimeLimitRange.allTimes.push({time: v});
      } else {
        paymentTimeLimitRange.allTimes.push({time: today});
      }

      if (offer.flights && this.searchObj.originDestinations.length >= offer.flights.length) {
        // fill flight numbers list for each flight
        this.buildSidebarFlightNumbers(offer.flights);

        offer.flights.map((flight, i) => {
          const arrivalTime = flight.arrival['time'].split(':');
          const departureTime = flight.departure['time'].split(':');

          const arrivalTimeInMinutes = +arrivalTime[0] * 60 + +arrivalTime[1];
          const departureTimeInMinutes =
            +departureTime[0] * 60 + +departureTime[1];

          if (timeRange[i] && timeRange[i].arrival && timeRange[i].arrival.allTimes) {
            timeRange[i].arrival.allTimes.push({
              time: arrivalTimeInMinutes,
              price: offer.price.consumer.total,
              currency: offer.price.consumer.currency
            });
          }
          if (
            timeRange[i] &&
            timeRange[i].departure &&
            timeRange[i].departure.allTimes
          ) {
            timeRange[i].departure.allTimes.push({
              time: departureTimeInMinutes,
              price: offer.price.consumer.total,
              currency: offer.price.consumer.currency
            });
          }

          let route =
            originDestinations[i].departure.airportCode +
            originDestinations[i].arrival.airportCode;
          let name =
            originDestinations[i].departure.airportCode +
            ' - ' +
            originDestinations[i].arrival.airportCode;
          if (items[route] === undefined) {
            items[route] = [];
            connections[route] = [];
            this.sidebarFilter.connections[route] = [];
          }

          // extract airports for each o&d

          this.extractInfoForShowAirportsInSidebar(
            originDestinations,
            i,
            offer,
            flight
          );

          if (flight.segments.length > 1) {
            flight.segments.map(fs => {
              [
                fs.originDestination.departure.airportCode,
                fs.originDestination.arrival.airportCode,
              ].map((code) => {
                if (
                  code !== flight.departure.airportCode &&
                  code !== flight.arrival.airportCode
                ) {
                  if (this.connectionPrices[route]) {
                    if (this.connectionPrices[route][code]) {
                      if (this.connectionPrices[route][code].price.indexOf(offer.price.consumer.total) === -1) {
                        this.connectionPrices[route][code].price.push(offer.price.consumer.total);
                      }
                      if (this.connectionPrices[route][code].currency.indexOf(offer.price.consumer.currency) === -1) {
                        this.connectionPrices[route][code].currency.push(offer.price.consumer.currency);
                      }
                    } else {
                      this.connectionPrices[route][code] = {
                        price: [offer.price.consumer.total],
                        currency: [offer.price.consumer.currency]
                      };
                    }
                  } else {
                    this.connectionPrices[route] = {};
                    this.connectionPrices[route][code] = {
                      price: [offer.price.consumer.total],
                      currency: [offer.price.consumer.currency]
                    };
                  }
                }
                if (
                  code !== flight.departure.airportCode &&
                  code !== flight.arrival.airportCode &&
                  items[route].indexOf(code) === -1
                ) {
                  items[route].push(code);
                  this.sidebarFilter.connections[route].push(code);
                }
              });
            });
          }
          names[route] = name;

          if (durationNames.indexOf(name) === -1) {
            durations.push({
              min: 9999,
              max: 0,
            });
            if (this.sidebarFilter.durationsTouched.length - 1 < i) {
              this.sidebarFilter.durationsTouched[i] = false;
            }
            if (!this.sidebarFilter.durationsTouched[i]) {
              this.sidebarFilter.durations[i] = 0;
            }
            durationNames.push(name);
          }
          let duration = 0;
          if (flight.duration) {
            duration = moment.duration(flight.duration).asMinutes();
          }

          const durationItem = {
            price: offer.price.consumer.total,
            currency: offer.price.consumer.currency,
            time: duration
          };
          if (this.durationsExtended[i]) {
            this.durationsExtended[i].push(durationItem);
          } else {
            this.durationsExtended[i] = [durationItem];
          }

          if (durations[i] && duration < durations[i].min) {
            durations[i].min = duration;
          }
          if (durations[i] && duration > durations[i].max) {
            durations[i].max = duration;
            if (!this.sidebarFilter.durationsTouched[i]) {
              this.sidebarFilter.durations[i] = duration;
            }
          }
        });

      }

      this.extractPrices(offer);
    });

    Object.keys(this.sidebarFilter.flightNumbers).map(k => {
      this.sidebarFilter.flightNumbers[k].flightNumbers.sort()
    })

    /** set min/max prices */
    this.sidebarPrices.allPrices.minPrice = Math.floor(
      Math.min(...this.sidebarPrices.allPrices.all)
    );
    this.sidebarPrices.allPrices.maxPrice = Math.ceil(
      Math.max(...this.sidebarPrices.allPrices.all)
    );

    if (this.isChangePriceRange) {
      this.sidebarPrices.allPrices.maxPriceValue = this.sidebarPrices.allPrices.maxPrice;
      this.sidebarPrices.allPrices.minPriceValue = this.sidebarPrices.allPrices.minPrice;
    }
    this.sidebarPrices.allPrices = {...this.sidebarPrices.allPrices};

    Object.keys(this.connectionPrices).map((route) => {
      Object.keys(this.connectionPrices[route]).map((connection) => {
        this.connectionPrices[route][connection].price = Math.min(
          ...this.connectionPrices[route][connection].price
        );
        this.connectionPrices[route][
          connection
          ].currency = this.connectionPrices[route][connection].currency.join(
          '/'
        );
      });
    });

    paymentTimeLimitRange.max = Math.max(
      ...paymentTimeLimitRange.allTimes.map((item) => item.time)
    );
    paymentTimeLimitRange.min = Math.min(
      ...paymentTimeLimitRange.allTimes.map((item) => item.time)
    );
    paymentTimeLimitRange.value = paymentTimeLimitRange.min;

    Object.keys(timeRange).map((key, index) => {
      timeRange[key].arrival.min = Math.min(
        ...timeRange[key].arrival.allTimes.map((item) => item.time)
      );
      timeRange[key].arrival.max = Math.max(
        ...timeRange[key].arrival.allTimes.map((item) => item.time)
      );
      timeRange[key].arrival.minValue = timeRange[key].arrival.min;
      timeRange[key].arrival.maxValue = timeRange[key].arrival.max;

      timeRange[key].departure.min = Math.min(
        ...timeRange[key].departure.allTimes.map((item) => item.time)
      );
      timeRange[key].departure.max = Math.max(
        ...timeRange[key].departure.allTimes.map((item) => item.time)
      );

      const selectedTime = this.searchObj.selectedTime[index];
      if (selectedTime !== undefined && !isNaN(selectedTime)) {
        timeRange[key].departure.minValue = Math.max(0, selectedTime - 120);
        timeRange[key].departure.maxValue = Math.min(23 * 60 + 59, selectedTime + 120);
      } else {
        timeRange[key].departure.minValue = timeRange[key].departure.min;
        timeRange[key].departure.maxValue = timeRange[key].departure.max;
      }
    });
    this.sidebarFilter.timeRanges = {...timeRange};
    this.sidebarFilter.paymentTimeLimitRanges = {
      ...paymentTimeLimitRange,
    };
    this.sidebarFilter.connectionValues = {
      ...this.sidebarFilter.connections,
    };

    this.connectionsBlackList = {};
    Object.keys(this.connectionsWhiteList).map((item) => {
      this.connectionsBlackList[item] = [];
    });

    this.extractConnectionListsFromUrl();

    durations.map((dur) => {
      durationSliderOptions.push({
        floor: dur.min,
        step: 30,
        ceil: dur.max,
        showSelectionBar: true,
        // minRange: 2,
        // pushRange: true,
        translate: (value: number): string => {
          if (isNaN(value)) {
            return '00:00';
          }
          let minutes = value % 60;
          let hours = Math.floor(value / 60);
          return (
            hours.toString().padStart(2, '0') +
            ':' +
            minutes.toString().padStart(2, '0') +
            'h'
          );
        },
      });
    });

    let itemKeys = Object.keys(items);
    for (let idx in itemKeys) {
      let route = itemKeys[idx];
      items[route] = items[route].sort((a, b) => {
        return a > b;
      });
      items[route].map((item) => {
        connections[route].push({id: item});
      });
    }
    this.sidebarFilterData.connections = connections;
    this.sidebarFilterData.connectionNames = names;
    this.sidebarFilterData.durations = durations;
    this.sidebarFilterData.durationNames = durationNames;
    this.durationSliderOptions = durationSliderOptions;

    // reset max flight durations
    this.clearSidebarFlightDuration();

    this.extractMaxDurationFromUrl();
    this.extractAirportsFromUrl();

  }

  applyFlightNumberFilter(offer) {
    let flag = true;

    const selectedFlightNumbers = [];
    offer.flights.map((flight, flightIdx) => {
      flight.segments.map(segment => {
        selectedFlightNumbers.push(segment.flightNumber);
      });

      this.sidebarFilter.flightNumbersSelected[flightIdx]?.map((item) => {
        if (selectedFlightNumbers.indexOf(item) === -1) {
          flag = false;
          return;
        }
      });
    });

    return flag;
  }

  applyAirlinesFilter(operatingCarriers) {
    let skipOffer = false;
    const uniqueArr = Array.from(new Set(operatingCarriers));
    uniqueArr.map((airline: string) => {
      if (this.sidebarFilter.airlines[airline] === false) {
        skipOffer = true;
      }
    });
    return skipOffer;
  }

  buildSidebarFlightNumbers(flights) {
    flights.map((f, i) => {
      const route = `${this.searchObj.originDestinations[i].departure.airportCode}-${this.searchObj.originDestinations[i].arrival.airportCode}`;
      let updateRoute = false;

      if (this.sidebarFilter.flightNumbers[i]?.route !== route) {
        updateRoute = true;
      }
      f.segments.map(fs => {
        if (!this.sidebarFilter.flightNumbers[i]) {
          this.sidebarFilter.flightNumbers[i] = {
            route: route,
            flightNumbers: [],
          };
        } else if (updateRoute) {
          this.sidebarFilter.flightNumbers[i].route = route;
        }

        if (this.sidebarFilter.flightNumbers[i].flightNumbers.indexOf(fs.flightNumber) === -1) {
          this.sidebarFilter.flightNumbers[i].flightNumbers.push(fs.flightNumber);
        }
      });
    });
  }

  onAirlinesChange($event, airlineKey, only = false) {
    this.isAirlinesNeedChanges = false;
    if (only) {
      Object.keys(this.sidebarFilter.airlines).forEach(key => this.sidebarFilter.airlines[key] = airlineKey === key);
    } else {
      this.sidebarFilter.airlines[airlineKey] = $event.target.checked;
    }
    this.sidebarIsShowResetButton.airlines = Object.values(this.sidebarFilter.airlines).some(value => value === false);
    setTimeout(() => this.applySidebarFilter(), 0);
  }

  clearSidebarAirlines() {
    Object.keys(this.sidebarFilter.airlines).forEach(key => this.sidebarFilter.airlines[key] = true);
    this.sidebarIsShowResetButton.airlines = false;
  }

  resetAirlines() {
    this.clearSidebarAirlines();
    this.applySidebarFilter();
  }

  onSelectFlightNumber($event, index) {
    this.filterService.loadStart();
    this.markFiltersTouched();
    let params = {...this.route.snapshot.queryParams};
    params[`origin_destinations_flightnumbers[${index}]`] = $event.value.join(
      ','
    );
    this.searchObj.selectedFlightNumberArr = $event.value;

    this.sidebarFilter.flightNumbersSelected[index] = $event.value;
    let temp = false;
    for (const key in this.sidebarFilter.flightNumbersSelected) {
      if (this.sidebarFilter.flightNumbersSelected[key].length > 0) {
        temp = true;
        break;
      }
    }
    this.sidebarIsShowResetButton.flightNumbers = temp;

    this.router.navigate([], {queryParams: params});
    setTimeout(() => this.applySidebarFilter(), 0);
  }

  clearSidebarFlightNumbers() {
    Object.keys(this.sidebarFilter.flightNumbersSelected).map(
      (item) => (this.sidebarFilter.flightNumbersSelected[item] = [])
    );
    this.sidebarIsShowResetButton.flightNumbers = false;
  }

  resetSelectedFlightNumbers() {
    this.clearSidebarFlightNumbers();
    let params = {...this.route.snapshot.queryParams};
    Object.keys(params).forEach(key => {
      if (key.startsWith("origin_destinations_flightnumbers")) {
        delete params[key];
      }
    });
    this.applySidebarFilter();
  }

  onAirportsChange() {
    this.filterService.loadStart();
    this.markFiltersTouched();
    let params = {...this.route.snapshot.queryParams};
    let isAirportsChanged = false;

    Object.keys(this.airportsInfo).map((route, index) => {
      const depAirports = [];
      const arrAirports = [];
      const info = this.airportsInfo[route];
      Object.keys(info.departure.airportMap).map((code) => {
        if (info.departure.airportMap[code]) {
          depAirports.push(code);
        }
      });

      Object.keys(info.arrival.airportMap).map((code) => {
        if (info.arrival.airportMap[code]) {
          arrAirports.push(code);
        }
      });

      if (!isAirportsChanged) {
        isAirportsChanged = Object.values(info.departure.airportMap).some(value => value === false) ||
               Object.values(info.arrival.airportMap).some(value => value === false);
      }

      params[`departure_airports[${index}]`] = depAirports.join(',');
      params[`arrival_airports[${index}]`] = arrAirports.join(',');
    });

    this.sidebarIsShowResetButton.airports = isAirportsChanged;

    this.router.navigate([], {queryParams: params});
    setTimeout(() => this.applySidebarFilter(), 0);
  }

  clearSidebarAirports() {
    if (this.airportsInfo) {
      Object.keys(this.airportsInfo).map((route, index) => {
        const info = this.airportsInfo[route];
        for (let key in info.departure.airportMap) {
          info.departure.airportMap[key] = true;
        }
        for (let key in info.arrival.airportMap) {
          info.arrival.airportMap[key] = true;
        }
      });
    }
    this.sidebarIsShowResetButton.airports = false;
  }

  resetAirports() {
    this.clearSidebarAirports();
    this.applySidebarFilter();
  }

  applySidebarFilter(event = null) {
    if (event && event.offer) {
      this.setToOriginDestinationArr(event);
      this.fixedOriginDestinationSegments = this.getFixedSegments(event.offer);
      this.setFixedODToUrl();
      this.page = 1;
    }

    this.sidebarFilter.connectionValueAfterSelect = {
      ...this.sidebarFilter.connectionValues,
    };

    let unix = moment().unix();
    let day = 60 * 60 * 24;
    let today = unix - (unix % day);

    const fareRulesFilter = this.fareRules ? this.fareRules.split('|') : '';
    this.sidebarFilteredOffers = this.offersForFilter.filter((offer) => {
      // price range filter
      if (offer.price.consumer.total < this.sidebarPrices.allPrices.minPriceValue ||
        offer.price.consumer.total > this.sidebarPrices.allPrices.maxPriceValue) {
        return false;
      }

      let paymentTimeLimitUnix = 0;
      if (offer.paymentTimeLimit) {
        paymentTimeLimitUnix = moment(offer.paymentTimeLimit).unix();
      }
      if (
        this.sidebarFilter.paymentTimeLimitRanges?.value !== today &&
        this.sidebarFilter.paymentTimeLimitRanges?.value > paymentTimeLimitUnix
      ) {
        return false;
      }

      /*if (offer.flights && !this.applyFareNameFilter(offer)) {
        return false;
      }*/

      if (!this.isDirectFlightsIncluded && offer.flights[0].segments.length <= 1) {
        return false;
      }

      if (this.isDirectFlightsOnly && offer.flights[0].segments.length >= 2) {
        return false;
      }

      if (!this.applyFlightNumberFilter(offer)) {
        return false;
      }

      if (offer.maxNumberOfStops > this.sidebarFilter.maxStops) {
        return false;
      }

      let disclosuresFilterOk = this.offersService.checkDisclosures(offer, this.checkedBaggage, this.refundableFares);
      if (!disclosuresFilterOk) {
        return false;
      }

      if (!this.offersService.checkFareRules(offer, fareRulesToNumberMap, fareRulesFilter)) {
        return false;
      }

      let isFitTimeRanges = true;
      let isFitDuration = true;
      const allOfferClasses = [];
      const airportConditionsArray = [];
      if (offer.flights && this.searchObj.originDestinations.length >= offer.flights.length) {
        const operatingCarriers = [];
        offer.flights.map((flight, idx) => {
          if (this.airportsInfo) {
            const od =
              this.searchObj.originDestinations[idx].departure.airportCode +
              this.searchObj.originDestinations[idx].arrival.airportCode;
            if (this.airportsInfo[od]) {
              airportConditionsArray.push(
                this.airportsInfo[od].departure.airportMap[
                  flight.departure.airportCode
                  ] &&
                this.airportsInfo[od].arrival.airportMap[
                  flight.arrival.airportCode
                  ]
              );
            }
          }

          flight.segments.map(seg => {
            operatingCarriers.push(seg.operatingCarrier.airlineID);
            if (seg.detail && seg.detail.classOfService && seg.detail.classOfService.cabinDesignator) {
              allOfferClasses.push(seg.detail.classOfService.cabinDesignator);
            }
          });

          const arrivalTime = flight.arrival['time'].split(':');
          const departureTime = flight.departure['time'].split(':');
          const arrivalTimeInMinutes = +arrivalTime[0] * 60 + +arrivalTime[1];
          const departureTimeInMinutes =
            +departureTime[0] * 60 + +departureTime[1];

          if (this.sidebarFilter.timeRanges && this.sidebarFilter.timeRanges[idx]) {
            if (
              departureTimeInMinutes <
              this.sidebarFilter.timeRanges[idx].departure.minValue ||
              departureTimeInMinutes >
              this.sidebarFilter.timeRanges[idx].departure.maxValue
            ) {
              isFitTimeRanges = false;
            }
            if (
              arrivalTimeInMinutes <
              this.sidebarFilter.timeRanges[idx].arrival.minValue ||
              arrivalTimeInMinutes >
              this.sidebarFilter.timeRanges[idx].arrival.maxValue
            ) {
              isFitTimeRanges = false;
            }
          }

          let duration = 0;
          if (flight.duration) {
            duration = moment.duration(flight.duration).asMinutes();
          }

          if (this.sidebarFilter.durations[idx] + 1 < duration) {
            isFitDuration = false;
          }
        });

        /** filter by airlines **/
        if (this.applyAirlinesFilter(operatingCarriers)) {
          return false;
        }

        /** hide codeshares **/
        if (this.hideCodeshares && !['AER', '1G'].includes(offer.owner)) {
          const uniqueArr = Array.from(new Set(operatingCarriers));
          const sameAirlines = Dictionary.getArrOfSameAirlines(this.getAirlinePipe.transform(offer.owner));
          let skipOffer = false;
          uniqueArr.map((airline) => {
            if (!sameAirlines.includes(airline)) {
              skipOffer = true;
            }
          });
          if (skipOffer) return false;
        }
        if (!isFitTimeRanges) {
          return false;
        }
        if (!isFitDuration) {
          return false;
        }

        const uniqueAirportConditions = Array.from(
          new Set(airportConditionsArray)
        );
        if (
          !(
            uniqueAirportConditions.length &&
            uniqueAirportConditions.length === 1 &&
            uniqueAirportConditions[0]
          )
        ) {
          return false;
        }

        let originDestinations = this.searchObj.originDestinations;

        const topCodes = [];
        offer.flights.map((flight, flightIdx) => {
          const codes = [];
          let skip2 = true;
          let route = originDestinations[flightIdx].departure.airportCode + originDestinations[flightIdx].arrival.airportCode;
          if (flight.segments.length === 1) {
            topCodes.push(false);
          } else {
            flight.segments.map((seg, segIdx) => {
              if (segIdx !== flight.segments.length - 1) {
                codes.push(seg.originDestination.arrival.airportCode);
              }
            });
            if (this.sidebarFilter.connectionValues[route]) {
              this.sidebarFilter.connectionValues[route].map((item) => {
                if (codes.includes(item)) {
                  skip2 = false;
                }
              });
            }
            topCodes.push(skip2);
          }
        });
        const temp = Array.from(new Set(topCodes));
        if (!(temp.length === 1 && !temp[0])) {
          return false;
        }

        return true;
      }
    });

    if (this.fixedOriginDestinationArr.length) {
      this.sidebarFilteredOffers = this.compareOffersWithFixedOriginDestinations(
        this.sidebarFilteredOffers
      );
    }

    this.connectionsSimpleModel = this.createConnectionsModel();

    this.countSelectedSidebarFilters = Object.values(this.sidebarIsShowResetButton).filter(val => val === true).length;

    this.filterService.loadEnd();
  }

  private createConnectionsModel() {
    const result = {};
    let temp = false;
    Object.keys(this.sidebarFilterData.connections).map((key) => {
      result[key] = {};
      this.sidebarFilterData.connections[key].map((connect) => {
        let value = this.sidebarFilter.connectionValues[key].includes(connect.id);
        result[key][connect.id] = value;
        if (!temp) {
          temp = !value;
        }
      });
    });
    this.sidebarIsShowResetButton.connections = !this.isDirectFlightsIncluded || temp;
    return result;
  }

  updateSearchUrl(key, value) {
    let params = {...this.route.snapshot.queryParams};
    params[key] = value;

    this.router.navigate([], {queryParams: params});
  }

  onSelectedFixedLeg(event) {
    this.applySidebarFilter(event);
  }

  refreshPages() {
    this.page = 1;
  }

  private setFixedODToUrl() {
    let params = {...this.route.snapshot.queryParams};

    let destinationObj = this.fixedOriginDestinationArr.map(
      (item) =>
        `${item.name.replace('originalDestination-', '')}-${item.isChecked}`
    );

    if (
      destinationObj.length &&
      this.fixedOriginDestinationSegments &&
      this.fixedOriginDestinationSegments !== '-'
    ) {
      params['fixed_origin_destinations'] = `${destinationObj.join(',')},${
        this.fixedOriginDestinationSegments
      }`;
    } else if (
      !destinationObj.length ||
      this.fixedOriginDestinationSegments === '-'
    ) {
      params['fixed_origin_destinations'] = undefined;
    }

    this.router.navigate([], {queryParams: params});
  }

  private extractFixedODFromUrl() {
    const params = this.route.snapshot.queryParams;
    this.fixedOriginDestinationArr = [];
    const fixedParams = params['fixed_origin_destinations'];

    if (fixedParams && fixedParams !== undefined) {
      const temp = fixedParams.split(',');
      const segments = temp[temp.length - 1];
      const checkingData = temp.slice(0, temp.length - 1);

      this.fixedOriginDestinationSegments =
        segments && segments !== undefined ? segments : '';

      if (checkingData.length) {
        this.checkObject = {};
        checkingData.map((item) => {
          const temp = item.split('-');

          const itemObj = {
            name: `originalDestination-${temp[0]}`,
            isChecked: temp[1] === 'true',
          };
          this.fixedOriginDestinationArr.push(itemObj);
          this.checkObject[`originalDestination-${temp[0]}`] =
            temp[1] === 'true';
        });
      }
    }
  }

  refreshPaginatedOffers(target: string = null, tab: string = '') {
    this.page = 1;
    this.pageSize = target === 'scroll' ? this.pageSize + 10 : 10;

    let offers = (this.gridModel.offers || []).map(item => this.helpers.recursiveDeepCopy(item));

    offers = this.helpers.sortOffers(
      this.sortStrategy,
      offers,
      this.searchObj.originDestinations
    );

    let activeOffersTab = this.activeOffersTab;
    if (tab) {
      activeOffersTab = tab;
    }
    if (activeOffersTab === OFFER_TAB.FULL_TRIP) {
      offers = this.helpers.groupOffersByFlights(offers);
    } else {
      setTimeout(() => {
        this.selectedOffers = offers;
      }, 0);
    }

    let start = (this.page - 1) * this.pageSize;
    // set timeout adds changes to main event process
    // and will be processed on next view checks  lifecycle
    setTimeout(() => {
      this.offersCount = offers.length;
      this.paginatedOffers = offers.slice(start, start + this.pageSize);
    }, 0);
  }

  changeSortStrategy($event) {
    this.refreshPages();
    setTimeout(() => this.refreshPaginatedOffers(), 0);
  }

  private extractAvailableConnections(route: string) {
    const result = [];
    Object.keys(this.connectionsSimpleModel[route]).map((key) => {
      if (this.connectionsSimpleModel[route][key]) {
        result.push(key);
      }
    });
    return result;
  }

  onConnectionsValueChanged(route: any = null) {
    this.filterService.loadStart();
    this.markFiltersTouched();
    this.sidebarFilter.connectionValues[
      route
      ] = this.extractAvailableConnections(route);

    // get selected item
    const addedItem = this.sidebarFilter.connectionValues[route].filter(
      (obj) => {
        return this.sidebarFilter.connectionValueAfterSelect[route].indexOf(obj) === -1;
      }
    );
    const deletedItem = this.sidebarFilter.connectionValueAfterSelect[route].filter(
      (obj) => {
        return this.sidebarFilter.connectionValues[route].indexOf(obj) === -1;
      });

    // update black and white lists
    if (addedItem[0] !== undefined) {
      this.updateWhiteListConnections(true, route, addedItem[0]);
      this.updateBlackListConnections(true, route, addedItem[0]);
    }
    if (deletedItem[0] !== undefined) {
      this.updateWhiteListConnections(false, route, deletedItem[0]);
      this.updateBlackListConnections(false, route, deletedItem[0]);
    }

    this.updateUrlParamsForConnections();
    setTimeout(() => this.applySidebarFilter(), 0);
  }

  selectAllCheckboxes(route) {
    this.sidebarFilter.connectionValues[route] = Object.keys(this.connectionsSimpleModel[route]);

    this.connectionsBlackList[route] = [];
    this.connectionsWhiteList[route] = Array.from(new Set(this.connectionsWhiteList[route].concat(this.sidebarFilter.connectionValues[route])));

    this.updateUrlParamsForConnections();
    this.applySidebarFilter();
  }

  selectedOnlyOneConnection(params) {
    this.connectionsBlackList[params.route] = [];
    this.connectionsWhiteList[params.route] = [];
    this.sidebarFilter.connectionValues[params.route] = [];

    this.sidebarFilter.connectionValues[params.route].push(params.connection);

    this.markFiltersTouched();

    for (let key in this.connectionsSimpleModel[params.route]) {
      if (key === params.connection) {
        this.connectionsWhiteList[params.route].push(params.connection);
      } else {
        this.connectionsBlackList[params.route].push(key);
      }
    }

    this.directFlightsSelected(false);

    this.updateUrlParamsForConnections();
    this.applySidebarFilter();
  }

  directFlightsSelected(value) {
    this.filterService.loadStart();
    this.isDirectFlightsIncluded = value;
    if (!value) {
      this.sidebarIsShowResetButton.connections = true;
    }
    this.applySidebarFilter();
  }

  directFlightsOnly(params) {
    this.connectionsBlackList[params.route] = [];
    this.connectionsWhiteList[params.route] = [];
    this.sidebarFilter.connectionValues[params.route] = [];

    this.filterService.loadStart();
    this.isDirectFlightsOnly = params.isDirectFlightsOnly;

    for (let key in this.connectionsSimpleModel[params.route]) {
      this.connectionsBlackList[params.route].push(key);
    }

    this.directFlightsSelected(true);

    this.updateUrlParamsForConnections();
    setTimeout(() => this.applySidebarFilter(), 0);
  }

  updateBlackListConnections(isAdd, route, item) {
    if (!isAdd && this.connectionsBlackList[route].indexOf(item) === -1) {
      this.connectionsBlackList[route].push(item);
    }
    if (isAdd) {
      this.helpers.removeItemFromArrayIfExist(
        this.connectionsBlackList[route],
        item
      );
    }
  }

  updateWhiteListConnections(isAdd, route, item) {
    if (!isAdd) {
      this.helpers.removeItemFromArrayIfExist(
        this.connectionsWhiteList[route],
        item
      );
    }
    if (isAdd && this.connectionsWhiteList[route].indexOf(item) === -1) {
      this.connectionsWhiteList[route].push(item);
    }
  }

  updateUrlParamsForConnections() {
    let params = {...this.route.snapshot.queryParams};
    Object.keys(this.sidebarFilter.connections).map((key, i) => {
      params[`connections_black_list[${i}]`] = this.connectionsBlackList[
        key
        ].join('-');
      params[`connections_white_list[${i}]`] = this.connectionsWhiteList[
        key
        ].join('-');
    });
    setTimeout(() => this.router.navigate([], {queryParams: params}), 0);
  }

  private extractConnectionListsFromUrl() {
    const params = this.route.snapshot.queryParams;

    Object.keys(this.sidebarFilter.connections).map((key, i) => {
      if (
        params[`connections_white_list[${i}]`] &&
        params[`connections_white_list[${i}]`] !== undefined
      ) {
        this.connectionsWhiteList[key] = this.sidebarFilter.connectionValues[
          key
          ] = params[`connections_white_list[${i}]`].split('-');
      } else if (params[`connections_white_list[${i}]`] === undefined) {
        this.connectionsWhiteList[key] = this.sidebarFilter.connectionValues[
          key
          ] = this.sidebarFilter.connections[key];
      } else {
        this.connectionsWhiteList[key] = this.sidebarFilter.connectionValues[
          key
          ] = [];
      }

      if (
        params[`connections_black_list[${i}]`] &&
        params[`connections_white_list[${i}]`] !== undefined
      ) {
        this.connectionsBlackList[key] = params[
          `connections_black_list[${i}]`
          ].split('-');
      } else {
        this.connectionsBlackList[key] = [];
      }
    });
  }

  clearSidebarConnections() {
    this.sidebarFilter.connectionValues = {};
    Object.keys(this.sidebarFilterData.connections).map((key) => {
      this.sidebarFilter.connectionValues[
        key
        ] = this.sidebarFilterData.connections[key].map((item) => item.id);
    });

    this.isDirectFlightsIncluded = true;

    this.sidebarIsShowResetButton.connections = false;
  }

  resetConnections() {
    this.clearSidebarConnections();
    let params = {...this.route.snapshot.queryParams};
    Object.keys(params).forEach(key => {
      if (key.startsWith("connections")) {
        params[key] = undefined;
      }
    });
    this.router.navigate([], {queryParams: params});
    this.applySidebarFilter();
  }

  private resetFixedOriginDestinations() {
    this.fixedOriginDestinationArr = [];
    this.fixedOriginDestinationSegments = '';
    this.checkObject = {};
  }

  private compareOffersWithFixedOriginDestinations(offers) {
    const filteredOffers = [];
    offers.map((offer) => {
      if (
        this.fixedOriginDestinationSegments === this.getFixedSegments(offer)
      ) {
        filteredOffers.push(offer);
      }
    });
    this.resetFixedOriginDestinationIfNeed();
    return filteredOffers;
  }

  private getFixedSegments(offer) {
    return offer.flights.map((flight, index) => {
      const candidate = this.fixedOriginDestinationArr.filter(item => item.name === `originalDestination-${index}`)[0];
      if (candidate && candidate.isChecked) {
        return flight.segments.map(segment => {
          return `${segment.marketingCarrier.airlineID}${segment.marketingCarrier.flightNumber}`;
        }).join('-');
      }
    }).join('-');
  }

  private setToOriginDestinationArr(event) {
    if (!this.fixedOriginDestinationArr.length) {
      this.fixedOriginDestinationArr = this.getOriginalDestinationsFromOffer(
        event.offer
      );
    }
    const candidate = this.fixedOriginDestinationArr.findIndex(
      (item) => item.name === event.fixedOriginDestination
    );
    if (candidate !== -1) {
      this.fixedOriginDestinationArr[candidate].isChecked = !this
        .fixedOriginDestinationArr[candidate].isChecked;
    }
    this.checkObject = {};
    this.fixedOriginDestinationArr.map((item) => {
      this.checkObject[item.name] = item.isChecked;
    });
  }

  resetFixedOriginDestinationIfNeed() {
    this.isShowMainLoader = true;
    const tempData = Array.from(
      new Set(this.fixedOriginDestinationArr.map((item) => item.isChecked))
    );
    if (tempData.length === 1 && !tempData[0]) {
      this.fixedOriginDestinationArr = [];
    }
  }

  private getOriginalDestinationsFromOffer(offer) {
    return offer.flights.map((flight, i) => {
      return {
        name: `originalDestination-${i}`,
        isChecked: false,
      };
    });
  }

  onChangeDuration(index) {
    this.markFiltersTouched();
    let params = {...this.route.snapshot.queryParams};
    const temp = [];
    let isDurationChanged = false;
    this.sidebarFilterData.durationNames.map((item, i) => {
      if (
        this.sidebarFilter.durations[i] &&
        this.sidebarFilter.durations[i] !== this.durationSliderOptions[i].ceil
      ) {
        temp[i] = this.sidebarFilter.durations[i];
        isDurationChanged = true;
      }
    });
    this.sidebarIsShowResetButton.flightDuration = isDurationChanged;
    params[`max-flight-duration`] = temp.join('-');

    this.router.navigate([], {queryParams: params});
    setTimeout(() => this.applySidebarFilter(), 0);
    this.sidebarFilter.durationsTouched[index] = true;
  }

  clearSidebarFlightDuration() {
    this.sidebarFilter.durations = this.durationSliderOptions.map(
      (item) => item.ceil
    );
    this.sidebarIsShowResetButton.flightDuration = false;
  }

  resetFlightDuration() {
    this.clearSidebarFlightDuration();
    let params = {...this.route.snapshot.queryParams};
    params[`max-flight-duration`] = undefined;
    this.applySidebarFilter();
  }

  onMaxStopsChange() {
    this.filterService.loadStart();
    this.markFiltersTouched();
    this.updateSearchUrl(this.urlParams.MAX_STOPS, this.sidebarFilter.maxStops);
    this.sidebarIsShowResetButton.maxStops = this.sidebarFilter.maxStops !== 10;
    setTimeout(() => this.applySidebarFilter(), 0);
  }

  clearSidebarStops() {
    this.sidebarFilter.maxStops = 10;
    this.sidebarIsShowResetButton.maxStops = false;
  }

  resetStops() {
    this.clearSidebarStops();
    this.onMaxStopsChange();
  }

  onChangeTimeRange() {
    this.filterService.loadStart();
    this.markFiltersTouched();
    let temp = false;
    let params = {...this.route.snapshot.queryParams};
    Object.keys(this.sidebarFilter.timeRanges).map((key) => {
      const obj = this.sidebarFilter.timeRanges[key];
      if (
        obj.departure.minValue !== obj.departure.min ||
        obj.departure.maxValue !== obj.departure.max ||
        obj.arrival.minValue !== obj.arrival.min ||
        obj.arrival.maxValue !== obj.arrival.max
      ) {
        temp = true;
      }

      params[
        `departure_time_range[${key}]`
        ] = `${obj.departure.minValue}-${obj.departure.maxValue}`;
      params[
        `arrival_time_range[${key}]`
        ] = `${obj.arrival.minValue}-${obj.arrival.maxValue}`;
    });
    this.isShowResetTimeRangesButton = temp;
    this.router.navigate([], {queryParams: params});
    setTimeout(() => this.applySidebarFilter(), 0);
  }

  resetTimeRanges() {
    Object.keys(this.sidebarFilter.timeRanges).map((key) => {
      const obj = this.sidebarFilter.timeRanges[key];
      obj.departure.minValue = obj.departure.min;
      obj.departure.maxValue = obj.departure.max;
      obj.arrival.minValue = obj.arrival.min;
      obj.arrival.maxValue = obj.arrival.max;
    });
    this.isShowResetTimeRangesButton = false;
    this.applySidebarFilter();
  }

  onChangePaymentTimeLimitRange() {
    this.filterService.loadStart();
    this.markFiltersTouched();
    if (this.sidebarFilter.paymentTimeLimitRanges.value !== this.sidebarFilter.paymentTimeLimitRanges.min) {
      this.sidebarIsShowResetButton.paymentTimeLimit = true;
    }
    let params = {...this.route.snapshot.queryParams};
    const obj = this.sidebarFilter.paymentTimeLimitRanges;
    params[`payment_time_limit_range`] = `${obj.minValue}-${obj.maxValue}`;
    this.router.navigate([], {queryParams: params});
    setTimeout(() => this.applySidebarFilter(), 0);
  }

  clearSidebarPaymentTimeLimit() {
    this.sidebarFilter.paymentTimeLimitRanges.value = this.sidebarFilter.paymentTimeLimitRanges.min;
    this.sidebarIsShowResetButton.paymentTimeLimit = false;
  }

  resetPaymentTimeLimit() {
    this.clearSidebarPaymentTimeLimit();
    this.applySidebarFilter();
  }

  onChangePriceRange() {
    this.filterService.loadStart();
    this.isChangePriceRange = false;
    this.markFiltersTouched();
    if (this.sidebarPrices.allPrices.minPriceValue !== this.sidebarPrices.allPrices.minPrice ||
      this.sidebarPrices.allPrices.maxPriceValue !== this.sidebarPrices.allPrices.maxPrice) {
      this.sidebarIsShowResetButton.priceRange = true;
    } else {
      this.sidebarIsShowResetButton.priceRange = false;
    }
    setTimeout(() => this.applySidebarFilter(), 0);
  }

  clearSidebarPriceRange() {
    this.sidebarPrices.allPrices.maxPriceValue = this.sidebarPrices.allPrices.maxPrice;
    this.sidebarPrices.allPrices.minPriceValue = this.sidebarPrices.allPrices.minPrice;
    this.sidebarIsShowResetButton.priceRange = false;
  }

  resetPriceRange() {
    this.clearSidebarPriceRange();
    this.applySidebarFilter();
  }

  extractValuesFromUrl() {
    const params = this.route.snapshot.queryParams;

    // extract and set MaxStops
    this.sidebarFilter.maxStops = +params[this.urlParams.MAX_STOPS] === 0 || +params[this.urlParams.MAX_STOPS]
      ? +params[this.urlParams.MAX_STOPS]
      : this.ls.settings.stops || 10;

    if (+params[this.urlParams.MAX_STOPS] === 0 || +params[this.urlParams.MAX_STOPS]) {
      this.sidebarFilter.maxStops = +params[this.urlParams.MAX_STOPS];
    } else if (!+params[this.urlParams.MAX_STOPS] && (this.ls.settings.stops || this.ls.settings.stops === 0)) {
      this.sidebarFilter.maxStops = this.ls.settings.stops;
    } else {
      this.sidebarFilter.maxStops = 10;
    }

    this.sidebarIsShowResetButton.maxStops = this.sidebarFilter.maxStops !== 10;

    // extract and set Time Range
    if (params[`departure_time_range[0]`] !== undefined) {
      this.isShowResetTimeRangesButton = true;
      for (let i = 0; i <= this.searchObj.originDestinations.length - 1; i++) {
        let departure = [];
        let arrival = [];
        if (params[`departure_time_range[${i}]`] !== undefined) {
          departure = params[`departure_time_range[${i}]`].split('-');
          arrival = params[`arrival_time_range[${i}]`].split('-');
          this.sidebarFilter.timeRanges[i].departure.minValue = +departure[0];
          this.sidebarFilter.timeRanges[i].departure.maxValue = +departure[1];
          this.sidebarFilter.timeRanges[i].arrival.minValue = +arrival[0];
          this.sidebarFilter.timeRanges[i].arrival.maxValue = +arrival[1];
        }
      }
    }
    if (params[`payment_time_limit_range`] !== undefined) {
      let vals = params[`payment_time_limit_range`].split('-');
      this.sidebarFilter.paymentTimeLimitRanges.minValue = +vals[0];
      this.sidebarFilter.paymentTimeLimitRanges.maxValue = +vals[1];
    }
  }

  private extractMaxDurationFromUrl() {
    const params = this.route.snapshot.queryParams;
    if (params[`max-flight-duration`] !== undefined) {
      this.sidebarFilter.durations = [];
      const durations = params[`max-flight-duration`].split('-');
      this.searchObj.originDestinations.map((item, i) => {
        if (durations[i]) {
          this.sidebarFilter.durations[i] = +durations[i];
          this.sidebarFilter.durationsTouched[i] = true;
          this.sidebarIsShowResetButton.flightDuration = true;
        } else {
          this.sidebarFilter.durations[i] = this.durationSliderOptions[i].ceil;
          this.sidebarFilter.durationsTouched[i] = false;
        }
      });
      this.applySidebarFilter();
    }
  }

  wasNewSearch(event) {
    this.isShowMainLoader = this.isNewSearch = event;
    if (event) {
      this.clearFiltersOnSearch();
      this.tempOffers = null;
      this.newOffersCount = 0;
      this.isOfferFiltersTouched = false;
      this.selectedOffers = this.paginatedOffers = [];
    }
  }

  clearFiltersOnSearch() {
    // reset fixed legs on new search
    this.comingOffersLength = 0;
    this.resetFixedOriginDestinations();
  }

  private fillFlightNumberIfNeed() {
    if (this.offers.length) {
      this.offers.forEach(offer => {
        offer.flights.forEach(flight => {
          if (flight.segments.length) {
            flight.segments.forEach(segment => {
              let marketingfNumber = segment.marketingCarrier.flightNumber;
              let operatingfNumber = segment.operatingCarrier.flightNumber;
              if (marketingfNumber.length && marketingfNumber.length === 3) {
                segment.marketingCarrier.flightNumber = `0${marketingfNumber}`;
              }
              if (operatingfNumber.length && operatingfNumber.length === 3) {
                segment.operatingCarrier.flightNumber = `0${operatingfNumber}`;
              }
            });
          }
        });
      });
    }
  }

  private extractAirportsFromUrl() {
    const params = this.route.snapshot.queryParams;
    const paramKeys = Object.keys(params).filter(
      (item) =>
        item.match('arrival_airports') || item.match('departure_airports')
    );
    if (!this.isNewSearch && paramKeys.length && this.airportsInfo) {
      Object.keys(this.airportsInfo).map((route, index) => {
        if (params[`departure_airports[${index}]`]) {
          let airportsUrl = params[`departure_airports[${index}]`].split(',');
          airportsUrl.map((code) => {
            this.airportsInfo[route].departure.airportMap[code] = true;
          });
        }

        if (params[`arrival_airports[${index}]`]) {
          let airportsUrl = params[`arrival_airports[${index}]`].split(',');
          airportsUrl.map((code) => {
            this.airportsInfo[route].arrival.airportMap[code] = true;
          });
        }
      });
    }
  }

  resetAllFilters(noCleanURL = false, isChangingType = false) {
    if (!noCleanURL) {
      // reset url values
      const obj = {...ALL_RESET_KEYS_OBJ};
      let params = {...this.route.snapshot.queryParams};
      Object.keys(params).map((param) => {
        Object.keys(obj).map((objItem) => {
          if (param.match(objItem)) {
            params[param] = obj[objItem];
          }
        });
      });

      this.router.navigate([], {queryParams: params});

      this.searchObj.originDestinations?.map((item, index) => {
        // reset time-ranges
        const timeRanges = this.sidebarFilter.timeRanges[index];
        if (!!timeRanges) {
          timeRanges.arrival.minValue = timeRanges.arrival.min;
          timeRanges.arrival.maxValue = timeRanges.arrival.max;
          timeRanges.departure.minValue = timeRanges.departure.min;
          timeRanges.departure.maxValue = timeRanges.departure.max;
        }
      });

      // reset max flight durations
      this.clearSidebarFlightDuration();

      if (this.offers.length) {
        setTimeout(() => this.buildDynamicSidebar());
      }
    }

    // reset price range
    this.clearSidebarPriceRange();

    // reset max stops
    this.clearSidebarStops();

    // reset selected flight numbers
    this.clearSidebarFlightNumbers();

    // hide reset button near issuing time limit
    this.sidebarIsShowResetButton.paymentTimeLimit = false;

    // reset airports
    this.clearSidebarAirports();

    // reset connections and direct flights
    this.clearSidebarConnections();

    // reset airlines
    this.clearSidebarAirlines();

    if (!isChangingType) {
      this.filterService.loadStart();
      this.applySidebarFilter();
      this.filterService.loadEnd();
    }
  }

  onSearch(event) {
    if (event) {
      this.searchObj = event;
      this.resetAllFilters(true);
    }
  }

  setFlightNumberValues(event) {
    if (event.originDestinations) {
      event.originDestinations.map((od, index) => {
        let temp = [];
        temp = event.selectedFlightNumberArr[index];
        if (!!temp && temp.length) {
          this.sidebarIsShowResetButton.flightNumbers = true;
        }
        this.sidebarFilter.flightNumbersSelected[index] = !!temp ? temp : [];
      });
    }
  }

  trackOfferInfo(index: number, item: any) {
    return Array.isArray(item) ? item[0].offerID : item.offerID;
  }

  markFiltersTouched() {
    this.offersLengthBeforeTouch = this.offers.length;
    this.isOfferFiltersTouched = this.isAirlinesNeedChanges =  true;
  }

  /**
   *  Response for set active tab
   * @param tabName
   */
  setOfferTab(tabName: string): void {
    let params = {...this.route.snapshot.queryParams};
    if (tabName === this.OFFER_TAB.PER_LEG) {
      params['segment'] = 'per_leg';
      this.router.navigate([], {queryParams: params});
      this.markFiltersTouched();
    } else if (tabName === this.OFFER_TAB.FULL_TRIP) {
      params['segment'] = 'full_trip';
      this.router.navigate([], {queryParams: params});
    }
    this.refreshPaginatedOffers(null, tabName);
    this.activeOffersTab = tabName;
  }

  windowToTop(event) {
    window.scroll({
      behavior: 'smooth',
      top: 0,
    });
  }

  /**
   * extract prices for sidebar
   */
  private extractPrices(offer) {
    /** for price range */
    // if (!this.sidebarPrices.allPrices.all.includes(offer.price.consumer.total)) {
    this.sidebarPrices.allPrices.all.push(offer.price.consumer.total);
    // }
    if (!this.sidebarPrices.allPrices.currency.includes(offer.price.consumer.currency)) {
      this.sidebarPrices.allPrices.currency.push(offer.price.consumer.currency);
    }
  }

  onCollapseOptions(key: string) {
    this.collapsedOptions[key] = !this.collapsedOptions[key];
    this.ls.collapseOptions = JSON.stringify(this.collapsedOptions);
  }

  onGraphRangeChange(event, key, type = 'duration') {
    this.filterService.loadStart();
    if (type === 'duration') {
      this.sidebarFilter.durations[key] = event[0];
      setTimeout(() => {
        this.onChangeDuration(key);
      });
      return;
    }

    if (type === 'price') {
      this.sidebarPrices.allPrices.minPriceValue = event[0];
      this.sidebarPrices.allPrices.maxPriceValue = event[1];
      setTimeout(() => {
        this.onChangePriceRange();
      });
      return;
    }

    if (type === 'paymentTimeLimit') {
      this.sidebarFilter.paymentTimeLimitRanges.value = event;
      setTimeout(() => {
        this.onChangePaymentTimeLimitRange();
      });
      return;
    }

    this.sidebarFilter.timeRanges[key][type].minValue = event[0];
    this.sidebarFilter.timeRanges[key][type].maxValue = event[1];
    setTimeout(() => {
      this.onChangeTimeRange();
    });
  }

  handleGridChange($event: any) {
    this.gridModel = $event;

    if (this.isAirlinesNeedChanges) {
      this.buildSidebarAirlines(this.gridModel.offers);
    }

    if (this.isFirstHandleGridEvent) {
      this.hideCodeshares = this.gridModel.hideCodeshares;
      this.checkedBaggage = this.gridModel.checkedBaggage;
      this.refundableFares = this.gridModel.refundableFares;
      this.fareRules = this.gridModel.fareRules;

      this.isFirstHandleGridEvent = false;
    }
    if (this.hideCodeshares !== this.gridModel.hideCodeshares ||
      this.checkedBaggage !== this.gridModel.checkedBaggage ||
      this.refundableFares !== this.gridModel.refundableFares ||
      this.fareRules !== this.gridModel.fareRules) {
      this.hideCodeshares = this.gridModel.hideCodeshares;
      this.checkedBaggage = this.gridModel.checkedBaggage;
      this.refundableFares = this.gridModel.refundableFares;
      this.fareRules = this.gridModel.fareRules;
      this.applySidebarFilter();
    }
    this.refreshPaginatedOffers();
  }

  buildSidebarAirlines(offers) {
    this.sidebarFilter.airlines = {};
    offers.map(offer => {
      offer.flights.map((flight, i) => {
        flight.segments.map((segment) => {
          const airline = segment.operatingCarrier.airlineID;
          if (airline && !this.sidebarFilter.airlines[airline]) {
            this.sidebarFilter.airlines[airline] = true;
          }
        });
      });
    });
  }

  getMaxStops() {
    this.helpers.getMaxStops().subscribe(maxStop => {
      if (this.sidebarFilter.maxStops !== maxStop) {
        this.sidebarFilter.maxStops = maxStop;
        this.onMaxStopsChange();
      }
    });
  }

}
