import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {Agency} from "../../shared/types/agency";
import {HubAgenciesService} from "../../shared/services/hub-agencies.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NotificationService} from "../../shared/services/notification.service";
import {Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {ROLES} from "../../shared/constants";

@Component({
  selector: 'app-subagencies',
  templateUrl: './subagencies.component.html',
  styleUrls: ['./subagencies.component.scss']
})
export class SubagenciesComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  agencies: Agency[] = [];
  errors = {
    email: '',
    agency: '',
    error: {summary: ''}
  };
  model: any  = {};

  constructor( public hubAgenciesService: HubAgenciesService,
               public modalService: NgbModal,
               private _notificationSvc: NotificationService,
               private router: Router
  ) { }

  ngOnInit(): void {
    this.hubAgenciesService.getAll()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res: any) => {
        if (res.body) {
          this.agencies = res.body;
        }
      }, err => console.log(err));
  }

  addNewSubagency(content) {
    this.modalService.open(content).result.then((result) => {

    }, (reason) => {

    });

  }

  deactivateAgency(id: string) {
    this.hubAgenciesService.deactivateAgency({id: id})
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response: any) => {
        let res = response.body;
        if (res.status === 'OK') {
          this._notificationSvc.success('Success', `Agency ${id} has been removed`,  0);
          this.ngOnInit();
        } else {
          this.errors = res;
        }
      });
  }

  save(c) {
    let body = {...this.model};
    this.hubAgenciesService.addAgency(body)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response: any) => {
        let res = response.body;
        if (res.status === 'OK') {
          this._notificationSvc.success('Success', `Agency ${this.model.name} has been added`,  0);
          c('ok');
          this.ngOnInit();
        } else {
          this.errors = res;
        }
      });

  }

  getNumberOfRoles(agency: Agency, role: string) {
    let count = 0;
    if (role === ROLES.MANAGER) {
      count = agency?.accounts?.filter(accountRole => (accountRole.role === ROLES.MANAGER || accountRole.role === ROLES.SUBAGENCY_MANAGER)).length;
    } else {
      count = agency?.accounts?.filter(accountRole => (accountRole.role !== ROLES.MANAGER && accountRole.role !== ROLES.SUBAGENCY_MANAGER)).length;
    }
    return count || '-';
  }

  view(url: string) {
    this.router.navigateByUrl(`agencies/${url}`);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}
