import { Pipe, PipeTransform } from '@angular/core';
import {Dictionary} from "../types/dictionary";


@Pipe({
  name: 'paymentInfo'
})
export class PaymentInfoPipe implements PipeTransform {

  transform(order: any): string {
    if (!order) { return ''; }

    const formOfPayment = order.formOfPayment || '';

    switch (formOfPayment) {
      case 'agencyCard':
        return 'Agency Card';
      case 'agencyCash':
        return 'Agency Cash';
      case 'Card':
        return this.getCardPaymentInfo(order);
      default:
        return formOfPayment;
    }
  }

  private getCardPaymentInfo(order: any): string {
    if (order.payment?.cardInformation) {
      const cardInfo = order.payment.cardInformation;
      const cardType = Dictionary.CardCodes[cardInfo.cardCode] || cardInfo.cardCode;
      const cardNumber = cardInfo.cardNumber;
      return `Card (${cardType}) ${cardNumber}`;
    }
    return 'Card';
  }

}
