import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getStyleToAlignColumnPipe'
})
export class GetStyleToAlignColumnPipe implements PipeTransform {

  transform(gridType: string, offersGrid: any, cls: string, providers: string[], ...args: unknown[]): string {

    let offersExist = false;
    let alignmentStyle = '';
    if (gridType === 'price') {
      offersExist = providers.some(s => offersGrid[s][cls].count > 0);
      alignmentStyle = offersExist ? 'left' : 'center';
    } else if (gridType === 'class') {
      alignmentStyle = 'left';
    } else {
      alignmentStyle = 'center';
    }

    return alignmentStyle;
  }

}
