import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {AuthGuard} from "../shared/guards/auth.guard";
import {PresetsComponent} from "./presets.component";
import {PresetListComponent} from "./preset-list/preset-list.component";
import {PresetDetailComponent} from "./preset-detail/preset-detail.component";
import {KeycloakGuard} from "../shared/guards/keycloak-guard";
import {PresetsGuard} from "../shared/guards/presets.guard";

const routes: Routes = [
  {
    path: 'presets',
    canActivate: [AuthGuard, KeycloakGuard, PresetsGuard],
    component: PresetsComponent,
    children: [
      {
        path: '',
        component: PresetListComponent,
      },
      {
        path: ':id',
        component: PresetDetailComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PresetsRoutingModule { }
