import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-show-warnings',
  templateUrl: './show-warnings.component.html',
  styleUrls: ['./show-warnings.component.scss']
})
export class ShowWarningsComponent implements OnInit {

  @Input() warnings: string[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
