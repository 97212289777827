
export class Corporate {
  id: string;
  org_id: string;
  consumer_id: string;
  agency_id: string;
  name: string;
  domains: string[] = [];
  account_number: string;
  created_at: string;
  updated_at: string;
  discount_codes: any = {};
  loyalty_program_discount_codes: any = {};
  counters: {
    managers: number;
    travelers: number
  };
}


export class SelectedCorporate {
  id: string;
  name: string;
}
