import {Agent} from "./agent";

export class Agency {
  id: string;
  accounts?: Agent[];
  name: string;
  iata_number?: string;
  image_url?: string;
}

export class Account {
  api_key: string;
  email: string;
  id: string;
  invited: boolean;
  name: string;
  role: string;
  status: string;
  username: string;
}
