import { PaymentSettings } from "./payment-settings";

export class BookingActionsState {
  seatAvailabilityEnabled: boolean;
  serviceListEnabled: boolean;
  orderCreateWithPaymentEnabled: boolean;
  paymentSettings: PaymentSettings;
}

export class OrderViewActionsState {
  seatAvailabilityButton: boolean;
  serviceListButton: boolean;
  changeItineraryButton: boolean;
  airdocIssueButton: boolean;
  cancelOrderButton: boolean;
  orderSplitButton: boolean;
  paymentSettings: PaymentSettings;
}
