export enum OrderTab {
  OrderDetails = 'orderDetails',
  OrderHistory = 'orderHistory',
  Comments = 'comments',
  Remarks = 'remarks',
  Metas = 'metas',
  OrderLog = 'orderLog'
}

export enum OrderListTabId {
  All = 'all',
  Active = 'Active',
  Pending = 'Pending',
  Ticketed = 'Ticketed',
  Cancelled = 'Cancelled',
  Voided = 'Voided',
  Issuing = 'Issuing',
  Expired = 'Expired',
  Unknown = 'Unknown',
  Notified = 'Notified',
  Search = 'search'
}
