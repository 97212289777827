export interface RemarkElement {
  content: string;
  type: REMARK_ELEM_TYPE;
  typeOfContent?: string;
  isRequired?: boolean;
  isOptional?: boolean;
  placeholder?: string;
  length?: number;
  minLength?: number;
  maxLength?: number;
  list?: any[];
  maxDigitsAfterDot?: number;
}

export enum REMARK_ELEM_TYPE {
  TEXT,
  INPUT,
}

export interface RemarkDataToUpdate {
  data: {
    name: string,
    template: string,
    variables: object
  };
  id: string;
  result: string;
  templates?: object[];
}

export interface  RemarkTextToUpdate {
  id: string;
  result: string;
}

export const REMARK_NAME = {
  iteratePassengers: 'iteratePassengers',
  remarksTemplateComplex: 'RemarksTemplateComplex',
  remarksSimpleTemplateTest: 'RemarksSimpleTemplateTest'
};
