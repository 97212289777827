import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseToTitleCase'
})
export class CamelCaseToTitleCasePipe implements PipeTransform {

  transform(value: string | any): string | any {
    if (typeof value === 'string') {
      return value.replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/\b\w/g, first => first.toUpperCase());
    } else {
      return value;
    }
  }

}
