import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-offer-discounts',
  templateUrl: './offer-discounts.component.html',
  styleUrls: ['./offer-discounts.component.scss']
})
export class OfferDiscountsComponent implements OnInit {

  @Input() discounts;

  constructor() { }

  ngOnInit(): void {
  }

}
