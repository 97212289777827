import { Injectable } from '@angular/core';
import { LocalStorageService} from "./local-storage.service";
import { version } from '../../../../package.json';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { timer } from 'rxjs/internal/observable/timer';

@Injectable()
export class VersionService {
  buildVersionValue = Number.MAX_VALUE;
  version = '';
  isVersionChangesSource = new BehaviorSubject(false);
  isVersionChanges$ = this.isVersionChangesSource.asObservable();

  constructor(public http: HttpClient,
              public ls: LocalStorageService) {}

  checkCurrentVersion() {
    timer(0, 60000).pipe(
      switchMap(() => this.getNumber())
    ).subscribe(res => {
      const versionValue = res;
      if (versionValue > this.buildVersionValue) {
        this.isVersionChangesSource.next(true);
      }
      this.buildVersionValue = versionValue;
      const ver = version.split('.');
      ver.pop();
      let verStr = ver.join('.');
      const buildNumber = this.buildVersionValue.toString().padStart(3, '0');
      this.version = this.ls.versionNumber = `${verStr}.${buildNumber}`;
    }, err => {
      this.version = this.ls.versionNumber || '';
    });
  }

  getNumber() {
    const headers = new HttpHeaders({
      'Cache-Control':  'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    return this.http.get<number>('/build_number', { headers: headers });
  }
}
