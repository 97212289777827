export interface SearchModel {
  originDestinations: OriginDestination[];
  defaultMinDate: SearchDate;
  defaultDepartureDate: SearchDate;
  defaultReturnDate: SearchDate;
  minDates: MinDates;
  totalOffers: number;
  originDestinationsForOfferURL: string;
  travelersForOfferURL: string;
  loggedRequestTime: string;
  errorMessage: string;
  allowNewSearch: boolean;
  isRequestSent: boolean;
  offers?: any[];
  selectedFlightNumberArr: any;
  form: SearchForm;
  selectedTime: number[];
}

export interface OriginDestination {
  departure: {
    airportCode: string;
    date: string;
    time: string;
    terminalName: string;
  };
  arrival: {
    airportCode: string;
    time: string;
    terminalName: string;
  };
}

export interface SearchDate {
  year: number;
  month: number;
  day: number;
}

export interface MinDates {
  'departure_date[0]': SearchDate;
  'arrival_date[0]'?: SearchDate;
}

export interface SearchForm {
  type: string;
  departure_code_0: string;
  arrival_code_0: string;
  cabin: string;
}

export const initSearchDate: SearchDate = {
  year: 0,
  month: 0,
  day: 0,
};

export const initMinDates = {
  'departure_date[0]': initSearchDate,
  'arrival_date[0]': initSearchDate,
};

export const initSearchForm: SearchForm = {
  type: '',
  departure_code_0: '',
  arrival_code_0: '',
  cabin: '',
};

export const initSearchModel: SearchModel = {
  originDestinations: [
    {
      departure: {
        airportCode: '',
        date: '',
        time: '',
        terminalName: '',
      },
      arrival: {
        airportCode: '',
        time: '',
        terminalName: '',
      },
    },
  ],
  defaultMinDate: initSearchDate,
  defaultDepartureDate: initSearchDate,
  defaultReturnDate: initSearchDate,
  minDates: initMinDates,
  totalOffers: 0,
  originDestinationsForOfferURL: '',
  travelersForOfferURL: '',
  loggedRequestTime: '',
  errorMessage: '',
  allowNewSearch: false,
  isRequestSent: false,
  offers: [],
  selectedFlightNumberArr: {},
  form: initSearchForm,
  selectedTime: []
};
