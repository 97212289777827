import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {PresetsRoutingModule} from "./presets-routing.module";
import {PresetsComponent} from "./presets.component";
import { PresetListComponent } from './preset-list/preset-list.component';
import { PresetDetailComponent } from './preset-detail/preset-detail.component';
import {SharedModule} from "../shared/shared.module";



@NgModule({
  declarations: [
    PresetsComponent,
    PresetListComponent,
    PresetDetailComponent
  ],
  imports: [
    PresetsRoutingModule,
    SharedModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class PresetsModule { }
