import { Pipe, PipeTransform } from '@angular/core';
import moment from "moment";

@Pipe({
  name: 'momentTime'
})
export class MomentTimePipe implements PipeTransform {

  transform(value: Date | moment.Moment): number {
    return value ? moment(value).utc().unix() : null;
  }

}
