import {Injectable} from "@angular/core";
import {AMADEUS_SEARCH} from "../constants";
import { MAX_DAYS_IN_MONTH} from "../../shared/constants";
import {DashboardHelpersService} from "./dashboard-helpers.service";
import {Subject} from "rxjs";
import {AmadeusParserToInput} from "../models/amadeus-parser-to-input.model";

@Injectable()
export class ParserAmadeusCommandService {

  inputValue = '';
  datePattern = /[0-9]{2}[a-zA-Z]{3}/gi;
  odPattern = /[a-zA-Z]{6}/gi;
  currentIndex = 0;
  errorInStep = '';
  AMADEUS = AMADEUS_SEARCH;
  MAX_DAYS_IN_MONTH = MAX_DAYS_IN_MONTH;
  parsed;
  defaultParsed = {
    availabilityType: '',
    originDestinations: [],
    airlinePreference: [],
    cabinTypes: [],
    classOfBooking: []
  }
  setFocus = 0;

  amadeusComandParsedData$ = new Subject();

  constructor(
    private dashboardHelpersService: DashboardHelpersService,
  ) {
    this.parsed = {...this.defaultParsed};
  }

  public starSearch(inputValue: string) {
    this.errorInStep = '';
      this.inputValue = inputValue.toUpperCase();
      this.parsed = {...this.defaultParsed};
      this.parsed.originDestinations = [];
      this.currentIndex = 0;
      // this.getAvailabilityType();
      this.getFirstOriginDestination();
      this.checkCurrentIndexForOptions();
  }

  getFirstOriginDestination() {
    const tempOD = {};
    tempOD['departureDate'] = this.getDepartureDate(this.inputValue.substr(this.currentIndex, 5));
    const od = this.getParsedAirports(this.inputValue.substr(this.currentIndex, 6));
    if (!this.errorInStep && !!od) {
      tempOD['departure'] = od.departure;
      tempOD['arrival'] = od.arrival;
    }
    this.parsed.originDestinations[0] = tempOD;
  }

  private checkCurrentIndexForOptions() {
    if (!!this.errorInStep) return;
    if (this.inputValue[this.currentIndex] === undefined) {
      this.goToSearch();
      return;
    }

    /** catch departure time **/
    const subStr4 = this.inputValue.substr(this.currentIndex, 4);
    const subStr3 = this.inputValue.substr(this.currentIndex, 3);
    const subStr2 = this.inputValue.substr(this.currentIndex, 2);

    if (subStr4.length === 4 && subStr4.match(/[0-9]{4}/gi)) {
      this.extractDepartureTime(subStr4);
      this.checkCurrentIndexForOptions();
      return;
    }
    if (subStr3.length === 3 && subStr3.match(/[0-9]{2}[pP]/gi)) {
      this.extractDepartureTime(subStr3);
      this.checkCurrentIndexForOptions();
      return;
    }
    if (subStr2.length === 2 && subStr2.match(/[0-9][pP]/gi)) {
      this.extractDepartureTime(subStr2);
      this.checkCurrentIndexForOptions();
      return;
    }
    /** ------ **/


    switch (this.inputValue[this.currentIndex]) {
      case '/':
        this.currentIndex++;
        this.amadeusFullAccess();
        return
      case ':':
        this.currentIndex++;
        this.amadeusAccessUpdate();
        return;
      case '.':
        this.currentIndex++;
        this.amadeusAccessSell();
        return;
      case '*':
        this.currentIndex++;
        this.amadeusDirectAccess();
        return;
      default:
        if (this.inputValue[this.currentIndex] === undefined) {
          this.goToSearch();
        } else {
          this.currentIndex++;
          this.checkCurrentIndexForOptions();
        }

    }
  }


  private goToSearch() {
    const data: AmadeusParserToInput = {
      errorMsg: this.errorInStep,
      data: this.errorInStep ? null : this.parsed,
    }
    this.amadeusComandParsedData$.next(data)
  }

  private getParsedAirports(subString) {
    let value = null;
    if (!this.errorInStep && subString.length === 6 && !!subString.match(this.odPattern)) {
      value = {
        departure: subString.substr(0, 3),
        arrival: subString.substr(3, 3)
      }
    } else {
      this.showParseError();
    }

    this.currentIndex = !!value ? this.currentIndex + 6 : this.currentIndex;
    return value;
  }

  private getDepartureDate(subString) {
    let date = null;
    if (!this.errorInStep && subString.length === 5 && !!subString.match(this.datePattern)) {
      const tempDate = this.dashboardHelpersService.convertDate(subString);

      if (!!tempDate) {
        date = tempDate;
        this.currentIndex += 5;
      } else {
        this.showParseError();
      }
    } else {
      this.showParseError();
    }
    return date;
  }

  private getAvailabilityType() {
    const value = this.inputValue[this.currentIndex];
    if (value && this.AMADEUS.AVAILABILITY_TYPES.includes(value)) {
      this.parsed.availabilityType = value;
      this.currentIndex++;
    } else {
      this.showParseError();
    }
  }

  showParseError(place = '') {
    this.errorInStep = place ? place : `Incorrect command for search.`;
    this.goToSearch();
  }

  amadeusFullAccess() {
    switch (this.inputValue[this.currentIndex]) {
      case 'A':
        if (this.inputValue[this.currentIndex + 1] !== '-') {
          this.currentIndex++;
          this.extractAirlinePreference();
        }
        return;
      case 'K':
        this.currentIndex++;
        this.extractCabinTypeOrClassOfBooking('CabinType');
        return;
      case 'C':
        this.currentIndex++;
        this.extractCabinTypeOrClassOfBooking('ClassOfBooking');
        return;
      case 'X':
        if (this.inputValue[this.currentIndex + 1] !== '-') {
          this.currentIndex++;
          this.extractConnections(true);
        } else {
          this.currentIndex += 2;
          this.extractConnections(false);
        }
        return;
      case 'F':
        this.currentIndex++;
        this.extractFlightType();
        return;
    }

    this.checkCurrentIndexForOptions();
  }


  amadeusAccessUpdate() {

    this.checkCurrentIndexForOptions();
  }

  amadeusAccessSell() {

    this.checkCurrentIndexForOptions();
  }

  amadeusDirectAccess() {
    const subStr_1 = this.inputValue.substr(this.currentIndex,11);
    if (subStr_1.length === 11 && subStr_1.match(/[0-9]{2}[a-zA-Z]{9}/gi)) {
      const tempOD = {}
      tempOD['departureDate'] = this.getDepartureDate(subStr_1.substr(0, 5));
      const od = this.getParsedAirports(subStr_1.substr( 5, 6));
      if (!this.errorInStep && !!od) {
        tempOD['departure'] = od.departure;
        tempOD['arrival'] = od.arrival;
      }
      this.parsed.originDestinations[this.parsed.originDestinations.length] = tempOD;
      this.checkCurrentIndexForOptions();
      return;
    }

    const subStr_2 = this.inputValue.substr(this.currentIndex,5);
    if (subStr_2.length === 5 && subStr_2.match(/[0-9]{2}[a-zA-Z]{3}/gi)) {
      const tempOD = {}
      tempOD['departureDate'] = this.getDepartureDate(subStr_2);
      const lastOD = this.parsed.originDestinations[this.parsed.originDestinations.length -1];
      if (!!lastOD) {
        tempOD['departure'] = lastOD.arrival;
        tempOD['arrival'] = lastOD.departure;
      }
      this.parsed.originDestinations[this.parsed.originDestinations.length] = tempOD;
      this.checkCurrentIndexForOptions();
      return;
    }

    if (this.inputValue[this.currentIndex] === undefined || this.inputValue[this.currentIndex] === ' ') {
      const lastOD = this.parsed.originDestinations[this.parsed.originDestinations.length -1];

      const tempOD = {}
      tempOD['departureDate'] = lastOD['departureDate']
      tempOD['departure'] = lastOD.arrival;
      tempOD['arrival'] = lastOD.departure;

      this.parsed.originDestinations[this.parsed.originDestinations.length] = tempOD;
      this.checkCurrentIndexForOptions();
      return;
    }

    this.checkCurrentIndexForOptions();
  }

  private extractAirlinePreference() {
    let value = [];
    let iterationFlag = 1;
    for (let i = 0; i < iterationFlag; i++) {
      const subStr = this.inputValue.substr(this.currentIndex, 2);
      if (subStr.length === 2 && subStr.match(/[a-zA-Z]{2}/gi)) {
        value.push(subStr);
        this.currentIndex += 2;
      }

      if (this.inputValue[this.currentIndex] === ',') {
        this.currentIndex++;
        iterationFlag++;
      }
    }

    this.parsed.airlinePreference = [...this.parsed.airlinePreference, ...value];
    this.checkCurrentIndexForOptions();
  }

  private extractCabinTypeOrClassOfBooking(flag) {
    let value = [];
    let iterationFlag = 1;
    for (let i = 0; i < iterationFlag; i++) {
      const subStr = this.inputValue.substr(this.currentIndex, 1);
      if (subStr.length === 1 && subStr.match(/[a-zA-Z]/gi)) {
        value.push(subStr);
        this.currentIndex ++;
      }

      if (this.inputValue[this.currentIndex] === ',') {
        this.currentIndex++;
        iterationFlag++;
      }
    }

    switch (flag) {
      case 'CabinType':
        this.parsed.cabinTypes = [...this.parsed.cabinTypes, ...value];
        this.checkCurrentIndexForOptions();
        return;
      case 'ClassOfBooking':
        this.parsed.classOfBooking = [...this.parsed.classOfBooking, ...value];
        this.checkCurrentIndexForOptions();
        return;
    }
  }

  private extractConnections(isWhiteList = false) {
    let value = [];
    let iterationFlag = 1;
    for (let i = 0; i < iterationFlag; i++) {
      const subStr = this.inputValue.substr(this.currentIndex, 3);
      if (subStr.length === 3 && subStr.match(/[a-zA-Z]{3}/gi)) {
        value.push(subStr);
        this.currentIndex += 3;
      }

      if (this.inputValue[this.currentIndex] === ',') {
        this.currentIndex++;
        iterationFlag++;
      }
    }

    const odLength = this.parsed.originDestinations.length;
    if (isWhiteList) {
      !!odLength ? this.parsed[`connections_white_list_${odLength - 1}`] = value : this.parsed[`connections_white_list_0`] = value;
      this.checkCurrentIndexForOptions();
    } else {
      !!odLength ? this.parsed[`connections_black_list_${odLength - 1}`] = value : this.parsed[`connections_black_list_0`] = value;
      this.checkCurrentIndexForOptions();
    }
  }

  extractFlightType() {
    const tempVal = this.inputValue[this.currentIndex];
    if (['N', 'D', 'C'].includes(tempVal)) {
      this.parsed['maxStops'] = tempVal;
      this.currentIndex++;
    }
    this.checkCurrentIndexForOptions();
  }

  extractDepartureTime(subString) {
    switch (subString.length) {
      case 4:
        this.parsed[`departure_time_${this.parsed.originDestinations.length -1}`] = `${subString.substr(0, 2)}:${subString.substr(2, 2)}`;
        this.currentIndex += 4;
        return;
      case 3:
      case 2:
        this.parsed[`departure_time_${this.parsed.originDestinations.length -1}`] = `${subString.substr(0, subString.length - 1)}:00`;
        this.currentIndex += subString.length;
        return;
      default:
        this.currentIndex++;
    }
  }
}
