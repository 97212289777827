import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isIncludes'
})
export class IsIncludesPipe implements PipeTransform {

  transform(provider: string, providersWithError: string[]): boolean {
    return providersWithError.includes(provider);
  }

}
