import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FilterLoaderService {
  loaderSource = new BehaviorSubject(false);
  isLoad$ = this.loaderSource.asObservable();

  constructor() {}

  loadStart() {
    this.loaderSource.next(true);
  }

  loadEnd() {
    this.loaderSource.next(false);
  }
}
