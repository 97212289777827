import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor() { }

  validateObject(control): object {
    let errors = {};
    for (let jkey in control.value) {
      let innerControl = control.get(jkey);
      if (innerControl) {
        if (innerControl.value && !innerControl.errors && typeof (innerControl.value) === 'object') {
          let innerErrors = this.validateObject(innerControl);
          for (let k in innerErrors) {
            errors[k] = innerErrors[k];
          }
        } else {
          if (!innerControl.valid && innerControl.errors) {
            errors[jkey] = innerControl.errors;
          }
          innerControl.updateValueAndValidity();
          innerControl.markAsDirty();
        }
      }
    }
    return errors;
  }
}
