import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getDisabledPreference'
})
export class GetDisabledPreferencesPipe implements PipeTransform {

  transform(preferenceList: any[], farePreference: any): boolean {
    let preferenceDisabled = false;

    preferenceList.map(preference => {
      if (preference.title !== farePreference.title && !!preference.code && preference.code === farePreference.code) {
        preferenceDisabled = true;
      }
    });

    return preferenceDisabled;
  }

}
